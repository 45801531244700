import User01Icon from "@untitled-ui/icons-react/build/esm/User01";
import {
  Avatar,
  Box,
  ButtonBase,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useMockedUser } from "src/hooks/use-mocked-user";
import { usePopover } from "src/hooks/use-popover";
import { AccountPopover } from "./account-popover";

export const AccountButton = () => {
  const user = {
    name: localStorage.getItem("fullName"),
    email: localStorage.getItem("userEmail"),
    avatar:
      "https://www.kindpng.com/picc/m/252-2524695_dummy-profile-image-jpg-hd-png-download.png",
  };
  const popover = usePopover();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "divider",
          height: 40,
          width: 40,
          borderRadius: "50%",
        }}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32,
          }}
          src={user.avatar}
        >
          <SvgIcon>
            <User01Icon />
          </SvgIcon>
        </Avatar>
      </Box>
      {isSmUp && (
        <Box>
          <Typography
            variant="body1"
            color="text.primary"
            sx={{ fontWeight: 700 }}
          >
            {user.name}
          </Typography>
          <Typography variant="body2" color="gray">
            {user.email}
          </Typography>
        </Box>
      )}
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
