import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { ImageLayout } from "src/pages/auth-demo/ImageLayout";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import LeadinglyLogo from '../../assets/logo-lg.83418505.png';
// import B2BRocketLogo from "../../assets/Leadingly logo blue cropped.png";
import "./style.css";

export const Layout = (props) => {
  const { children } = props;
  let location = useLocation();
  const currentUrl = location.pathname;

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        display: "flex",
        flex: "1 1 auto",
        flexDirection: {
          xs: "column-reverse",
          md: "row",
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: "background.paper",
          display: "flex",
          flex: {
            xs: "1 1 auto",
            md: "0 0 auto",
          },
          flexDirection: "column",
          justifyContent: {
            md: "center",
          },
          maxWidth: "100%",
          pt: 2,
          pb: 0,
          pl: 25,
          pr: 4,
          width: {
            md: 650,
          },
        }}
        className="loginContainer"
      >
        <div>
          <Box sx={{ mb: 2 }}>
            <Stack
              alignItems="center"
              component={RouterLink}
              direction="row"
              display="inline-flex"
              href={paths.index}
              spacing={2}
              sx={{ textDecoration: "none" }}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  height: 44,
                  width: 44,
                  marginBottom: "20px",
                }}
              >
                <img src={LeadinglyLogo} alt="Logo" />
              </Box>
            </Stack>
          </Box>
          {children}
        </div>
      </Box>

      <Box
        className="imageContainer"
        sx={{
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          color: "common.white",
          display: "flex",
          justifyContent: "right",
          flex: {
            xs: "0 0 auto",
            lg: "2 2 auto",
          },
          p: {
            xs: 8,
            md: 0,
          },
        }}
      >
        <ImageLayout currentUrl={currentUrl} />
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
