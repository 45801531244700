import { Navigate, Outlet } from "react-router-dom";
import { Layout as MarketingLayout } from "src/layouts/marketing";
import HomePage from "src/pages";
import Error401Page from "src/pages/401";
import Error404Page from "src/pages/404";
import Error500Page from "src/pages/500";
import ContactPage from "src/pages/contact";
import CheckoutPage from "src/pages/checkout";
import ReportPage from "src/pages/userReport";
import PricingPage from "src/pages/pricing";
import ThanksPage from "src/pages/thankyou";
import UnsubscribePage from "src/pages/unsubscribe";
import OptoutPage from "src/pages/optout";
import { authRoutes } from "./auth";
import { authDemoRoutes } from "./auth-demo";
import { componentsRoutes } from "./components";
import { dashboardRoutes } from "./dashboard";
import ChatPage from "src/pages/chatPage";
import RedirectToChat from "src/pages/RedirectToChat";

export const routes = [
  {
    element: (
      <MarketingLayout>
        <Outlet />
      </MarketingLayout>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={"/auth-main/login/v2"} replace />,
      },
      {
        path: "pricing",
        element: <PricingPage />,
      },
      ...componentsRoutes,
    ],
  },
  ...authRoutes,
  ...authDemoRoutes,
  ...dashboardRoutes,
  {
    path: "user-report",
    element: <ReportPage />,
  },
  {
    path: "thankyou",
    element: <ThanksPage />,
  },
  {
    path: "optout/:uid",
    element: <OptoutPage />,
  },
  {
    path: "/unsubscribe/:uid",
    element: <UnsubscribePage />,
  },
  {
    path: "/unenroll/:uid",
    element: <UnsubscribePage />,
  },
  {
    path: "checkout",
    element: <CheckoutPage />,
  },
  {
    path: "contact",
    element: <ContactPage />,
  },
  {
    path: "401",
    element: <Error401Page />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "500",
    element: <Error500Page />,
  },
  {
    path: "*",
    element: <Error404Page />,
  },
  {
    path: "chat/private/:campaignId/:leadId/:emailId",
    element: <ChatPage />,
  },
  {
    path: "chat/private/:campaignId/:leadId",
    element: <ChatPage />,
  },
  {
    path: "chat/private/:urlId",
    element: <ChatPage />,
  },
  {
    path: "chat/:campaignId",
    element: <ChatPage />,
  },
];
