import React from "react";
import { Box, Stack } from "@mui/material";
import SideNavFooterItem from "./side-nav-footer-item";
import Settings01 from "@untitled-ui/icons-react/build/esm/Settings01";
import LogOut01Icon from "@untitled-ui/icons-react/build/esm/LogOut01";
import { useCallback } from "react";
import toast from "react-hot-toast";

import { RouterLink } from "src/components/router-link";
import { useAuth } from "src/hooks/use-auth";
import { usePathname } from "src/hooks/use-pathname";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { Issuer } from "src/utils/auth";

const SideNavFooter = () => {
  const router = useRouter();
  const auth = useAuth();
  const user = {
    name: localStorage.getItem("fullName"),
    email: localStorage.getItem("userEmail"),
  };
  const pathname = usePathname();

  console.log("path", pathname);

  const handleLogout = useCallback(async () => {
    try {
      switch (auth.issuer) {
        case Issuer.Amplify: {
          await auth.signOut();
          break;
        }

        case Issuer.Auth0: {
          await auth.logout();
          break;
        }

        case Issuer.Firebase: {
          await auth.signOut();
          break;
        }

        case Issuer.JWT: {
          await auth.signOut();
          break;
        }

        default: {
          console.warn("Using an unknown Auth Issuer, did not log out");
        }
      }

      router.push(paths.index);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!");
    }
  }, [auth, router]);
  return (
    <Stack
      component="ul"
      spacing={0.5}
      sx={{
        listStyle: "none",
        m: 0,
        p: 0,
        gap: "24px",
        alignDelf: "stretch",
        borderTop: '0.2px inset',
        color: '#F2F4F7',
        fontFamily: 'DM Sans'

      }}
    >
      <SideNavFooterItem
        active={paths.dashboard.account === pathname}
        depth={0}
        disabled={false}
        icon={<Settings01 />}
        key={"Settings"}
        label={""}
        open={true}
        path={paths.dashboard.account}
        handleClick={() => {}}
        title={"Settings"}
      />
      <SideNavFooterItem
        active={false}
        depth={0}
        disabled={false}
        icon={<LogOut01Icon />}
        key={"Logout"}
        label={""}
        open={true}
        handleClick={handleLogout}
        title={"Log out"}
      />
    </Stack>
  );
};

export default SideNavFooter;
