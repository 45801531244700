import "../style.css";
import StepOneIcon from "src/components/logos/StepOneIcon.png";
import {
  Typography,
  Stack,
  Button,
  Divider,
  IconButton,
  Box,
} from "@mui/material";
import Check from "src/components/logos/Check.png";
import StepItem from "./StepItem";
import { useState } from "react";
import StepEmailContent from "./StepEmailContent";

export const TimelineStepOne = (props) => {
  const { onNext, disabled, followUpStep, emailSeqStatus, emailStatus } = props;

  const steps = [
    {
      stepTitle: "NOW",
      stepText: "Send INITIAL email.",
      stepContent: (
        <StepEmailContent
          content={emailSeqStatus?.["1"]}
          status={emailStatus}
        />
      ),
    },
    {
      stepTitle: "+10 DAYS AFTER PREVIOUS EMAIL",
      stepText:
        "If an INITIAL email was not opened, opened, or clicked, resend the AI regenerated email.",
      stepContent: (
        <StepEmailContent
          content={emailSeqStatus?.["2"]}
          status={emailStatus}
        />
      ),
    },
    {
      stepTitle: "+12 DAYS AFTER PREVIOUS EMAIL",
      stepText:
        "If INITIAL email was not opened, opened, or clicked, resend AI regenerated email.",
      stepContent: (
        <StepEmailContent
          content={emailSeqStatus?.["3"]}
          status={emailStatus}
        />
      ),
    },

    {
      stepTitle: "+13 DAYS AFTER PREVIOUS EMAIL",
      stepText:
        "If the prospect still has not taken any ACTION (conversation, reply, meeting) other than open or click then remove the prospect from the journey.",
      stepContent: (
        <StepEmailContent
          content={emailSeqStatus?.["4"]}
          status={emailStatus}
        />
      ),
    },
  ];

  return (
    <Stack spacing={3} gap={3} direction="column" className="contentBorder">
      {/* <>
        <Stack direction="row" spacing={1}>
          <img src={StepOneIcon} />
          <Typography
            variant="h6"
            sx={{ color: "#050C46", fontSize: "16px", fontWeight: "700" }}>
            Now
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: "0px 28px" }}>
          <IconButton sx={{ p: 0 }}>
            <img src={Check} height="20px" />
          </IconButton>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500, color: "#050C46" }}>
            Send an INITIAL email.
          </Typography>
        </Stack>
      </>
      <Divider />
      <>
        <Stack direction="row" spacing={1}>
          <img src={StepOneIcon} />
          <Typography
            variant="h6"
            sx={{ color: "#050C46", fontSize: "16px", fontWeight: "700" }}>
            +10 Days After Previous Email
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: "0px 28px" }}>
          <IconButton sx={{ p: 0 }}>
            <img src={Check} height="20px" />
          </IconButton>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500, color: "#050C46" }}>
            If an INITIAL email was not opened, opened, or clicked, resend the
            Al regenerated email.
          </Typography>
        </Stack>
      </>
      <Divider />
      <>
        <Stack direction="row" spacing={1}>
          <img src={StepOneIcon} />
          <Typography variant="h6" sx={{ color: "#050C46" }}>
            +12 Days After Previous Email
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: "0px 28px" }}>
          <IconButton sx={{ p: 0 }}>
            <img src={Check} height="20px" />
          </IconButton>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500, color: "#050C46" }}>
            If INITIAL email was not opened, opened, or clicked, resend Al
            regenerated email.
          </Typography>
        </Stack>
      </>
      <Divider />
      <>
        <Stack direction="row" spacing={1}>
          <img src={StepOneIcon} />
          <Typography variant="h6" sx={{ color: "#050C46" }}>
            +15 Days After Previous Email
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: "0px 28px" }}>
          <IconButton sx={{ p: 0 }}>
            <img src={Check} height="20px" />
          </IconButton>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500, color: "#050C46" }}>
            If INITIAL email was not opened, opened, or clicked, resend Al
            regenerated email.
          </Typography>
        </Stack>
      </>
      <Divider />
      <>
        <Stack direction="row" spacing={1}>
          <img src={StepOneIcon} />
          <Typography variant="h6" sx={{ color: "#050C46" }}>
            +13 Days After Previous Email
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ padding: "0px 28px" }}>
          <IconButton sx={{ p: 0 }}>
            <img src={Check} height="20px" />
          </IconButton>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500, color: "#050C46" }}>
            If the prospect still has not taken any ACTION (conversation, reply,
            meeting) other than open or click, then REMOVE the prospect from
            future emails and ensure our unsubscribe link is working and tested.
          </Typography>
        </Stack>
      </> */}
      {steps.map((step, index) => (
        <>
          {" "}
          <StepItem
            key={index}
            stepTitle={step.stepTitle}
            stepText={step.stepText}
            stepContent={step.stepContent}
            // completed={followUpStep && index + 1 < Number(followUpStep)}
            completed={!!emailSeqStatus?.[`${index + 1}`]}
          />
          {index < steps.length - 1 && (
            <Divider sx={{ "&.MuiDivider-root": { my: 0 } }} />
          )}
        </>
      ))}
      <Button fullWidth className="currentButton" onClick={onNext}>
        Next Stage
      </Button>
    </Stack>
  );
};
