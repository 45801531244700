import SearchLgIcon from "@untitled-ui/icons-react/build/esm/SearchLg";
import { IconButton, SvgIcon, Tooltip } from "@mui/material";
import { useDialog } from "src/hooks/use-dialog";
import { SearchDialog } from "./search-dialog";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import { alpha, useTheme } from "@mui/material/styles";

export const SearchButton = () => {
  const dialog = useDialog();
  const theme = useTheme();
  return (
    <>
      {/* <Tooltip title="Search">
        <IconButton onClick={dialog.handleOpen}>
          <SvgIcon>
            <SearchMdIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip> */}
      <Paper
        //component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          maxWidth: 400,
          backgroundColor: alpha(theme.palette.primary.main, 0.05),
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: "6px",
        }}
        // onClick={dialog.handleOpen}
      >
        <IconButton type="button" aria-label="search">
          <SearchLgIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          inputProps={{ "aria-label": "search" }}
        />
      </Paper>
      {/* <SearchDialog onClose={dialog.handleClose} open={dialog.open} /> */}
    </>
  );
};
