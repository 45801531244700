import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MuiTelInput } from "mui-tel-input";
import "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  Switch,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
  InputAdornment,
  Autocomplete,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Chip
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import axios from "axios";
import { storage } from "../../../libs/firebase";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import {
  getAuth,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updateProfile,
} from "firebase/auth";
import { toast } from "react-hot-toast";
import {
  API_SERVICE_BACKEND,
  cronofy,
  API_SERVICE_BACKEND_2,
  API_LOCAL_IP,
  APP_TIMEZONE, API_SERVICE_BACKEND_3,
} from "src/config";
import {
  AvailabilityRulesWrapper,
  CalendarSyncWrapper,
} from "src/components/cronofy";
import moment from "moment-timezone";
import "./style.css";
import UserCircle from "src/components/logos/userCircle.png";
import UploadIcon from "src/components/logos/uploadIcon.png";
import GlobeIcon from "src/components/logos/globeIcon.png";
import Email from "src/components/logos/Email.png";
import MarkerPin from "src/components/logos/markerPin.png";
import Phonecall from "src/components/logos/phoneCall.png";
import MeetingIcon from "src/components/logos/meetingIcon.png";
import Link from "src/components/logos/link.png";
import calenderPlus from "src/components/logos/calendarPlus.png";
import LinkIcon from "src/components/logos/linkIcon.png";
import LinkIcon2 from '@mui/icons-material/Link';
import SelectIcon from "src/components/logos/selectIcon.png";
import Key from "src/components/logos/key.png";
import Linkbroken from "src/components/logos/linkBroken.png";
import Dropzone from "react-dropzone";
import { FileDropzone } from "src/components/file-dropzone";
import { useRef } from "react";
import { Visibility } from "@mui/icons-material";
import { thunks } from "src/thunks/mail";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useAuth } from "src/contexts/auth/firebase-context";
import { AiOutlineConsoleSql } from "react-icons/ai";
import { Oval } from "react-loader-spinner";
import { getServerDateTime } from "src/pages/dashboard/customers/allCustomers";
import { cronofyApi } from "src/api/cronofy";

export const UserMeetingSchedules = ({ date, index }) => {
  const meetingTime = [
    "09:00AM",
    "11:00AM",
    "01:00PM",
    "03:00PM",
    "04:00PM",
    "09:30AM",
    "11:30AM",
    "01:30PM",
    "02:30PM",
    "04:30PM",
    "09:45AM",
    "11:45AM",
    "01:45PM",
    "02:45PM",
    "03:45PM",
    "09:15AM",
    "11:30AM",
    "01:30PM",
    "03:15PM",
    "04:15PM",
    "10:00AM",
    "11:15AM",
    "01:15PM",
    "02:45PM",
    "03:30PM",
  ];
  const selectedTimeSlots = meetingTime.slice(index * 5, index * 5 + 5);

  return (
    <>
      <Grid>
        <Stack alignItems="center" direction="row" spacing={3}>
          <TextField
            type="text"
            defaultValue={date}
            sx={{ mt: 1 }}
            variant="outlined"
            disabled={true}
          />

          {selectedTimeSlots.map((time, timeIndex) => (
            <TextField
              key={timeIndex}
              defaultValue={time}
              variant="outlined"
              sx={{ mt: 2, display: "none" }}
              type="text"
              disabled={true}
            />
          ))}
        </Stack>
      </Grid>
    </>
  );
};

const SlotPresetSetting = () => {
  const [duration, setDuration] = useState(30);
  const checkFirstRender = useRef(true);
  const [buffer, setBuffer] = useState({
    isSetBuffer: false,
    before: 0,
    after: 0
  })
  const email = localStorage.getItem("userEmail");
  const handleDurationChange = async (event) => {
    const targetValue = event.target.value;
    setDuration(targetValue);
  };

  const durationTime = async () => {
    const userSlotPreset = await fetch(
      `${API_SERVICE_BACKEND_3}/get-slot-preset/${email}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const duration = await userSlotPreset.json();
    setDuration(duration?.data?.slotsPreset)

    if (duration?.data?.buffer) {
      setBuffer(duration?.data?.buffer)
    }

  }
  useEffect(() => {
    durationTime();
  }, []);

  useEffect(() => {

    if (checkFirstRender.current) {
      checkFirstRender.current = false;
      return;
    }
    (async () => {
      const res = await fetch(
        `${API_SERVICE_BACKEND_3}/updateUserSlotPreset/${email}/${duration}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 200) {
        toast.success("Slot Preset Updated Successfully");
      } else {
        toast.error("Error Updating Slot Preset");
      }
    })();
  }, [duration]);

  const handleBufferChange = (event) => {

    const { name, value } = event.target;
    setBuffer(prevState => ({
      ...prevState,
      [name]: Number(value)
    }))

  }

  const handleBufferVisibilityChange = async (event) => {

    setBuffer(prevState => ({
      ...prevState,
      isSetBuffer: !buffer.isSetBuffer
    }))

    if (event.target.value === 'false') {
      const res = await updateSlotPreset(false);
      if (res.status === 200) {
        toast.success("Buffer Updated Successfully");
      } else {
        toast.error("Error Updating Buffer");
      }
    }
  }

  const updateSlotPreset = async (isSetBuffer) => {
    console.log("121212 GLOBAL -->", isSetBuffer)
    return await fetch(
      `${API_SERVICE_BACKEND_2}/updateUserSlotPreset/${email}/${duration}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...buffer, isSetBuffer: isSetBuffer ?? buffer.isSetBuffer })
      }
    );

  }

  const handleSaveBuffer = () => {
    (async () => {
      const res = await updateSlotPreset();
      if (res.status === 200) {
        toast.success("Buffer Updated Successfully");
      } else {
        toast.error("Error Updating Buffer");
      }
    })();

  }

  return (
    <>
      {/* <FormLabel component="legend">Select Slot Preset</FormLabel> */}
      <RadioGroup
        onChange={handleDurationChange}
        row
        aria-label="duration"
        name="duration"
        value={duration}
      >
        <FormControlLabel value={30} control={<Radio />} label="30 Minutes" />
        <FormControlLabel value={60} control={<Radio />} label="60 Minutes" />
      </RadioGroup>

      <Divider />

      <Grid item container direction="row" gap={2} alignItems={"center"} marginTop={"10px"}>
        <Typography
          sx={{
            fontSize: "16px",
            color: "#050C46",
            fontWeight: 600,
          }}
        >
          Set Buffer time in minutes:
        </Typography>

        <RadioGroup
          onChange={handleBufferVisibilityChange}
          row
          aria-label="buffer"
          value={buffer.isSetBuffer}
          name={"isSetBuffer"}
        >
          <FormControlLabel value={false} control={<Radio />} label="No" />
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
        </RadioGroup>

      </Grid>

      {buffer && buffer.isSetBuffer && (
        <Grid container alignItems="center" columnSpacing={2}>

            <Grid item container direction="row" gap={2}>
              <Grid item container alignItems="center" columnSpacing={1}>
                <Typography sx={{marginRight: "8px"}}>before : </Typography>
                <TextField
                    name={"before"}
                    value={buffer?.before}
                    variant="outlined"
                    size="small"
                    type="number"
                    max={60}
                    error={buffer?.before>60}
                    helperText={buffer?.before>60 && "maximum buffer time should be 60"}
                    sx={{flexGrow: 1}}
                    inputProps={{min: 0}}
                    onChange={handleBufferChange}
                />
              </Grid>

              <Grid item container alignItems="center" columnSpacing={1}>
                <Typography sx={{marginRight: "8px"}}>after : </Typography>
                <TextField
                    name={"after"}
                    value={buffer?.after}
                    variant="outlined"
                    size="small"
                    type="number"
                    max={60}
                    error={buffer?.after>60}
                    helperText={buffer?.after>60 && "maximum buffer time should be 60"}
                    sx={{flexGrow: 1}}
                    inputProps={{min: 0}}
                    onChange={handleBufferChange}
                />
              </Grid>

              <Grid item container alignItems="center" columnSpacing={1}>
                <Button sx={{marginRight: "8px"}} disabled={(buffer?.after > 60) || (buffer?.before>60)} variant={"outlined"} size={"small"} onClick={handleSaveBuffer}>Save
                  buffer </Button>
              </Grid>
            </Grid>
          </Grid>
      )}


    </>
  );
};
export const AccountGeneralSettings = (props) => {
  const location = useLocation();
  const { user } = useAuth();
  // console.log(user);
  // console.log(location);

  const [hasZohoIntegration, setHasZohoIntegration] = useState(false);
  const [hasHubSpotIntegration, setHasHubSpotIntegration] = useState(false);

  const [zohoExpired, setZohoExpired] = useState(false);
  const [hubspotExpired, setHubSpotExpired] = useState(false);

  const [zohoIntegration, setZohoIntegration] = useState({});
  const [hubSpotIntegration, setHubSpotIntegration] = useState({});

  useEffect(() => {
    // Parse the query parameters
    const searchParams = new URLSearchParams(location.search);
    const from = searchParams.get('from');
    const lastLinkedProfileId = searchParams.get('last_linked_profile_id');

    // Check if the desired query parameters are present
    if (from === 'cronofy' && lastLinkedProfileId) {
      // Make your API call here
      makeApiCall(from, lastLinkedProfileId);
    }
  }, [location.search]);

  const makeApiCall = (from, lastLinkedProfileId) => {
    cronofyApi.conferencingLinked({ email: email });
  };


  useEffect(() => {
    const tokenZoho = user?.integrations?.zoho?.access_token;

    if (tokenZoho) {
      setHasZohoIntegration(true);
      setZohoIntegration(user?.integrations?.zoho);
    } else {
      setHasZohoIntegration(false);
    }

    const tokenHubspot = user?.integrations?.hubspot?.access_token;
    if (tokenHubspot) {
      setHasHubSpotIntegration(true);
      setHubSpotIntegration(user?.integrations?.hubspot);
    } else {
      setHasHubSpotIntegration(false);
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      const { dateTime } = await getServerDateTime();
      const now = moment.tz(dateTime, APP_TIMEZONE);

      if (
        moment.tz(hubSpotIntegration?.expires_at, APP_TIMEZONE).unix() <
        now.unix()
      ) {
        setHubSpotExpired(true);
      } else {
        setHubSpotExpired(false);
      }

      if (
        moment.tz(zohoIntegration?.expires_at, APP_TIMEZONE).unix() < now.unix()
      ) {
        setZohoExpired(true);
      } else {
        setZohoExpired(false);
      }
    })();
  }, [hubSpotIntegration, zohoIntegration]);

  const handleDisconnect = async (userEmail, type) => {
    console.log(userEmail, type, "comingData");
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND_2}/removeIntegration`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userEmail: userEmail,
            type: type,
          }),
        }
      );
      console.log(response, "responsedel");
      if (response.ok) {
        type == "hubspot"
          ? setHasHubSpotIntegration(false)
          : setHasZohoIntegration(false);
        console.log(response, "resMsg");

        if (type === "hubspot") {
          setHasHubSpotIntegration(false);
          setHubSpotExpired(false);
          setHubSpotIntegration(null);
        } else if (type === "zoho") {
          setHasZohoIntegration(false);
          setZohoExpired(false);
          setZohoIntegration(null);
        }
      } else {
        const errorData = await response.json();
        console.error(`Error: ${errorData.error}`);
      }
    } catch (error) {
      console.error("Internal Server Error:", error);
    }
  };

  const initiateZohoOAuth = () => {
    // Replace 'YOUR_CLIENT_ID' with the actual Client ID obtained from the Zoho Developer Console
    const clientId = "1000.9VUAZN3TVQ0OSAOQSXUNGBHC72RRWA";
    const redirectUri = `${API_SERVICE_BACKEND_2}/zoho-callback`;

    const queryParams = {
      access_type: "offline",
      prompt: "consent",
      response_type: "code",
      client_id: clientId,
      redirect_uri: redirectUri,
      scope: "ZohoCRM.modules.ALL",
    };

    const oauthUrl = `https://accounts.zoho.com/oauth/v2/auth?${new URLSearchParams(
      queryParams
    ).toString()}`;
    console.log(oauthUrl);
    // Redirect the user to Zoho for authorization
    window.location.replace(oauthUrl);
  };

  const section1Ref = useRef();
  const section2Ref = useRef();
  const section3Ref = useRef();
  const section4Ref = useRef();
  const section5Ref = useRef();
  const section6Ref = useRef();
  const section7Ref = useRef();
  const section8Ref = useRef();
  const section9Ref = useRef();

  const navHeader = [
    {
      headerTitle: "Basic details",
      headerRef: section1Ref,
      headerID: "section1",
    },
    {
      headerTitle: "Company Details",
      headerRef: section2Ref,
      headerID: "section2",
    },
    {
      headerTitle: "Phone Number",
      headerRef: section3Ref,
      headerID: "section3",
    },
    {
      headerTitle: "Change password",
      headerRef: section4Ref,
      headerID: "section4",
    },
    // {
    //   headerTitle: "Meetings Available Slots",
    //   headerRef: section5Ref,
    //   headerID: "section5",
    // },
    // {
    //   headerTitle: "Meeting Link",
    //   headerRef: section6Ref,
    //   headerID: "section6",
    // },
    {
      headerTitle: "Link calendar",
      headerRef: section7Ref,
      headerID: "section7",
    },
    {
      headerTitle: "Select Your Availability",
      headerRef: section8Ref,
      headerID: "section8",
    },
    {
      headerTitle: "Integrations",
      headerRef: section9Ref,
      headerID: "section9",
    },
  ];

  //function to handle change in phone number
  const handleChange = (newValue) => setuserPhoneNumber(newValue);

  const [prevPassword, setPrevPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [dates, setDates] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const date = [];
    // Calculate the list of dates starting from tomorrow and spanning the next 7 days
    const startDate = moment().add(1, "days"); // Start from tomorrow
    for (let i = 0; i < 5; i++) {
      date.push(startDate.format("YYYY-MM-DD"));
      startDate.add(1, "days");
    }
    setDates(date);
    // Set the list of dates as options for the Select component
    setuserMeetingDate(dates[0]); // Set the default selected date to the first one
  }, []);

  const handlePasswordUpdate = async () => {
    if (newPassword === "" || prevPassword === "") {
      toast.error("Invalid Password!!");
      return;
    }
    const auth = getAuth();
    const user = auth.currentUser;

    // Re-authenticating the user with their current credentials
    const credential = EmailAuthProvider.credential(user.email, prevPassword);

    await reauthenticateWithCredential(user, credential)
      .then(() => {
        // User successfully re-authenticated
        updatePassword(user, newPassword)
          .then(() => {
            toast.success("Password updated successfully");
          })
          .catch((error) => {
            toast.error("Error updating password:", error.message);
          });
      })
      .catch((error) => {
        toast.error("Error re-authenticating user:", error.message);
      });
  };

  const {
    avatar,
    email,
    name,
    element_tokens,
    cronofy_options,
    userCompanyName,
    setuserCompanyName,
    updateCompany,
    updateCompanyAddress,
    setuserCompanyAddress,
    userCompanyAddress,
    updateUserPhoneNumber,
    setuserPhoneNumber,
    userPhoneNumber,
    addmeetingschedule,
    setuserMeetingDate,
    setuserMeetingTime,
    userMeetingDate,
    userMeetingTime,
    userMeetingLink,
    setuserMeetingLink,
    updateUserMeetingScheduleLink,
    userMeetingData,
    setuserMeetingId,
    updateUserMeetingDate,
    setupdateUserMeetingDate,
    updateUserMeetingTime,
    setupdateUserMeetingTime,
    updatemeetingschedule,
    deletemeetingschedule,
    setAdd,
    add,
    userFirstName,
    userLastName,
    setUserFirstName,
    setUserLastName,
    conferencingLinked,
    handleConferencingLink
  } = props;
  const [userName, setUserName] = useState(name);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeMenuItem, setActiveMenuItem] = useState("section1");
  const [uploadingProfileImage, setUploadingProfileImage] = useState(false);
  const [updatedProfileImage, setUpdatedProfileImage] = useState("");
  let inputRef;

  const handleUserNameUpdate = async () => {
    if (userName === undefined || userName === "" || userName === null) {
      toast.error("Invalid User Name!!");
      return;
    }

    const auth = getAuth();
    updateProfile(auth.currentUser, {
      displayName: userName,
    })
      .then(async () => {
        try {
          var dataSend = {
            name: userName,
          };
          const email = localStorage.getItem("userEmail");

          const responseDataLog = await fetch(
            `${API_SERVICE_BACKEND}/updateuserbasicdetails/${email}`,
            {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(dataSend),
            }
          );
          if (
            responseDataLog.status === 200 ||
            responseDataLog.status === 201
          ) {
            toast.success("User Name updated successfully");
            localStorage.setItem("fullName", userName);
          }
        } catch (err) {
          console.log(err);
        }
      })
      .catch((error) => {
        toast.error("Error updating User Name:", error.message);
      });
  };

  // const [imageUpload, setImageUpload] = useState(null);
  // const [imageUrl, setImageUrl] = useState(null);

  // const uploadFile = () => {
  //   if (imageUpload == null) return;
  //   const imageRef = ref(
  //     storage,
  //     `/ProfileImages/${imageUpload.name + Date.now()}`
  //   );
  //   uploadBytes(imageRef, imageUpload).then((snapshot) => {
  //     getDownloadURL(snapshot.ref).then((url) => {
  //       setImageUrl(url);
  //     });
  //   });
  // };

  // console.log(imageUrl);

  // Cronofy
  const { redirect_uri } = cronofy_options;
  const { calendarToken, availabilityToken } = element_tokens;

  const [calendarOptions, setCalendarOptions] = useState({
    data_center: cronofy.data_center,
    target_id: "cronofy-calendar-sync",
    authorization: {
      redirect_uri,
      client_id: cronofy.client_id,
      scope: "read_write",
      avoid_linking: true,
    },
    styles: {
      prefix: "CalendarSync",
    },
  });

  useEffect(() => {
    if (!calendarToken) return;
    setCalendarOptions((prev) => ({
      ...prev,
      element_token: calendarToken,
    }));
  }, [calendarToken]);

  const [availabilityOptions, setAvailabilityOptions] = useState({
    target_id: "cronofy-availability-rules",
    data_center: cronofy.data_center,
    availability_rule_id: "work_hours",
    tzid: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  useEffect(() => {
    if (!availabilityToken) return;
    setAvailabilityOptions((prev) => ({
      ...prev,
      element_token: availabilityToken,
    }));
  }, [availabilityToken]);

  const [availabilityElements, setAvailabilityElements] = useState([]);
  useEffect(() => {
    if (!availabilityOptions.element_token) return;
    setAvailabilityElements((prev) => [
      // ...prev,
      <AvailabilityRulesWrapper
        key="availability-rules"
        options={availabilityOptions}
      />,
    ]);
  }, [availabilityOptions]);

  const saveAccessToken = async (paramsStr) => {
    const res = await axios.get(
      `${API_SERVICE_BACKEND_2}/saveZoho?${paramsStr}`,
      {
        params: {
          email: user.email,
        },
      }
    );
  };

  // let params = new URLSearchParams(window.location.search);
  // console.log("params", params.toString());

  // let status = params.get("i");
  // let access_token = params.get("at");
  // let refresh_token = params.get("rt");
  // let expires_at = params.get("ea");
  // let type = params.get("t");
  // console.log("access_token", access_token);
  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams(window.location.search);
      const status = params.get("i");
      const access_token = params.get("at");
      const refresh_token = params.get("rt");
      const type = params.get("t");
      const expires_at = params.get("ea");
      const account_server = params.get("act");
      const loc = params.get("loc");

      dispatch(thunks.setAccessToken(access_token));
      try {
        localStorage.setItem("hubspot_access_token", access_token);
      } catch (error) {
        console.error("Error setting local storage:", error);
      }
      if (status === "s") {
        var dataSend = {
          userEmail: email,
          access_token,
          expires_at,
          refresh_token,
          type,
          account_server,
          location: loc
        };

        const resDataLog = await fetch(
          `${API_SERVICE_BACKEND_2}/addintegration`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataSend),
          }
        );
        if (resDataLog.status === 200) {
          // Handle success
          window.location.replace(location.pathname);
        }
      }
    };

    // Call fetchData() inside the useEffect
    fetchData();
  }, []);

  useEffect(() => {
    const isInViewport = (element) => {
      let rect = {};
      if (element) {
        rect = element.getBoundingClientRect();
      }
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    const handleScroll = () => {
      if (isInViewport(section1Ref?.current)) {
        setActiveMenuItem("section1");
      } else if (isInViewport(section2Ref?.current)) {
        setActiveMenuItem("section2");
      } else if (isInViewport(section3Ref?.current)) {
        setActiveMenuItem("section3");
      } else if (isInViewport(section4Ref?.current)) {
        setActiveMenuItem("section4");
      }
      // else if (isInViewport(section5Ref?.current)) {
      //   setActiveMenuItem('section5');
      // }
      // else if (isInViewport(section6Ref?.current)) {
      //   setActiveMenuItem('section6');
      // }
      else if (isInViewport(section7Ref?.current)) {
        setActiveMenuItem("section7");
      } else if (isInViewport(section8Ref?.current)) {
        setActiveMenuItem("section8");
      } else if (isInViewport(section9Ref?.current)) {
        setActiveMenuItem("section9");
      } else {
        setActiveMenuItem(null);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function stringAvatar(name) {
    return {
      children: `${name?.split(" ")[0][0]}`,
    };
  }

  const handleUpload = async (e) => {
    setUploadingProfileImage(true);
    try {
      let image = e[0];
      let formData = new FormData();
      formData.append("file", image);

      const response = await axios.post(
        // `${API_LOCAL_IP}upload`,
        `${API_SERVICE_BACKEND_2}/upload`,
        formData
      );

      const imageUrl = response.data?.url;

      const request = {
        profile_image: imageUrl,
      };
      if (response) {
        const uploadResponse = await axios.put(
          // `${API_LOCAL_IP}updateuserprofileimage/${email}`,
          `${API_SERVICE_BACKEND_2}/updateuserprofileimage/${email}`,
          request
        );
        if (uploadResponse.data.status === true) {
          toast.success("Image updated successfully");
        }
        const profileImage = uploadResponse.data?.data?.profile_image;
        setUpdatedProfileImage(profileImage);
      }
    } catch (error) {
      console.log("error", error);
    }
    setUploadingProfileImage(false);
  };

  return (
    <>
      <div className="mainContainer">
        <ul style={{ listStyleType: "none" }}>
          <div className="navigation">
            {navHeader.map((header, index) => (
              <li style={{ margin: "5px" }}>
                <a
                  href={`#${header.headerID}`}
                  className="listLink"
                  key={index + header.headerID}
                  style={{
                    backgroundColor:
                      activeMenuItem === header.headerID
                        ? "rgb(220 221 239)"
                        : "",
                  }}
                >
                  {header.headerTitle}
                </a>
              </li>
            ))}
          </div>
        </ul>

        {
          <Stack spacing={4} {...props} sx={{ width: "100%" }}>
            {/* <Button
                    color="inherit"
                    size="small"
                    onClick={handleUserNameUpdate}
                  >
                    Save
                  </Button> */}
            <Card>
              <CardContent>
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  ref={section1Ref}
                  id="section1"
                >
                  <div style={{ display: "flex", marginBottom: "15px" }}>
                    <>
                      <img
                        src={UserCircle}
                        style={{ marginRight: "10px", height: "23px" }}
                      />
                    </>
                    <Typography
                      className="subHeadding"
                      sx={{ fontWeight: "700" }}
                    >
                      Basic details
                    </Typography>
                  </div>

                  <Grid>
                    <Grid>
                      <Stack spacing={3}>
                        {/* <Stack alignItems='center' direction='row' spacing={2}>
                  <Box
                    sx={{
                      borderColor: "neutral.300",
                      borderRadius: "50%",
                      borderStyle: "dashed",
                      borderWidth: 1,
                      p: "4px",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "50%",
                        height: "100%",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          backgroundColor: (theme) =>
                            alpha(theme.palette.neutral[700], 0.5),
                          borderRadius: "50%",
                          color: "common.white",
                          cursor: "pointer",
                          display: "flex",
                          height: "100%",
                          justifyContent: "center",
                          left: 0,
                          opacity: 0,
                          position: "absolute",
                          top: 0,
                          width: "100%",
                          zIndex: 1,
                          "&:hover": {
                            opacity: 1,
                          },
                        }}
                      >
                        <Stack alignItems='center' direction='row' spacing={1}>
                          <SvgIcon color='inherit'>
                            <Camera01Icon />
                          </SvgIcon>
                          <Typography
                            color='inherit'
                            variant='subtitle2'
                            sx={{ fontWeight: 700 }}
                          >
                            Select
                          </Typography>
                        </Stack>
                      </Box>
                      <Avatar
                        src={avatar}
                        sx={{
                          height: 100,
                          width: 100,
                        }}
                      >
                        <SvgIcon>
                          <User01Icon />
                        </SvgIcon>
                      </Avatar>
                    </Box>
                  </Box>
                  <input
                    accept='image/*'
                    hidden
                    id='avatar-image-upload'
                    type='file'
                    onChange={(event) => {
                      setImageUpload(event.target.files[0]);
                    }}
                  />
                  <label htmlFor='avatar-image-upload'>
                    <Button
                      variant='contained'
                      color='primary'
                      component='span'
                      mb={2}
                      onClick={uploadFile}
                    >
                      {imageUpload ? "Uploading" : "Upload"}
                    </Button>
                  </label>
                </Stack> */}
                        <Stack direction="row" spacing={3}>
                          <div className="basicDetails">
                            <Typography
                              sx={{ marginBottom: "10px", fontSize: "14px" }}
                            >
                              First Name
                            </Typography>
                            <TextField
                              value={userFirstName}
                              variant="outlined"
                              size="small"
                              fullWidth
                              sx={{ flexGrow: 1 }}
                              onChange={(e) => setUserFirstName(e.target.value)}
                            />
                          </div>
                          <div className="basicDetails">
                            <Typography
                              sx={{ marginBottom: "10px", fontSize: "14px" }}
                            >
                              LastName
                            </Typography>
                            <TextField
                              value={userLastName}
                              variant="outlined"
                              size="small"
                              fullWidth
                              sx={{ flexGrow: 1 }}
                              onChange={(e) => setUserLastName(e.target.value)}
                            />
                          </div>
                        </Stack>
                        <Stack direction="row" spacing={3}>
                          <Stack
                            direction="column"
                            sx={{ width: "100%" }}
                            spacing={1}
                          >
                            <Typography
                              sx={{ color: "#050C46", fontSize: "14px" }}
                            >
                              Email
                            </Typography>
                            <TextField
                              defaultValue={email}
                              disabled
                              variant="outlined"
                              required
                              sx={{ color: "#050C46" }}
                              size="small"
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <img
                                    src={Email}
                                    style={{
                                      paddingRight: "10px",
                                      height: "23px",
                                    }}
                                  />
                                ),
                              }}
                            />
                            {/* <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div>
                                <Avatar
                                  {...stringAvatar(name)}
                                  sx={{
                                    backgroundColor: "#5766F6",
                                    color: "white",
                                    marginRight: "20px",
                                  }}
                                />
                              </div>
                              <div className="dropZoneArea">
                                {uploadingProfileImage && (
                                  <Oval
                                    height={70}
                                    width={70}
                                    color="#040b44"
                                    secondaryColor="#5664f1"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}
                                    wrapperClass="imageLoader"
                                    visible={true}
                                    ariaLabel="oval-loading"
                                  />
                                )}

                                <Dropzone
                                  onDrop={(acceptedFiles) => {
                                    handleUpload(acceptedFiles);
                                    console.log(acceptedFiles);
                                  }}
                                  accept={{
                                    "image/jpeg": [],
                                    "image/png": [],
                                    "image/jpg": [],
                                    "image/svg+xml": [],
                                    "image/svg": [],
                                    "image/gif": [],
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <section>
                                      <div {...getRootProps()}> */}
                            {/* <input
                                            {...getInputProps()}
                                            accept={".png, .jpg, .jpeg"}
                                          /> */}
                            {/* <img
                                          src={UploadIcon}
                                          className="uploadIcon"
                                        />
                                        <p className="dragText">
                                          <button
                                            onClick={() => inputRef.click()}
                                            className="uploadButton"
                                          > */}
                            {/* Click to upload */}
                            {/* </button> */}
                            {/* <input
                                              type="file"
                                              hidden={true}
                                              ref={(refParam) =>
                                                (inputRef = refParam)
                                              }
                                              accept={".png, .jpg, .jpeg"}
                                              onChange={(e) => {
                                                // handleUpload(e);
                                              }}
                                            /> */}
                            {/* a file or Drag and drop
                                        </p>
                                        <p className="svgText">
                                          SVG, PNG, JPG or GIF (max. 800x400px)
                                        </p>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>
                            </div> */}
                          </Stack>
                          {/* <Button color='inherit' size='small'>
                    Edit
                  </Button> */}
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
            </Card>

            <Card id="section2">
              <CardContent>
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  ref={section2Ref}
                  id="section2"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "15px",
                    }}
                  >
                    <>
                      <img
                        src={GlobeIcon}
                        style={{ marginRight: "10px", height: "23px" }}
                      />
                    </>
                    <Typography
                      className="subHeadding"
                      sx={{ fontWeight: "700", fontSize: "16px" }}
                    >
                      Company Details
                    </Typography>
                  </div>
                  <Divider />
                  <div style={{ marginTop: "20px" }}>
                    <Stack
                      direction="column"
                      spacing={2}
                      sx={{ fontSize: "14px" }}
                    >
                      <Typography className="title" sx={{ fontSize: "14px" }}>
                        {" "}
                        Company Name
                      </Typography>
                      <TextField
                        fullWidth
                        placeholder="Enter Company Name"
                        variant="outlined"
                        size="small"
                        type="text"
                        value={userCompanyName}
                        sx={{
                          flexGrow: 1,
                        }}
                        onChange={(e) => setuserCompanyName(e.target.value)}
                      />
                      {/* <Button onClick={updateCompany}>Save</Button> */}
                    </Stack>

                    <Stack sx={{ mt: 2 }} direction="column" spacing={2}>
                      <Typography className="title" sx={{ fontSize: "14px" }}>
                        Company Address
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Enter Company Address"
                        type="text"
                        value={userCompanyAddress}
                        sx={{
                          flexGrow: 1,
                        }}
                        onChange={(e) => setuserCompanyAddress(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src={MarkerPin} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {/* <Button onClick={updateCompanyAddress}>Save</Button> */}
                    </Stack>
                  </div>
                </div>
              </CardContent>
            </Card>

            {/* phone number details  */}
            <Card>
              <CardContent>
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  ref={section3Ref}
                  id="section3"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "15px",
                    }}
                  >
                    <>
                      <img
                        src={Phonecall}
                        style={{ marginRight: "10px", height: "23px" }}
                      />
                    </>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "#050C46",
                      }}
                    >
                      Phone Number
                    </Typography>
                  </div>
                  <Divider />
                  <div style={{ marginTop: "20px" }}>
                    <Stack direction="column" spacing={2}>
                      <Typography sx={{ fontSize: "14px", color: "#050C46" }}>
                        Phone Number
                      </Typography>

                      <MuiTelInput
                        value={userPhoneNumber}
                        onChange={handleChange}
                        style={{ flex: 1 }}
                      />
                      {/* <Button onClick={updateUserPhoneNumber}>Save</Button> */}
                    </Stack>
                  </div>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  ref={section4Ref}
                  id="section4"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "15px",
                    }}
                  >
                    <>
                      <img
                        src={Key}
                        style={{ marginRight: "10px", height: "23px" }}
                      />
                    </>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "#050C46",
                      }}
                    >
                      Change password
                    </Typography>
                  </div>
                  <Divider />
                  <Grid xs={12} sm={12} md={8} sx={{ marginTop: "20px" }}>
                    <Stack direction="column" spacing={2}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#050C46",
                          fontWeight: 500,
                        }}
                      >
                        Previous password
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Previous password"
                        type="password"
                        size="small"
                        value={prevPassword}
                        sx={{
                          flexGrow: 1,
                        }}
                        onChange={(e) => setPrevPassword(e.target.value)}
                      // InputProps={{
                      //   endAdornment: (
                      //     <>
                      //       {" "}
                      //       <InputAdornment position="end">
                      //         <IconButton
                      //           aria-label="toggle password visibility"
                      //           // onClick={handleClickShowPassword}
                      //           // onMouseDown={handleMouseDownPassword}
                      //           edge="end"
                      //           sx={{ mr: "1px" }}
                      //         >
                      //           <Visibility />
                      //           {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                      //         </IconButton>
                      //       </InputAdornment>
                      //     </>
                      //   ),
                      // }}
                      />
                    </Stack>
                    <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#050C46",
                          fontWeight: 500,
                        }}
                      >
                        New password
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        placeholder="Enter New password"
                        type="password"
                        value={newPassword}
                        sx={{
                          flexGrow: 1,
                        }}
                        onChange={(e) => setNewPassword(e.target.value)}
                      // InputProps={{
                      //   endAdornment: (
                      //     <>
                      //       {" "}
                      //       <InputAdornment position="end">
                      //         <IconButton
                      //           aria-label="toggle password visibility"
                      //           // onClick={handleClickShowPassword}
                      //           // onMouseDown={handleMouseDownPassword}
                      //           edge="end"
                      //           sx={{ mr: "1px" }}
                      //         >
                      //           <Visibility />
                      //           {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                      //         </IconButton>
                      //       </InputAdornment>
                      //     </>
                      //   ),
                      // }}
                      />
                      <Button onClick={handlePasswordUpdate}>
                        Change Password
                      </Button>
                    </Stack>
                  </Grid>
                </div>
              </CardContent>
            </Card>

            {/* <Card>
            <CardContent>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  ref={section5Ref}
                  id="section5"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "15px",
                  }}
                >
                  <>
                    <img src={MeetingIcon} style={{ marginRight: "10px", height: '23px' }} />
                  </>
                  <Typography
                    sx={{ fontWeight: "700", fontSize: "16px", color: "#050C46" }}
                  >
                    Meetings Available Slots
                  </Typography>
                </div>
                <Divider />
                <div style={{ justifyContent: "flex-end", marginTop: "20px" }}>
                  <Autocomplete
                    getOptionLabel={(option) => option.text}
                    options={dates}
                    sx={{ mb: '20px' }}
                    renderInput={(params) => (
                      <>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "#050C46",
                            fontWeight: 500,
                            marginBottom: '10px'
                          }}
                        >
                          Select Date
                        </Typography>
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          placeholder="Enter available slots"
                          name="TimeSlots"
                        />
                      </>
                    )}
                  />
                  <Divider />
                  <div style={{ marginTop: '20px' }}>
                    <div style={{ border: '1px solid #ECE9F5', backgroundColor: '#ECE9F5', height: '84px', borderRadius: '12px' }}>

                    </div>
                    <div style={{ border: '1px solid #ECE9F5', backgroundColor: '#ECE9F5', height: '84px', borderRadius: '12px', marginTop: '15px' }}>

                    </div>
                  </div> */}
            {/* {dates?.map((date, index) => {
                  return (
                    <>
                      <UserMeetingSchedules
                        date={moment(date).format("ddd,MMM Do")}
                        key={index}
                        index={index}
                      />
                    </>
                  );
                })} */}
            {/* </div>
              </div>
            </CardContent>
          </Card> */}

            {/* <Card>
              <CardContent>
                <div
                  ref={section6Ref}
                  id="section6"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "15px",
                    }}
                  >
                    <>
                      <img
                        src={Link}
                        style={{ marginRight: "10px", height: "23px" }}
                      />
                    </>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "#050C46",
                      }}
                    >
                      Meeting Link
                    </Typography>
                  </div>
                  <Divider />
                  <div style={{ marginTop: "20px" }}>
                    <Stack direction="column" spacing={2}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#050C46",
                          fontWeight: 500,
                        }}
                      >
                        Link
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        placeholder="Add Link"
                        type="text"
                        value={userMeetingLink}
                        sx={{
                          flexGrow: 1,
                        }}
                        onChange={(e) => setuserMeetingLink(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src={LinkIcon} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                  </div>
                </div>
              </CardContent>
            </Card> */}

            <Card>
              <CardContent>
                <div
                  ref={section7Ref}
                  id="section7"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "15px",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <>
                      <img
                        src={calenderPlus}
                        style={{ marginRight: "10px", height: "23px" }}
                      />
                    </>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "#050C46",
                      }}
                    >
                      Link calendar
                    </Typography>
                    {!conferencingLinked.linked && (
                      <Chip
                        size="small"
                        color="error"
                        label="Link meeting platform"
                        onClick={() => {
                          handleConferencingLink(
                            conferencingLinked.accessToken
                          );
                        }}
                        icon={<LinkIcon2 style={{ color: "white" }} />}
                      />
                    )}
                  </div>
                  <Divider />
                  <div style={{ marginTop: "20px" }}>
                    <CalendarSyncWrapper
                      options={calendarOptions}
                      email={email}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                {Boolean(availabilityElements.length) && (
                  <div
                    ref={section8Ref}
                    id="section8"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "15px",
                      }}
                    >
                      <>
                        <img
                          src={SelectIcon}
                          style={{ marginRight: "10px", height: "23px" }}
                        />
                      </>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "16px",
                          color: "#050C46",
                        }}
                      >
                        {/* Select Your Availability */}
                        Select Slot Preset
                      </Typography>
                    </div>
                    <Divider />
                    <div id="availability-grid" style={{ marginTop: "20px" }}>
                      {availabilityElements.map((element) => element)}
                    </div>
                  </div>
                )}
                <SlotPresetSetting />
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                <div
                  ref={section9Ref}
                  id="section9"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "15px",
                    }}
                  >
                    <>
                      <img
                        src={LinkIcon}
                        style={{ marginRight: "10px", height: "23px" }}
                      />
                    </>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "#050C46",
                      }}
                    >
                      Integrations
                    </Typography>
                  </div>
                  <Divider />

                  <div style={{ marginTop: "20px" }}>
                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="space-between"
                      sx={{ border: "1px solid #EAECF0", borderRadius: "12px" }}
                    >
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        {/* <img
                      style={{ marginRight: "10px",height:'23px'}}
                      width="28"
                      height="28"
                      src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/48/external-hubspot-a-developer-and-marketer-of-software-products-logo-color-tal-revivo.png"
                      alt="external-hubspot-a-developer-and-marketer-of-software-products-logo-color-tal-revivo"
                    /> */}
                        <div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              // flexDirection: "row",
                              // margin: "10px",
                            }}
                          >
                            <div style={{ width: "33%" }}>
                              <Button
                                variant="outlined"
                                href={`${API_SERVICE_BACKEND_2}/oauth?userEmail=${email}`}
                                sx={{ margin: "5px", border: "none" }}
                              >
                                <img
                                  style={{ marginRight: "10px" }}
                                  width="28"
                                  height="28"
                                  src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/48/external-hubspot-a-developer-and-marketer-of-software-products-logo-color-tal-revivo.png"
                                  alt="external-hubspot-a-developer-and-marketer-of-software-products-logo-color-tal-revivo"
                                />
                                Hubspot
                              </Button>
                            </div>
                            {hasHubSpotIntegration ? (
                              <div
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  color: hubspotExpired ? "red" : "green",
                                }}
                              >
                                {/* <Typography style={{ fontSize: "13px" }}>
                                  Expires:
                                  {moment
                                    .tz(
                                      hubSpotIntegration.expires_at,
                                      APP_TIMEZONE
                                    )
                                    .format("MM/DD/YYYY hh:mm A z")}
                                </Typography> */}
                                <Button
                                  variant="outlined"
                                  href={`${API_SERVICE_BACKEND_2}/oauth?userEmail=${email}`}
                                  sx={{
                                    color: "#050C46",
                                    border: "1px solid #ECE9F5",
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <>
                                    <img
                                      src={LinkIcon}
                                      style={{ marginRight: "8px" }}
                                    />
                                  </>
                                  Reconnect
                                </Button>
                                <Button
                                  variant="outlined"
                                  onClick={() =>
                                    handleDisconnect(
                                      user?.dbRef?.email,
                                      "hubspot"
                                    )
                                  }
                                  sx={{
                                    color: "#050C46",
                                    border: "1px solid #ECE9F5",
                                    marginRight: "10px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <>
                                    <img
                                      src={Linkbroken}
                                      style={{ marginRight: "8px" }}
                                    />
                                  </>
                                  Disconnect
                                </Button>
                              </div>
                            ) : null}
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ width: "33%" }}>
                              <Button
                                variant="outlined"
                                onClick={initiateZohoOAuth}
                                sx={{ margin: "5px", border: "none" }}
                              >
                                <img
                                  style={{ marginRight: "10px" }}
                                  width="28"
                                  height="28"
                                  src="https://uploads-ssl.webflow.com/594d8d64dc2b583adddea915/5a7fd806c7942700013399da_zoho.png"
                                  alt="external-hubspot-a-developer-and-marketer-of-software-products-logo-color-tal-revivo"
                                />
                                Zoho
                              </Button>
                            </div>
                            {hasZohoIntegration ? (
                              <div
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  color: zohoExpired ? "red" : "green",
                                }}
                              >
                                {/* <Typography style={{ fontSize: "13px" }}>
                                  Expires:
                                  {moment
                                    .tz(
                                      zohoIntegration.expires_at,
                                      APP_TIMEZONE
                                    )
                                    .format("MM/DD/YYYY hh:mm A z")}
                                </Typography> */}
                                <Button
                                  variant="outlined"
                                  onClick={initiateZohoOAuth}
                                  sx={{
                                    color: "#050C46",
                                    border: "1px solid #ECE9F5",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <>
                                    <img
                                      src={LinkIcon}
                                      style={{ marginRight: "8px" }}
                                    />
                                  </>
                                  Reconnect
                                </Button>
                                <Button
                                  variant="outlined"
                                  onClick={() =>
                                    handleDisconnect(user?.dbRef?.email, "zoho")
                                  }
                                  sx={{
                                    color: "#050C46",
                                    border: "1px solid #ECE9F5",
                                    marginRight: "10px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <>
                                    <img
                                      src={Linkbroken}
                                      style={{ marginRight: "8px" }}
                                    />
                                  </>
                                  Disconnect
                                </Button>
                              </div>
                            ) : null}
                          </div>

                          {/* <p
                        style={{
                          margin: "0px",
                          fontSize: "14px",
                          color: "#55597A",
                        }}
                      >
                        {email}
                      </p> */}
                        </div>
                      </div>
                      {/* <Button
                        variant="outlined"
                        href={`${API_SERVICE_BACKEND_2}/oauth?userEmail=${email}`}
                        sx={{
                          color: "#050C46",
                          border: "1px solid #ECE9F5",
                          marginRight: "10px",
                        }}
                      >
                        <>
                          <img
                            src={Linkbroken}
                            style={{ marginRight: "8px" }}
                          />
                        </>
                        Disconnect
                      </Button> */}

                      {/* <Button color="inherit" variant="outlined" onClick={initiateOAuth}>
                  <img style={{ marginRight: '10px' }} width="28" height="28" src="https://avatars.slack-edge.com/2020-08-05/1306743165504_831b4f7b271a4fff5688_512.png" alt="external-hubspot-a-developer-and-marketer-of-software-products-logo-color-tal-revivo"/>
                  Zoho CRM
                </Button> */}
                    </Stack>
                  </div>
                </div>
              </CardContent>
            </Card>

            {/* <Card>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              xs={12}
              md={4}
            >
              <Typography variant="h6">
                Public profile
              </Typography>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={8}
            >
              <Stack
                divider={<Divider />}
                spacing={3}
              >
                <Stack
                  alignItems="flex-start"
                  direction="row"
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Stack spacing={1}>
                    <Typography variant="subtitle1">
                      Make Contact Info Public
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                    >
                      Means that anyone viewing your profile will be able to see your contacts
                      details.
                    </Typography>
                  </Stack>
                  <Switch />
                </Stack>
                <Stack
                  alignItems="flex-start"
                  direction="row"
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Stack spacing={1}>
                    <Typography variant="subtitle1">
                      Available to hire
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                    >
                      Toggling this will let your teammates know that you are available for
                      acquiring new projects.
                    </Typography>
                  </Stack>
                  <Switch defaultChecked />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card> */}
            {/* <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid xs={12} md={4}>
              <Typography variant='h6'>Delete Account</Typography>
            </Grid>
            <Grid xs={12} md={8}>
              <Stack alignItems='flex-start' spacing={3}>
                <Typography variant='subtitle1'>
                  Delete your account and all of your source data. This is
                  irreversible.
                </Typography>
                <Button color='error' variant='outlined'>
                  Delete account
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card> */}
          </Stack>
        }
      </div>
    </>
  );
};

AccountGeneralSettings.propTypes = {
  avatar: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
