import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import PropTypes from "prop-types";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { firebaseApp } from "src/libs/firebase";
import { Issuer } from "src/utils/auth";
import { getUserInfo } from "src/pages/dashboard/account";
import { API_SERVICE_BACKEND } from "src/config";
import axios from "axios";

const auth = getAuth(firebaseApp);

var ActionType;
(function (ActionType) {
  ActionType["AUTH_STATE_CHANGED"] = "AUTH_STATE_CHANGED";
})(ActionType || (ActionType = {}));

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === "AUTH_STATE_CHANGED") {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

export const AuthContext = createContext({
  ...initialState,
  issuer: Issuer.Firebase,
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  sendPasswordResetEmail: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleAuthStateChanged = useCallback(
    async (user) => {
      if (user) {
        // Here you should extract the complete user profile to make it available in your entire app.
        // The auth state only provides basic information.
        // console.log(user);
        const userInfo = await getUserInfo(user.email);
        let integrationsData = await axios
          .get(
            `${API_SERVICE_BACKEND}/getUserIntegrations/${encodeURIComponent(
              user.email
            )}`
          )
          .then((res) => res.data)
          .catch((err) => {
            console.log(err);
          });

        // console.log(userInfo);
        dispatch({
          type: ActionType.AUTH_STATE_CHANGED,
          payload: {
            isAuthenticated: true,
            user: {
              integrations: {
                hubspot: integrationsData.find(
                  (integration) => integration.type === "hubspot"
                ),
                cronofy: userInfo.extra?.accessToken,
                zoho: integrationsData.find(
                  (integration) => integration.type === "zoho"
                ),
              },
              dbRef: userInfo,
              ref: user,
              id: user.uid,
              avatar: user.photoURL || undefined,
              email: user.email || "UNKNOWN",
              name: user.displayName || "UNKNOWN",
              plan: user.data?.plan || "GROW", // SCALE | GROW
              organisation: user.data?.organisation || "UNKNOWN",
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.AUTH_STATE_CHANGED,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    },
    [dispatch]
  );

  useEffect(
    () => onAuthStateChanged(auth, handleAuthStateChanged),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const _signInWithEmailAndPassword = useCallback(async (email, password) => {
    await signInWithEmailAndPassword(auth, email, password);
  }, []);

  // const signInWithGoogle = useCallback(async () => {
  //   const provider = new GoogleAuthProvider();

  //   await signInWithPopup(auth, provider);
  // }, []);
  const signInWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();
    let finalResult;

    await signInWithPopup(auth, provider).then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      // The signed-in user info.
      const user = result.user;
      finalResult = user;
    });
    return finalResult;
  }, []);

  const _createUserWithEmailAndPassword = useCallback(
    async (email, password) => {
      await createUserWithEmailAndPassword(auth, email, password);
    },
    []
  );

  const _signOut = useCallback(async () => {
    await signOut(auth);
    localStorage.clear();
    sessionStorage.clear();
    dispatch({
      type: ActionType.AUTH_STATE_CHANGED,
      payload: {
        isAuthenticated: false,
        user: null,
      },
    });
  }, []);

  const _forgotPassword = useCallback(async (email) => {
    await sendPasswordResetEmail(auth, email);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        updateAuthState: dispatch,
        issuer: Issuer.Firebase,
        createUserWithEmailAndPassword: _createUserWithEmailAndPassword,
        signInWithEmailAndPassword: _signInWithEmailAndPassword,
        signInWithGoogle,
        signOut: _signOut,
        forgotPassword: _forgotPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
