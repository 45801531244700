import Section from "src/components/logos/Section.png";
import RegSection from "src/components/logos/regSection.png";
import ForgotSection from "src/components/logos/forgotSection.png";
import { useState } from "react";

export const RegistrationImage = ({ setRegImage, imageSource, currentUrl }) => {
  const [currentTndex, setcurrentIndex] = useState(1);
  const images = [Section, RegSection, ForgotSection];

  const changeImage = (index) => {
    setcurrentIndex(index);
    const newImage = images[index];
    setRegImage(newImage);
  };

  const handleChangeImage = (index) => {
    if (index === 0) {
      setcurrentIndex(2);
      setRegImage(images[2]);
    } else {
      setcurrentIndex(index - 1);
      const newImage = images[index - 1];
      setRegImage(newImage);
    }
  };

  const handleImage = (index) => {
    if (index === 2) {
      setcurrentIndex(0);
      setRegImage(images[0]);
    } else {
      setcurrentIndex(index + 1);
      const newImage = images[index + 1];
      setRegImage(newImage);
    }
  };

  return (
    <div style={{ position: "relative", height: "100vh", overflow: "hidden" }}>
      <img
        src={imageSource}
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
        }}
      />
      {!currentUrl.includes("login") && (
        <>
          <button
            onClick={() => handleChangeImage(currentTndex)}
            style={{
              position: "absolute",
              zIndex: 1,
              bottom: "230px",
              left: "200px",
              background: "none",
              border: "none",
              color: "white",
            }}
          >
            &lt;
          </button>
          {images.map((image, index) => (
            <button
              key={index}
              style={{
                position: "absolute",
                padding: "5px",
                backgroundColor: index === currentTndex ? "white" : "#A2A5CD",
                borderRadius: "16px",
                bottom: "230px",
                left: `${280 + 40 * index}px`,
                zIndex: 1,
              }}
              onClick={() => changeImage(index)}
            >
              <div
                style={{
                  width: "2px",
                  height: "2px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              ></div>
            </button>
          ))}
          <button
            onClick={() => handleImage(currentTndex)}
            style={{
              position: "absolute",
              zIndex: 1,
              bottom: "230px",
              right: "200px",
              background: "none",
              border: "none",
              color: "white",
            }}
          >
            &gt;
          </button>
        </>
      )}
    </div>
  );
};
