import React from "react";
import { Box, ButtonBase, Collapse, SvgIcon } from "@mui/material";
import { RouterLink } from "src/components/router-link";

const SideNavFooterItem = (props) => {
  const {
    active,
    children,
    depth = 0,
    disabled,
    external,
    icon,
    label,
    open: openProp,
    path,
    title,
    handleClick,
  } = props;
  let startIcon = icon;
  const linkProps = path
    ? external
      ? {
          component: "a",
          href: path,
          target: "_blank",
        }
      : {
          component: RouterLink,
          href: path,
        }
    : {};

  return (
    <li style={{marginTop:'10px'}}>
      <ButtonBase
        disabled={disabled}
        onClick={handleClick}
        sx={{
          alignItems: "center",
          borderRadius: 1,
          display: "flex",
          justifyContent: "flex-start",
          pl: `${16}px`,
          pr: "16px",
          py: "6px",
          textAlign: "left",
          width: "100%",
          ...(active && {
            ...(depth === 0 && {
              backgroundColor: "var(--nav-item-active-bg)",
            }),
          }),
          "&:hover": {
            backgroundColor: "var(--nav-item-hover-bg)",
          },
        }}
        {...linkProps}
      >
        {startIcon && (
          <Box
            component="span"
            sx={{
              alignItems: "center",
              //color: "var(--nav-item-icon-color)",
              display: "inline-flex",
              justifyContent: "center",
              color: "var(--nav-item-active-color)",
              mr: 2,
              ...(active && {
                color: "var(--nav-item-active-color)",
              }),
            }}
          >
            {startIcon}
          </Box>
        )}
        <Box
          component="span"
          sx={{
            color: "var(--nav-item-active-color)",
            flexGrow: 1,
            fontFamily: (theme) => theme.typography.fontFamily,
            fontSize: depth > 0 ? 13 : 14,
            fontWeight: 500, // depth > 0 ? 500 : 600,
            lineHeight: "24px",

            whiteSpace: "nowrap",
            ...(active && {
              color: "var(--nav-item-active-color)",
            }),
            ...(disabled && {
              color: "var(--nav-item-disabled-color)",
            }),
          }}
        >
          {title}
        </Box>
        {label && (
          <Box component="span" sx={{ ml: 2 }}>
            {label}
          </Box>
        )}
      </ButtonBase>
    </li>
  );
};

export default SideNavFooterItem;
