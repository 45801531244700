import { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  Skeleton,
  createTheme,
  Dialog,
  DialogTitle,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Chip,
  DialogContent,
  DialogActions,
  Container,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
  FormControl,
  InputLabel,
  DialogContentText,
  AvatarGroup,
  Tooltip,
  useMediaQuery,
  SvgIcon,
  Autocomplete,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import companyLogo1 from "../assets/companyLogo1.png";
import b2b from "../assets/b2b.png";
import companyLogo2 from "../assets/companyLogo2.png";
import vector from "../assets/2.png";
import LogoutIcon from "@mui/icons-material/Logout";
import send from "../assets/send.png";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { toast as hotToast } from "react-hot-toast";
import {
  API_SERVICE_BACKEND,
  API_SERVICE_BACKEND_2,
  cronofy,
  API_SERVICE_BACKEND_CRONOFY,
  API_HIPPO_VIDEO,
  API_SERVICE_BACKEND_3,
} from "../config";
import { DateTimePickerWrapper } from "../components/cronofy";
import { cronofyApi } from "../api/cronofy";
import moment from "moment/moment";
import { MainLogo } from "src/assets/chatPage/MainLogo";
import { UserAvatar } from "src/assets/chatPage/UserAvatar";
import { BotAvatar } from "src/assets/chatPage/BotAvatar";
import { array } from "prop-types";
import BouncingDotsLoader from "src/components/BouncingDotsLoader";
import { margin } from "@mui/system";
import { RefreshIcon } from "src/assets/chatPage/RefreshIcon";
import { CheckBoxOnIcon } from "src/assets/chatPage/CheckBoxOnIcon";
import { CheckBoxOffIcon } from "src/assets/chatPage/CheckBoxOffIcon";
import { CalendarIcon } from "src/assets/chatPage/CalendarIcon";
// import ReactMarkdown from "markdown-to-jsx";
import { option } from "src/assets/data";
import dayjs from "dayjs";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment1 from "moment-timezone";
import B2BRocketLogo from "src/assets/blue icon square.png";
import arrowRight from "../assets/arrowRight.png";
import Star from "../assets/Star.png";
import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";
import { QuillEditor } from "src/components/quill-editor";
import CloseIcon from "@mui/icons-material/Close";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import avatar1 from "../assets/avatar1.png";
import avatar2 from "../assets/avatar2.png";
import avatar3 from "../assets/avatar3.png";
import avatar4 from "../assets/avatar4.png";
import avatar5 from "../assets/avatar5.png";
import avatar6 from "../assets/avatar6.png";
import user from "../assets/user.png";
import chatButton from "../assets/chatButton.png";
import chatIcon from "../assets/chatIcon.png";
import menuIcon from "../assets/menuIcon.png";
import { useMobileNav } from "src/layouts/dashboard/vertical-layout/use-mobile-nav";
import { alpha } from "@mui/material/styles";
import { Scrollbar } from "src/components/scrollbar";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { jobsApi } from "src/api/jobs";
import "./style.css";
import { useRoutes } from "react-router-dom";
import { routes } from "../routes/index";
const { getGPT_Prompt } = require("./chatTemplate");

const MOBILE_NAV_WIDTH = 420;
const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;

// const axiosOpenAI = axios.create({
//   baseURL: "https://api.openai.com/v1/",
//   headers: {
//     Authorization: `Bearer ${process.env.REACT_APP_OPENAI_SECRET}`,
//     "Content-Type": "application/json",
//   },
// });

// fontFamily: "DM Sans, sans-serif",

const { meetPrefs } = cronofy;
// test
// const getNextThreeTimes = (timezone, stateValue1, stateValue2) => {
//   let currentTime = moment1().tz(timezone);
//   let nextTimes = [];
//   let Times = [];
//   currentTime.add(30 - (currentTime.minute() % 30), "minutes");

//   for (let i = 0; i < 3; i++) {
//     if (currentTime?.hour() >= 17) {
//       currentTime.add(1, "days").set({ hour: 9, minute: 0 });
//     }
//     if (currentTime.day() === 6) {
//       currentTime.add(2, "days").set({ hour: 9, minute: 0 });
//     } else if (currentTime.day() === 0) {
//       currentTime.add(1, "days").set({ hour: 9, minute: 0 });
//     }
//     const formattedTime = currentTime.format("h:mm a");
//     Times.push(formattedTime);
//     stateValue1(Times);
//     nextTimes.push(currentTime.format("ddd, MMM Do h:mm a").toUpperCase());
//     currentTime.add(45, "minutes");
//   }
//   stateValue2(nextTimes)
//   // stateValue2(nextTimes)
// }

// const getNextThreeTimes = (timezone, stateValue1, stateValue2) => {
//   let currentTime = moment1().tz(timezone);
//   let nextTimes = [];
//   let Times = [];
//   currentTime.add(30 - (currentTime.minute() % 30), "minutes");

//   for (let i = 0; i < 3; i++) {
//     if (
//       currentTime?.hour() >= 18 ||
//       (currentTime?.hour() === 17 && currentTime.minute() >= 15)
//     ) {
//       currentTime.add(1, "days").set({ hour: 9, minute: 0 });
//     }
//     if (currentTime.day() === 6) {
//       currentTime.add(2, "days").set({ hour: 9, minute: 0 });
//     } else if (currentTime.day() === 0) {
//       currentTime.add(1, "days").set({ hour: 9, minute: 0 });
//     }
//     const formattedTime = currentTime.format("h:mm a");
//     Times.push(formattedTime);
//     stateValue1(Times);
//     nextTimes.push(currentTime.format("ddd, MMM Do h:mm a").toUpperCase());
//     currentTime.add(45, "minutes");
//   }
//   stateValue2(nextTimes);
// };

// changes

const filterTimeslot = (selectedDate, timezone, stateValue) => {
  const date = moment().format("l");
  const newdate = selectedDate.format("l");
  if (
    new Date(date).getDay() === 6 ||
    new Date(date).getDay() === 0 ||
    new Date(newdate).getDay() === 6 ||
    new Date(newdate).getDay() === 0
  ) {
    return;
  }
  const currentTimeMoment = moment1().tz(timezone);
  // let HourTimeslots = []
  const timeslot = [
    "9:00AM",
    "9:45AM",
    "10:30AM",
    "11:15AM",
    "12:00PM",
    "12:45PM",
    "01:30PM",
    "02:15PM",
    "03:00PM",
    "03:45PM",
    "04:30PM",
    "05:15PM",
  ];
  if (date === newdate) {
    const filteredTimeslot = timeslot.filter((time) => {
      const timeMoment = moment(time, "h:mmA");
      return timeMoment.isSameOrAfter(currentTimeMoment);
    });
    stateValue(filteredTimeslot);
  } else {
    // timeslot.forEach((slot, index) => {
    //   if (index % 2 === 1) {
    //     // Only consider odd-indexed timeslots (e.g., 9:30AM, 10:30AM, etc.)
    //     HourTimeslots.push(timeslot[index - 1]);
    //   }
    // });
    stateValue(timeslot);
  }
};

const askForMeet = (options) => {
  const { client } = options;
  return `Alternatively, I found the right team member, <b>${client.name}</b>, Partnership Manager, who has worked with similar clients in your industry. We've limited slots available for a strategy session. Book a slot using this full calendar slots or pick whichever slot is convenient for you from the options below.`;
};

const limitedSlotText = (timezone, handleClickOpen) => {
  // const {} = options;
  return `We've limited slots available for a strategy session. Book a slot using this <a style="cursor:pointer" onclick="${handleClickOpen}" ><b>full calendar slots</b></a> or pick whichever slot is convenient for you from the options below.`;
};

// class="full_calander_slot"
// onClick={(e) => {
// }}
const LimitedSlotText = (props) => {
  const { timezone, handleClickOpen } = props;
  return (
    <Typography
      style={{ fontSize: "14px", color: "#050C46", fontWeight: "400" }}
    >
      I've limited slots available for a strategy session. Book a slot using
      this{" "}
      <a
        style={{ cursor: "pointer" }}
        className="full_calander_slot"
        onClick={handleClickOpen}
      >
        <b>full calendar slots</b>
      </a>{" "}
      or pick whichever slot is convenient for you from the options below.
    </Typography>
  );
};

const meetKeywords = [
  "schedule",
  "meet",
  "meeting",
  "connect",
  "representative",
  "reach",
  "SHOW",
];

const decoder = new TextDecoder("utf-8");

const checkKeywords = (input) => {
  for (let i = 0; i < meetKeywords.length; i++) {
    const pattern = new RegExp(meetKeywords[i], "i");
    if (pattern.test(input)) {
      return true;
    }
  }
  return false;
};

const getUserInfo = async (email) => {
  try {
    const res = await axios.get(
      `${API_SERVICE_BACKEND}/getuserdetail/${email}`
    );
    if (!res.data.status) return;
    return res.data.data;
  } catch (error) {
    return;
  }
};

// getElementToken(campaignId.clientEmail, ["availability"])
const getElementToken = async (accessToken, permissions) => {
  const token = await cronofyApi.getElementToken({
    sub: accessToken.sub,
    permissions,
    origin: cronofy.origin,
  });
  return token?.element_token?.token;
};
const getUserMeetings = async (email) => {
  try {
    const campaigns = await jobsApi.getUserCampaigns(email);
    const campaignIds = campaigns.map(({ _id }) => _id);
    let url = `${API_SERVICE_BACKEND_2}/dashboard-chart`;
    url += `/?campaignIds=${campaignIds.join(",")}`;
    const response = await fetch(url);
    const data = await response.json();
    const meetings = data?.data?.meetings;
    if (!meetings || !Array.isArray(meetings)) {
      return 0;
    }
    const totalCount = meetings.reduce((sum, convo) => sum + convo.count, 0);
    return totalCount;
  } catch (err) {
    console.log(err);
  }
};

const handleSlotConfirmation = async (notification, extra) => {
  const { slot, type, tzid } = notification;
  // const slot = JSON.stringify(slot);

  const {
    setCronofyElements,
    handlePromptForMeet,
    accessTokenStr,
    leadInfo,
    campaignData,
  } = extra;

  const meetingDate = moment(slot.start).format("DD MMM YYYY");
  const start = moment(slot.start).format("LT");
  const end = moment(slot.end).format("LT");

  // this api call can also handle tracking of meetings count ! **disabled for now
  const [status, error] = await cronofyApi.addEvent({});
  if (!status) {
    setCronofyElements([
      <div>
        <Typography variant="h6">{error}</Typography>
      </div>,
    ]);
    return;
  }

  // Increment meet count in campaign model !
  // const payload = {
  //   "operationStat.meetings": {
  //     $cond: {
  //       if: { $gt: [{ $ifNull: ["$operationStat.meetings", 0] }, 0] },
  //       then: { $add: ["$operationStat.meetings", 1] },
  //       else: 1,
  //     },
  //   },
  // };
  // const updateRes = await axios.patch(`${API_SERVICE_BACKEND}/updateCampaign`, {
  //   id: campaignData._id,
  //   payload,
  //   aggregate: true,
  // });

  handlePromptForMeet("hidden", true);
  setCronofyElements([
    <div>
      <Typography variant="title1">Event details:</Typography>
      <br />
      <Typography variant="title1">
        <strong>Date:</strong> {meetingDate}
      </Typography>
      <br />
      <Typography variant="title1">
        <strong>Time:</strong> {start} to {end}
      </Typography>
    </div>,
  ]);
};

const TimePopup = ({
  // timeSlotes,
  calenderId,
  sub,
  selectedDates,
  handleDateChange,
  option,
  setTimezones,
  cronofyToken,
  timezones,
  handleClose,
  open,
  handleOpen2,
  campaignData,
  handleDisable = () => { },
}) => {
  const [timeSlotes, setTimeSlotes] = useState(null);
  // const [cronofyToken, setCronofyToken] = useState('')
  const [cronofyData, setCronofyData] = useState([]);
  // const [calenderId, setCalenderId] = useState('')
  // const [sub, setSub] = useState()
  // const userEmail = localStorage.getItem("userEmail");

  // useEffect(() => {
  //   const getAccessToken = async () => {
  //     const userInfo = await getUserInfo(userEmail);
  //     const accessToken = userInfo?.extra?.accessToken?.access_token;
  //     const sub = userInfo?.extra?.accessToken?.sub
  //     setSub(sub)
  //     setCronofyToken(accessToken)
  //   };
  //   getAccessToken()
  // }, [userEmail])

  // const cronofycalendar = async () => {
  //   try {
  //     const responseDataLog = await fetch(
  //       `${API_SERVICE_BACKEND_2}/calendar`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "cronofyToken": cronofyToken
  //         },
  //       }
  //     ).then((res) => res.json());
  //     setCalenderId(responseDataLog.calendars[0].calendar_id)
  //   } catch (err) {
  //
  //   }
  // }

  const date1 = moment().format("YYYY-MM-DD");
  const date2 = selectedDates.format("YYYY-MM-DD");
  const utctime = moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
  const cronofyApi3 = async () => {
    const email = campaignData?.clientEmail; //localStorage.getItem("userEmail");
    const userSlotPreset = await fetch(
      `${API_SERVICE_BACKEND_3}/get-slot-preset/${email}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const duration = await userSlotPreset.json();
    const slotDuration = duration?.data?.slotsPreset;
    try {
      let data = {
        participants: [
          {
            members: [
              {
                sub: sub,
                calendar_ids: [calenderId],
                managed_availability: true,
              },
            ],
            required: 1,
          },
        ],
        // required_duration: { minutes: 60 },
        required_duration: { minutes: slotDuration ? slotDuration : 30 },
        response_format: "slots",
        // In query_periods, we are giving timeframe EST 9 AM to 6 PM for getting timeslots.
        // query_periods: [
        //   {
        //     // start: date1 == date2 ? utctime : moment(selectedDates.format('YYYY-MM-DDT09:00:00Z')).utc(),
        //     // end: moment(selectedDates.format('YYYY-MM-DDT18:00:00Z')).utc()
        //     start:
        //       date1 == date2
        //         ? utctime
        //         : moment(date2)
        //           .add(1, "days")
        //           .utc()
        //           .format("YYYY-MM-DDT14:00:00[Z]"), // here, we are converting in EST 9 AM in UTC
        //     end:
        //       date1 == date2
        //         ? moment().utc().format("YYYY-MM-DDT23:59:00[Z]") // here, we are converting in EST 6 PM in UTC
        //         : moment(date2)
        //           .add(1, "days")
        //           .utc()
        //           .format("YYYY-MM-DDT23:00:00[Z]"),
        //   },
        // ],
        query_periods: [
          {
            start:
              date1 === date2
                ? utctime
                : moment(date2)
                  .startOf("day")
                  // .utc()
                  .format("YYYY-MM-DDT00:00:00[Z]"),
            end:
              date1 === date2
                ? moment().utc().format("YYYY-MM-DDT23:59:59[Z]")
                : moment(date2)
                  // .add(1, "days")
                  // .utc()
                  .format("YYYY-MM-DDT23:59:59[Z]"),
          },
        ],
      };

      let buffer = duration?.data?.buffer;
      if (buffer && buffer.isSetBuffer) {
        data = {
          ...data,
          buffer: {
            before: { minutes: buffer.before },
            after: { minutes: buffer.after },
          },
        };
      }

      const responseDataLog = await fetch(
        `${API_SERVICE_BACKEND_3}/availability`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            cronofyToken: cronofyToken,
          },
          body: JSON.stringify(data),
        }
      ).then((res) => res.json());
      // setCronofyData(responseDataLog?.data?.available_periods)
      setCronofyData(responseDataLog?.data?.available_slots);
    } catch (err) { }
  };

  // useEffect(() => {
  //   if (cronofyToken && open) {
  //     cronofycalendar()
  //   }
  // }, [cronofyToken, open])

  useEffect(() => {
    setTimeSlotes(null);
    if (calenderId && cronofyToken && open && campaignData?.clientEmail) {
      // if (date1 === date2 && new Date().getHours() >= 18) {
      //   return;
      // } else {
      cronofyApi3();
      // }
    }
  }, [cronofyToken, calenderId, selectedDates, timezones, open, campaignData]);

  useEffect(() => {
    // function generateTimeSlots(events) {
    //   const timeSlots = [];

    //   // Loop through each event
    //   for (let i = 0; i < events?.length; i++) {
    //     // const startTime = moment1(events[i].start).tz('UTC');
    //     // const endTime = moment1(events[i].end).tz('UTC');
    //     const startTime = moment1(events[i].start).tz(timezones);
    //     const endTime = moment1(events[i].end).tz(timezones);
    //     const gap = 45;

    //     // Generate time slots with 30-minute intervals
    //     // while (startTime.isBefore(endTime)) {
    //     while (startTime.isBefore(endTime) && endTime.diff(startTime, 'minutes') >= gap) {
    //       timeSlots.push(startTime.format('hh:mma'));
    //       startTime.add(gap, 'minutes');
    //     }
    //   }
    //   return timeSlots;
    // }
    function generateTimeSlots(events) {
      const timeSlots = [];

      // Loop through each event
      for (let i = 0; i < events?.length; i++) {
        // const startTime = moment1(events[i].start).tz('UTC');
        // const endTime = moment1(events[i].end).tz('UTC');
        const startTime = moment1(events[i].start).tz(timezones);
        timeSlots.push(startTime.format("hh:mma"));
      }
      return timeSlots;
    }

    const timeSlots = generateTimeSlots(cronofyData);

    setTimeSlotes(timeSlots);
  }, [cronofyData]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      fullWidth
      maxWidth="md"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              width: "100%",
              boxShadow: "0px 8px 8px -4px #10182808",
              bgcolor: "white",
              p: 2,
              borderRadius: "8px",
            }}
          >
            {" "}
            <InputLabel
              sx={{
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                color: "#55597A",
                mb: "6px",
              }}
            >
              Your current timezone
            </InputLabel>{" "}
            <Select
              // displayEmpty
              renderValue={(selected) => {
                if (selected === "") {
                  return "Placeholder";
                }
                return selected;
              }}
              value={timezones}
              onChange={(e) => setTimezones(e.target.value)}
              fullWidth
              size="small"
            >
              {/* <MenuItem disabled value="">
                      Placeholder
                    </MenuItem> */}
              {/* {option?.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))} */}
            </Select>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: "100%",
              boxShadow: "0px 8px 8px -4px #10182808",
              bgcolor: "white",
              p: 2,
              borderRadius: "8px",
            }}
          >
            {" "}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {/* <DatePicker defaultValue={today} disablePast /> */}
              <StaticDatePicker
                // shouldDisableDate={handleDisable}
                sx={{
                  "& .MuiPickersToolbar-root": {
                    color: "#050C46",
                    borderRadius: 2,
                    borderWidth: 1,
                    borderColor: "#2196f3",
                    border: "1px solid",
                    // backgroundColor: "#0d47a1",
                  },
                }}
                value={selectedDates}
                onChange={handleDateChange}
                disablePast
                componentsProps={{
                  actionBar: {
                    actions: [],
                  },
                }}
                slotProps={{
                  actionBar: {
                    actions: [
                      {
                        name: "Custom Action",
                        onClick: () => {
                          console.log("Custom action clicked");
                        },
                      },
                    ],
                  },
                }}
              />
            </LocalizationProvider>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={handleClose}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                Return
              </Button>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ minHeight: "100%" }}>
          {" "}
          <Box
            sx={{
              width: "100%",
              minHeight: "100%",
              boxShadow: "0px 8px 8px -4px #10182808",
              bgcolor: "white",
              p: 2,
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                minHeight: "100%",
                border: "1px solid #ECE9F5",
                bgcolor: "#F8F7FC",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                width: "100%",
                p: 2,
                borderRadius: "8px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  mb: 2,
                }}
              >
                Book a slot below for :{" "}
              </Typography>
              {timeSlotes ? (
                !timeSlotes.length ? (
                  <Typography
                    sx={{
                      fontFamily: "DM Sans",
                      fontSize: "13px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      mb: 2,
                    }}
                  >
                    No free slots available{" "}
                  </Typography>
                ) : (
                  timeSlotes.map((item) => {
                    return (
                      <>
                        <Button
                          sx={{
                            mb: 1,
                            borderRadius: "6px",
                            background: "#fff",
                            // background:
                            //   selectedDate === question ? "#00AA38" : "#fff",
                            border: "1px solid #ECE9F5",
                            color: "#050C46",
                            // color:
                            //   selectedDate === question ? "#fff" : "#050C46",
                            // p: 2,
                            boxShadow: 0,
                            "&:hover": {
                              backgroundColor: "#ECE9F5",
                              // backgroundColor:
                              //   selectedDate === question
                              //     ? "#00AA38"
                              //     : "#ECE9F5",
                              // color: "#fff",
                              boxShadow: "0px 1px 2px 0px #1018280F",
                            },
                            fontFamily: "DM Sans",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "20px",
                          }}
                          fullWidth
                          onClick={() =>
                            handleOpen2(
                              selectedDates.format("YYYY-MM-DD"),
                              item
                            )
                          }
                          variant="contained"
                        >
                          {item}
                        </Button>
                      </>
                    );
                  })
                )
              ) : (
                <CircularProgress color="inherit" sx={{ margin: "auto" }} />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

const TypingEffect = ({ text, videoRef }) => {
  const [displayText, setDisplayText] = useState("");
  useEffect(() => {
    const typingInterval = 70; // Adjust typing speed
    const words = text.split(" ");
    let currentIndex = 0;

    const typingAnimation = setInterval(() => {
      const currentWord = words[currentIndex];
      currentWord !== undefined &&
        setDisplayText((prevText) => prevText + " " + currentWord);
      currentIndex += 1;

      if (currentIndex > words.length) {
        clearInterval(typingAnimation);
      }
      if (videoRef.current) {
        videoRef.current.scrollTop = videoRef.current.scrollHeight;
      }
    }, typingInterval);

    return () => clearInterval(typingAnimation);
  }, [text, videoRef]);
  return <span dangerouslySetInnerHTML={{ __html: displayText }} />;
  // return <div ref={(ref) => setContainerRef(ref)} style={{ overflowY: 'auto', height: '200px' }}>{displayText}</div>;
};

const regexChars = [
  "^",
  "$",
  ".",
  "|",
  "?",
  "*",
  "+",
  "(",
  ")",
  "[",
  "]",
  "{",
  "}",
];

const ChatBlock = ({
  cronofyToken,
  calenderId,
  sub,
  userEmail,
  data,
  busy,
  handleEgSelect,
  extra,
  userDetails,
  scheduleMeet,
  setIsChatClick,
  handleOpenChat,
  isChatClick,
  isVideo,
  videoRef,
  setIsVideo,
  videoData,
  lgUp,
  midUp,
  mdUp,
  setScrollPosition,
  scrollPosition,
}) => {
  const {
    isUser,
    text: unprocessedText,
    quickResponses,
    eg,
    isMeetLink,
    id,
    showSchedule,
  } = data;

  // Cronofy
  const { campaignId } = useParams();

  const [isVideoPresent, setIsVideoPresent] = useState(false);

  const {
    accessToken,
    dateTimeOptions: OG_DateTimeOptions,
    // setDateTimeOptions,
    leadInfo,
    campaignData,
    handlePromptForMeet,
  } = extra;

  let searchPatterns = {
    organisationName: campaignData?.organisationName,
    companyName: campaignData?.companyName,
    fname: leadInfo?.fname,
    lname: leadInfo?.lname,
    senderName: campaignData?.senderName,
  };

  Object.keys(searchPatterns).forEach((word) => {
    regexChars.forEach((char) => {
      searchPatterns[word] = searchPatterns[word]?.replace(char, `\\${char}`);
    });
  });

  // process text!
  let text = unprocessedText;
  const replacements = [
    {
      pattern: `(?<=\\W)${searchPatterns?.organisationName}(?=\\W)`,
      by: `<b>${campaignData?.organisationName}</b>`,
    },
    {
      pattern: `(?<=\\W)${searchPatterns?.companyName}(?=\\W)`,
      by: `<b>${campaignData?.companyName}</b>`,
    },
    {
      pattern: `(?<=\\W)${searchPatterns?.fname}(?=\\W)`,
      by: `<b>${leadInfo?.fname}</b>`,
    },
    {
      pattern: `(?<=\\W)${searchPatterns?.lname}(?=\\W)`,
      by: `<b>${leadInfo?.lname}</b>`,
    },
    {
      pattern: `(?<=\\W)${searchPatterns?.senderName}(?=\\W)`,
      by: `<b>${campaignData?.senderName}</b>`,
    },
  ];
  replacements.forEach(({ pattern, by }) => {
    if (!pattern) return;
    const modTemp = text.replace(new RegExp(pattern, "ig"), by);
    if (modTemp) {
      text = modTemp;
    } else {
    }
  });

  const [isMeet, setIsMeet] = useState(isMeetLink);
  const [openmeeting, setOpenmeeting] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const meetingTitleStr = `${campaignData?.organisationName} <> ${leadInfo?.organization || leadInfo?.fname + " " + leadInfo?.lname
    }`;
  const [meeting_title, setmeeting_title] = useState(
    meetingTitleStr
    // `${campaignData?.organisationName} <> ${leadInfo?.organization}, ${leadInfo?.fname} ${leadInfo?.lname} - (${campaignData?._id})`
  );
  const [meeting_date, setmeeting_date] = useState("");
  const [meeting_time, setmeeting_time] = useState("");
  const [companyName, setCompanyName] = useState("");
  // const [meeting_with, setmeeting_with] = useState("");
  const [meeting_purpose, setmeeting_purpose] = useState(
    "I will attend this session as we are actively exploring similar solutions."
  );
  const [meeting_phone, setmeeting_phone] = useState("");
  const [meeting_guest, setmeeting_guest] = useState("");
  const [userDocumentId, setUserDocumentId] = useState("");
  const [userMeetingScheduleData, setuserMeetingScheduleData] = useState([]);
  const [MeetingSlot, setMeetingSlot] = useState(null);
  const [userMeetingSchedule, setuserMeetingSchedule] = useState({});
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [slotDuration, setSlotDuration] = useState(30);
  const [timezones, setTimezones] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const [done, setDone] = useState(false);
  const closeDialog = () => {
    setDone(false);
  };

  const [examples, setExamples] = useState([]);
  // const [examplesTime, setExamplesTime] = useState([]);
  useEffect(() => {
    const chatContainer = document.querySelector("#content-div");
    if (campaignData?.link?.url == "") {
      chatContainer.scrollTop = chatContainer?.scrollHeight;
    }
  }, [examples]);

  // useEffect(() => {
  //   getNextThreeTimes(timezones, setExamplesTime, setExamples);
  // }, [timezones]);

  useEffect(() => {
    // Call cronofy API here to get list of available dates and set Date sate accordingly.
    // const date = [];
    // Calculate the list of dates starting from tomorrow and spanning the next 7 days
    // const startDate = moment().add(1, "days"); // Start from tomorrow
    // for (let i = 0; i < 3; i++) {
    //   date.push(startDate.format("YYYY-MM-DD"));
    //   startDate.add(1, "days");
    // }
    // setDates(date);

    const fetchAvailability = async () => {
      const email = campaignData?.clientEmail; //localStorage.getItem("userEmail");
      const userSlotPreset = await fetch(
        `${API_SERVICE_BACKEND_3}/get-slot-preset/${email}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const duration = await userSlotPreset.json();
      const slotDuration = duration?.data?.slotsPreset;
      setSlotDuration(slotDuration);
      const data = {
        participants: [
          {
            members: [
              {
                sub: sub,
                calendar_ids: [calenderId],
                managed_availability: true,
              },
            ],
            required: 1,
          },
        ],
        // required_duration: { minutes: 288 },
        required_duration: { minutes: slotDuration ? slotDuration : 3600 },
        response_format: "slots",
        query_periods: [
          {
            start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
            end: moment()
              .add(6, "days")
              .endOf("day")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss[Z]"),
          },
        ],
      };

      const responseDataLog = await fetch(
        `${API_SERVICE_BACKEND_3}/availability`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            cronofyToken: cronofyToken,
          },
          body: JSON.stringify(data),
        }
      ).then((res) => res.json());

      // const availableSlots = responseDataLog?.data?.available_slots.filter(
      //   (slot) => {
      //     const slotStartMoment = moment
      //       .tz(slot.start, "UTC")
      //       .tz("America/New_York"); // converting UTC into EST timezone
      //     const slotStartTime = slotStartMoment.format("HH:mm");
      //     const slotStartHour = parseInt(slotStartTime.split(":")[0]);
      //     return slotStartHour
      //   }
      // );

      const exampleSlots = responseDataLog?.data?.available_slots
        .map((slot) => {
          return moment(slot.start).format("ddd, MMM Do h:mm A").toUpperCase();
        })
        .slice(0, 3);

      setExamples(exampleSlots);

      const groupedSlots = responseDataLog?.data?.available_slots.reduce(
        (result, slot) => {
          const startDate = moment.utc(slot.start).format("DD-MM-YYYY");

          if (!result[startDate]) {
            result[startDate] = [];
          }

          result[startDate].push(slot);

          return result;
        },
        {}
      );

      const groupedSlotsArray = Object.entries(groupedSlots)
        .map(([date, slots]) => {
          const dateObject = moment.utc(date, "DD-MM-YYYY");

          return {
            date: dateObject.format("YYYY-MM-DD"),
            slots: slots.map((slot) => ({
              start: moment.utc(slot.start).format("HH:mm:ss"),
              end: moment.utc(slot.end).format("HH:mm:ss"),
            })),
          };
        })
        .slice(0, 3);

      setDates(groupedSlotsArray);
      setLoading(false);
    };
    // const isAfter6_30 = (starttime)=>{
    //   const startTime = new Date(starttime);
    //   const sixThirtyPm = new Date();
    //   sixThirtyPm.setHours(18, 30, 0, 0); // Set time to 6:30 pm

    //   return startTime > sixThirtyPm;
    // }
    if (calenderId && campaignData?.clientEmail) {
      fetchAvailability();
    }
  }, [calenderId, campaignData]);

  useEffect(() => {
    if (MeetingSlot && companyName) {
      const meetingDateTime = moment(MeetingSlot, "ddd, MMM Do hh:mm A");

      // Extract meeting_date and meeting_time
      const meeting_date = meetingDateTime.format("YYYY-MM-DD");
      const meeting_time = meetingDateTime.format("HH:mm");

      // Create a single object with meeting_date and meeting_time
      const extractedData = {
        meeting_date,
        meeting_time,
      };
      setuserMeetingSchedule(extractedData);
    }
  }, [MeetingSlot]);

  const handleClosemeeting = () => {
    setOpenmeeting(false);

    setmeeting_date("");
    setmeeting_title(meetingTitleStr);
    // setmeeting_with("");
    // setmeeting_link("");
    setmeeting_time("");
    setmeeting_purpose(
      "I will attend this session as we are actively exploring similar solutions."
    );
    setmeeting_phone("");
    setmeeting_guest("");
    setTimezones(Intl.DateTimeFormat().resolvedOptions().timeZone);
    // setMeetingId("");
    // setMeetingEdit(false);
  };

  const getRegisteredAllUsers = async () => {
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/getallregistereduser/admin`
      );
      const data = await response.json();
      return data.data;
    } catch (err) { }
  };
  // const [meetingBookingLoader, setMeetingBookingLoader] = useState(false);
  const addMeetingViaCronofy = async () => {
    setDone(true);
    setOpenmeeting(true);
    // setMeetingBookingLoader(true);
    setBtnLoading(true);
    try {
      const data = await getRegisteredAllUsers();
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const dataOfUser = data.find(
        (doc) => doc.email === campaignData?.clientEmail
      );
      const startTime = moment.tz(
        `${meeting_date} ${meeting_time}`,
        "YYYY-MM-DD hh:mmA",
        timezones
      );
      startTime.utc();
      startTime.tz(userTimeZone);

      // Calculate endTime by adding 30 minutes to startTime
      const endTime = startTime.clone().add(slotDuration, "minutes");

      // Format startTime and endTime in the desired formats
      // const formattedStartTime = startTime.format("YYYY-MM-DDTHH:mm:ss[Z]");
      // const formattedEndTime = endTime.format("YYYY-MM-DDTHH:mm:ss[Z]");
      const formattedStartTime = startTime.format();
      const formattedEndTime = endTime.format();
      // const startTime = moment(`${meeting_date} ${meeting_time}`, 'YYYY-MM-DD hh:mmA').format('YYYY-MM-DDTHH:mm:ss[Z]');
      // const endTime = startTime.add(30, 'minutes').format('YYYY-MM-DDTHH:mm:ss[Z]');
      var dataSend = {
        client_email: campaignData?.clientEmail,
        client_orgId: dataOfUser?.organization_id,
        email: campaignData?.clientEmail,
        lead_email: leadInfo?.email,
        summary: meeting_title,
        start: formattedStartTime,
        end: formattedEndTime,
        campaign_id: campaignData?._id,
        // meeting_date,
        // meeting_time,
        // meeting_with,
        description: "Discuss our offerings and how we can help you.",
        location: {
          description: "Virtual Meeting",
        },
        attendees: {
          invite: [
            {
              email: leadInfo?.email,
              display_name: leadInfo?.clientEmail,
            },
            ...meeting_guest
              .split(",")
              ?.filter((email) => Boolean(email.length))
              .map((email) => ({
                email: email,
              })),
          ],
        },
        // meeting_link: "Test Link",
      };
    } catch (err) {
      setBtnLoading(false);
      hotToast.error("something went wrong!!", { position: "top-right" });
      // setMeetingBookingLoader(false);
    }

    try {
      const url = `${API_SERVICE_BACKEND_CRONOFY}/addusermeetingsbycronofy?`;
      const res = await axios.post(url, dataSend);
      if (res) {
        const data = res.data.data;
        await addMeetings({ data });
      }
    } catch (error) {
      setBtnLoading(false);
      hotToast.error("something went wrong!!", { position: "top-right" });
      // setMeetingBookingLoader(false);
    }
    handleClosemeeting();
    setBtnLoading(false);
    hotToast.success("Meeting booked successfully!!", {
      position: "top-right",
    });
    // setMeetingBookingLoader(false);
  };

  const handleOpen3 = (question) => {
    if (examples.includes(question)) {
      const formattedDate = moment(question, "ddd, MMM Do h:mm A").format(
        "YYYY-MM-DD"
      );
      const formattedTime = moment(question, "ddd, MMM Do h:mm A").format(
        "h:mm A"
      );
      handleOpen2(formattedDate, formattedTime);
    } else {
      handleEgSelect(question);
    }
  };
  const getUserInfo = async (email) => {
    try {
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/getuserdetail/${email}`
      );
      // Assuming the response structure matches the example you provided
      const userData = res.data; // Access the response data
      const companyName1 = res.data.data.companyName;
      const userId = res.data.data._id;
      setUserDocumentId(userId);
      setCompanyName(companyName1);

      // Check if the status is truthy (status exists and is not falsey)
      if (userData.status) {
        return userData.data; // Return the data property of the response
      } else {
        return null; // Return null or handle the error case as needed
      }
    } catch (error) {
      throw error; // You can choose to re-throw the error or handle it here
    }
  };
  const [meetCount, setMeetCount] = useState(0);
  const [sevenDaysMeet, setSevenDayMeet] = useState(0);
  const [thirtyDaysMeet, setThirtyDayMeet] = useState(0);
  const getMeetUnreadCount = async () => {
    try {
      const userData = await getUserInfo(campaignData?.clientEmail);
      const campaigns = await jobsApi.getUserCampaigns(
        campaignData?.clientEmail
      );
      const campaignIds = campaigns.map(({ _id }) => _id);
      let url = `${API_SERVICE_BACKEND_2}/dashboard-chart`;
      url += `/?campaignIds=${campaignIds.join(",")}`;
      const response = await fetch(url);
      const data = await response.json();
      var meetings = data?.data?.meetings;
      const isWithinLastDays = (date, days) => {
        const today = new Date();
        const dateBefore = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - days
        );
        return date > dateBefore;
      };

      // Calculate meetings in the last 7 and 30 days
      const calculateMeetings = (meetings, days) => {
        return meetings
          .filter((meeting) => {
            const meetingDate = new Date(meeting._id);
            return isWithinLastDays(meetingDate, days);
          })
          .reduce((total, meeting) => total + meeting.count, 0);
      };
      const totalMeetingsLast7Days = calculateMeetings(meetings, 7);
      const totalMeetingsLast30Days = calculateMeetings(meetings, 30);
      setSevenDayMeet(totalMeetingsLast7Days);
      setThirtyDayMeet(totalMeetingsLast30Days);
      var tempUnseenCount = 0;

      meetings.map((item) => {
        item.seen === false && tempUnseenCount++;
      });
      // console.log(tempUnseenCount);
      setMeetCount(tempUnseenCount);
      return { totalMeetingsLast7Days, totalMeetingsLast30Days };
    } catch (err) {
      console.log(err);
    }
  };
  const addMeetings = async (options) => {
    const { data: resData } = options;
    try {
      const data = await getRegisteredAllUsers();
      const dataOfUser = data.find(
        (doc) => doc.email === campaignData?.clientEmail
      );
      // var dataSend = {
      //   client_email: "dev@test.com",
      //   client_orgId: "1689755239760_b5bc23e5-16d1-45f6-b8ef-34a692671dc4",
      //   meeting_title:"MY TEST TITLE",
      //   meeting_date:"2023-10-08",
      //   meeting_time:"03:30PM",
      //   meeting_with:"Dev Comp 12",
      //   // meeting_link: "Test Link",
      // };
      var dataSend = {
        calendarId: calenderId,
        client_email: campaignData?.clientEmail,
        client_orgId: dataOfUser?.organization_id,
        meeting_title,
        meeting_date,
        meeting_time,
        // meeting_with,
        meeting_purpose,
        meeting_phone,
        meeting_guest,
        timezones,
        campaign_id: campaignData?._id,
        event_id: resData?.event_id,
        meeting_link: resData?.meeting_url,
      };

      // const resDataLog = await fetch('http://localhost:8080/api/v1/main/addusermeetings', {
      const resDataLog = await fetch(
        `${API_SERVICE_BACKEND_3}/addusermeetings`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataSend),
        }
      );
      if (resDataLog.status === 200) {
        const runMeetingCount = async () => {
          const { totalMeetingsLast7Days, totalMeetingsLast30Days } =
            await getMeetUnreadCount();
          const userData = await getUserInfo(campaignData?.clientEmail);
          const totalMeetings = await getUserMeetings(
            campaignData?.clientEmail
          );
          const meeting_detail = {
            email: campaignData?.clientEmail,
            ai_agent_name: campaignData?.title,
            prospect_fullname: leadInfo?.firstName + " " + leadInfo?.lastName,
            prospect_email: leadInfo?.email,
            prospect_company: leadInfo?.organization,
            prospect_title: leadInfo?.description,
            prospect_linkedin_url: leadInfo?.linkedin_url,
            meeting_date: dataSend?.meeting_date,
            meeting_time: dataSend?.meeting_time,
            meeting_count: totalMeetings,
            meetings_booked_7days: totalMeetingsLast7Days,
            meetings_booked_30days: totalMeetingsLast30Days,
          };
          fetch(`${API_SERVICE_BACKEND}/intercom-event-meeting`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(meeting_detail),
          })
            .then((response) => response.json())
            .then((data) => console.log("Success:", data))
            .catch((error) => console.error("Error:", error));
        };
        runMeetingCount();
        setDone(false);
        // alert("Meeting Booked");
        // getAllMeetingsUser(userData?.email, userData?.organization_id);
        handleClosemeeting();
        // setMeetingBookingLoader(false);
        // getUserDetails(userCampaginDetails.client_email, userCampaginDetails.clinet_orgId);
      }
    } catch (error) { }
  };

  const handleOpen = (question, meetingTime) => {
    if (busy) return;
    handleEgSelect(question);
  };

  const handleOpen2 = (question, meetingTime) => {
    setMeetingSlot(question);
    setMeetingSlot(meetingTime);
    setOpenmeeting(true);

    // Extract meeting_date and meeting_time
    const meeting_date = moment(question).format("YYYY-MM-DD").toUpperCase();
    setmeeting_date(meeting_date);
    const meeting_time = meetingTime;
    setmeeting_time(meeting_time);
    // setmeeting_with(companyName);
  };

  const [dateTimeOptions, setDateTimeOptions] = useState({
    ...OG_DateTimeOptions,
  });

  const [dateTimeToken, setDateTimeToken] = useState(null);
  const [cronofyElements, setCronofyElements] = useState([]);

  useEffect(() => {
    if (!accessToken || !dateTimeOptions) return;
    if (dateTimeOptions.element_token) {
      return;
    }
    if (isMeet) {
      const permissions = ["availability"];
      (async () => {
        const dateTimeToken = await getElementToken(accessToken, permissions);
        setDateTimeToken(dateTimeToken);
      })();
    }
  }, [dateTimeOptions, accessToken, isMeet]);

  useEffect(() => {
    if (!dateTimeToken) return;
    setDateTimeOptions((prev) => {
      return {
        ...prev,
        element_token: dateTimeToken,
        callback: (res) => {
          if (res.notification.type !== "slot_selected") return;
          handleSlotConfirmation(res.notification, {
            setCronofyElements,
            handlePromptForMeet,
            accessTokenStr: accessToken.access_token,
            leadInfo,
            campaignData,
          });
        },
      };
    });
  }, [dateTimeToken]);

  useEffect(() => {
    if (!dateTimeOptions?.element_token) return;
    setCronofyElements((prev) => [
      ...prev,
      <DateTimePickerWrapper key={Date.now()} options={dateTimeOptions} />,
    ]);
  }, [dateTimeOptions]);

  const addEventToUserCalander = () => { };

  // const userEmail = localStorage.getItem("userEmail");

  useEffect(() => {
    console.log("effect calledd ---- 121212 >", userMeetingScheduleData);
    const getmeetingschedule = async () => {
      try {
        // leadInfo
        const responseDataLog = await fetch(
          // `${API_SERVICE_BACKEND_2}/getmeetingschedule/${userDocumentId}`,
          `${API_SERVICE_BACKEND_3}/getmeetingschedule/${userDocumentId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        ).then((res) => res.json());
        setuserMeetingScheduleData(responseDataLog.data);
      } catch (err) { }
    };
    if (userDocumentId && !userMeetingScheduleData?.length) {
      getmeetingschedule();
    }
  }, [userDocumentId]);

  // const generateTimeOptions = () => {
  //   const options = [];
  //   const startTime = 9; // Start time in hours
  //   const endTime = 17; // End time in hours

  //   for (let hour = startTime; hour < endTime; hour++) {
  //     for (let minute = 0; minute < 60; minute += 30) {
  //       const formattedHour = String(hour).padStart(2, '0');
  //       const formattedMinute = String(minute).padStart(2, '0');
  //       const timeOption = `${formattedHour}:${formattedMinute}`;
  //       options.push({ value: timeOption, label: timeOption });
  //     }
  //   }

  //   return options;
  // };

  // const timeOptions = generateTimeOptions();

  // 9:00 am to 5:00 pm ka time array banayein
  const timeOptions = [];
  let time = new Date();
  time.setHours(9, 0, 0); // 9:00 am se shuru karein

  while (time.getHours() < 17) {
    // 5:00 pm tak
    const formattedTime = time.toLocaleString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    timeOptions.push(formattedTime);
    time.setMinutes(time.getMinutes() + 30); // 30-minute intervals
  }

  const UserMeetingSchedule = ({ id, isUser, openMeeting }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const meetingTime = [
      "09:00AM",
      "11:00AM",
      "01:00PM",
      "03:00PM",
      "04:00PM",
      "09:30AM",
      "11:30AM",
      "01:30PM",
      "02:30PM",
      "04:30PM",
      "09:45AM",
      "11:45AM",
      "01:45PM",
      "02:45PM",
      "03:45PM",
      "09:15AM",
      "11:30AM",
      "01:30PM",
      "03:15PM",
      "04:15PM",
      "10:00AM",
      "11:15AM",
      "01:15PM",
      "02:45PM",
      "03:30PM",
    ];

    useEffect(() => {
      const chatContainer = lgUp
        ? document.querySelector("#chat-container")
        : document.querySelector("#content-div");
      if (campaignData?.link?.url == "") {
        chatContainer.scrollTop = chatContainer?.scrollHeight;
      }
    }, [selectedDate]);

    // const links = document.querySelectorAll(".full_calander_slot");

    return (
      <>
        {!isVideo && (
          <Typography
            id={id ? id : ""}
            sx={{
              color: isUser ? "#FFFFFF" : "#050C46",
              fontFamily: "DM Sans",
              fontSize: { xs: "14px", md: "16px" },
              lineHeight: "24px",
              fontWeight: 400,
              marginTop: "15px",
            }}
          // dangerouslySetInnerHTML={{
          //   __html:
          // text === "Schedule a meet as per the availability."
          //   ? "Schedule a meet as per the availability."
          //   : showSchedule
          //   ? askForMeet({ client: { name: campaignData.senderName } })
          //   : limitedSlotText(
          //       Intl.DateTimeFormat().resolvedOptions().timeZone
          //     ),
          // }}
          >
            {
              text === "Schedule a meet as per the availability." ? (
                "Schedule a meet as per the availability."
              ) : showSchedule ? (
                askForMeet({ client: { name: campaignData.senderName } })
              ) : (
                <LimitedSlotText
                  timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                  handleClickOpen={openMeeting}
                />
              )
              // limitedSlotText(
              //     Intl.DateTimeFormat().resolvedOptions().timeZone
              //   )
            }
          </Typography>
        )}

        <Box
          id={id ? id : ""}
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            border: "1px solid #ECE9F5",
            backgroundColor: "#F8F7FC",
            borderRadius: "8px",
            p: 2,
            marginTop: "12px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Book a slot below for :
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "20px",
              }}
            >
              {moment().format("MMMM YYYY")}
            </Typography>{" "}
          </Box>
          <Grid item xs={12}>
            {" "}
            <InputLabel
              sx={{
                ml: 3,
                mt: 3,
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                color: "#55597A",
                mb: "6px",
              }}
            >
              Your current timezone
            </InputLabel>{" "}
            {/*<Select*/}
            {/*  // displayEmpty*/}
            {/*  renderValue={(selected) => {*/}
            {/*    if (selected === "") {*/}
            {/*      return "Placeholder";*/}
            {/*    }*/}
            {/*    return selected;*/}
            {/*  }}*/}
            {/*  value={timezones}*/}
            {/*  onChange={(e) => setTimezones(e.target.value)}*/}
            {/*  sx={{ width: { xs: "80%", md: "95%" }, ml: 3 }}*/}
            {/*  size="small"*/}
            {/*>*/}
            {/*  /!* <MenuItem disabled value="">*/}
            {/*      Placeholder*/}
            {/*    </MenuItem> *!/*/}
            {/*  {option?.map((item, index) => (*/}
            {/*    <MenuItem key={index} value={item}>*/}
            {/*      {item}*/}
            {/*    </MenuItem>*/}
            {/*  ))}*/}
            {/*</Select>*/}
            <Autocomplete
              options={option}
              sx={{ width: { xs: "80%", md: "95%" }, ml: 3 }}
              id="auto-highlight"
              autoHighlight
              renderInput={(params) => (
                <TextField {...params} variant={"outlined"} />
              )}
              onChange={(event, value) => {
                setTimezones(
                  value || Intl.DateTimeFormat().resolvedOptions().timeZone
                );
              }}
              value={timezones}
            />
          </Grid>
          {loading ? (
            <Box
              sx={{
                margin: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="inherit" sx={{ margin: "auto" }} />
            </Box>
          ) : (
            <Grid
              container
              className="jenis"
              spacing={1}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                mt: 1,
                width: "100%",
              }}
            >
              {dates?.map((question, index) => {
                return (
                  <>
                    <Grid item key={index}>
                      <Button
                        // fullWidth
                        variant="contained"
                        sx={{
                          borderRadius: "8px",
                          p: { xs: 1, md: 2 },
                          background:
                            selectedDate === question.date ? "#00AA38" : "#fff",
                          border: "1px solid #ECE9F5",
                          color:
                            selectedDate === question.date ? "#fff" : "#050C46",
                          fontSize: { xs: "10px", md: "16px" },
                          // p: 2,
                          boxShadow: 0,
                          "&:hover": {
                            backgroundColor:
                              selectedDate === question.date
                                ? "#00AA38"
                                : "#ECE9F5",
                            // color: "#fff",
                            boxShadow: "0px 1px 2px 0px #1018280F",
                          },
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                        onClick={() => setSelectedDate(question.date)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            // pb: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "DM Sans",
                              fontSize: "14px",
                              fontWeight: 500,
                              lineHeight: "20px",
                              opacity: "75%",
                            }}
                          >
                            {moment(question.date).format("ddd")}
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "DM Sans",
                              fontSize: "14px",
                              fontWeight: 500,
                              lineHeight: "20px",
                            }}
                          >
                            {moment(question.date).format("D")}
                          </Typography>
                        </Box>
                      </Button>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          )}

          <Grid container spacing={1} sx={{ mt: 1 }}>
            {dates?.map((question, index) => {
              const selectedTimeSlots = question.slots.map((slot) => {
                // return slot.start
                const time = moment.utc(slot.start, "HH:mm:ss").tz(timezones);
                return time.format("h:mm A");
              });
              return (
                <>
                  {selectedDate === question.date &&
                    [0, 1, 2, 3, 4]?.map((index) => (
                      <Grid item xs={12} sm={2.4} key={index}>
                        {selectedTimeSlots[index] && (
                          <Button
                            fullWidth
                            variant="contained"
                            sx={{
                              borderRadius: "6px",
                              background: "#fff",
                              color: "#050C46",
                              py: 1,
                              px: 2,
                              boxShadow: 0,
                              "&:hover": {
                                background: "#ECE9F5",
                                // color: "#fff",
                              },
                              fontFamily: "DM Sans",
                              fontSize: "12px",
                              fontWeight: 500,
                              lineHeight: "20px",

                              textAlign: "center",
                              border: "2px solid #ECE9F5",
                            }}
                            onClick={() =>
                              handleOpen2(
                                question.date,
                                selectedTimeSlots[index]
                              )
                            }
                          >
                            {selectedTimeSlots[index]}
                          </Button>
                        )}
                      </Grid>
                    ))}
                </>
              );
            })}
          </Grid>
          <Button
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              backgroundColor: "#5761FE",
              color: "#fff",
              py: 1,
              px: 2,
              "&:hover": {
                backgroundColor: "#6269d9",
              },
              boxShadow: "0px 1px 2px 0px #1018280D",
              mt: 2,
            }}
            fullWidth
            onClick={handleClickOpen}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: 1,
              }}
            >
              <CalendarIcon />
            </Box>
            Show more slots
          </Button>
        </Box>
      </>
    );
  };

  useEffect(() => {
    const links = document.querySelectorAll(".full_calander_slot");
    links.forEach((link) =>
      link.addEventListener("click", () => {
        handleClickOpen();
      })
    );
  }, []);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDisable = (date) => {
    if (
      new Date().getDate() === new Date(date).getDate() &&
      new Date().getHours() >= 18
    ) {
      return (
        new Date(date).getDay() === 0 ||
        new Date(date).getDay() === 6 ||
        new Date().getDate() === new Date(date).getDate()
      );
    } else {
      return new Date(date).getDay() === 0 || new Date(date).getDay() === 6;
    }
  };

  const [selectedDates, setSelectedDates] = useState(dayjs()); // Initialize with today's date
  const handleDateChange = (date) => {
    setSelectedDates(date);
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    // Allow users to deselect by clicking the selected option again
    setmeeting_purpose((prevValue) => (prevValue === newValue ? "" : newValue));
  };

  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setBrowserWidth(window.innerWidth);
    };
    setBrowserWidth(window.innerWidth);
    if (videoRef?.current) {
      videoRef.current.scrollTop = videoRef.current.scrollHeight;
      setScrollPosition(videoRef.current.scrollHeight);
    }
    window.addEventListener("resize", handleResize);

    // window.removeEventListener("resize", handleResize);
    if (browserWidth <= 576) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [videoRef]);

  return (
    <>
      {/* <Button onClick={addMeetingViaCronofy} autoFocus /> */}
      <TimePopup
        // handleDisable={handleDisable}
        calenderId={calenderId}
        sub={sub}
        cronofyToken={cronofyToken}
        // timeSlotes={timeSlotes}
        selectedDates={selectedDates}
        option={option}
        handleOpen2={handleOpen2}
        setTimezones={setTimezones}
        timezones={timezones}
        handleDateChange={handleDateChange}
        open={open}
        handleClose={handleClose}
        campaignData={campaignData}
      />
      {/* <Dialog
        open={done}
        onClose={closeDialog}
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ backgroundColor: "rgba(5, 12, 70, 0.2)" }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "24px",
            color: "#050C46",
            textAlign: "center",
          }}
        >
          Meeting Booked
        </DialogTitle>
      </Dialog> */}
      <Dialog
        open={openmeeting}
        fullWidth
        maxWidth="md"
        onClose={() => {
          if (!btnLoading) {
            handleClosemeeting();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ backgroundColor: "rgba(5, 12, 70, 0.2)" }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "24px",
            color: "#050C46",
            textAlign: "center",
          }}
        >
          Please Confirm Your Slot
        </DialogTitle>

        <Grid item xs={12}>
          {" "}
          <InputLabel
            sx={{
              ml: 3,
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              color: "#55597A",
              mb: "6px",
            }}
          >
            Your current timezone
          </InputLabel>{" "}
          <Typography
            sx={{
              width: { xs: "80%", md: "95%" },
              ml: "auto",
              mr: "auto",
              textAlign: "left",
              border: "1px solid #5761FE",
              borderRadius: "6px",
              paddingLeft: "16px",
              paddingRight: "16px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {timezones}
          </Typography>
          {/* <Select
            // displayEmpty
            renderValue={(selected) => {
              if (selected === "") {
                return "Placeholder";
              }
              return selected;
            }}
            value={timezones}
            // onChange={(e) => setTimezones(e.target.value)}
            sx={{ width: { xs: "80%", md: "95%" }, ml: 3 }}
            size="small" 
                      >
                      {/* <MenuItem disabled value="">
                  Placeholder
                </MenuItem> 
            {/* {option?.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))} */}
          {/* </Select> */}
        </Grid>

        <Divider />
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              border: "1px solid #5761FE",
              borderRadius: "6px",
              width: { xs: "100%", md: "fit-content" },
              px: 2,
              py: "10px",
            }}
          >
            {" "}
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                color: "#050C46",
              }}
            >
              {" "}
              {moment(meeting_date).format("ddd, MMM Do")},{" "}
              {meeting_time.toUpperCase()}
            </Typography>
          </Box>
          <Button
            sx={{
              border: "1px solid #ECE9F5",
              borderRadius: "6px",
              backgroundColor: "#fff",
              color: "#050C46",
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              ml: { xs: 0, md: 1 },
              mt: { xs: 1, md: 0 },
              width: { xs: "100%", md: "fit-content" },
            }}
            onClick={handleClosemeeting}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: 1,
              }}
            >
              <RefreshIcon />
            </Box>
            Change slot
          </Button>
        </DialogTitle>
        {/* <DialogTitle id="alert-dialog-title">
          {" "}
          <Typography
            sx={{
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              color: "#050C46",
            }}
          >
            {" "}
            How will this meeting help you? (It's ok if you don't know yet)
          </Typography>
        </DialogTitle> */}
        <DialogContent>
          {/* <RadioGroup value={meeting_purpose} onChange={handleChange}>
            <FormControlLabel
              value="I will attend this session as we are actively exploring similar solutions."
              control={
                <Radio
                  checkedIcon={<CheckBoxOnIcon />}
                  icon={<CheckBoxOffIcon />}
                />
              }
              sx={{
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "24px",
                color: "#55597A",
              }}
              label="I will attend this session as we are actively exploring similar solutions."
            />
            <FormControlLabel
              value="I will attend this session as I’m curious, and look forward to learning more."
              control={
                <Radio
                  checkedIcon={<CheckBoxOnIcon />}
                  icon={<CheckBoxOffIcon />}
                />
              }
              sx={{
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "24px",
                color: "#55597A",
                mt: { xs: 1, md: 0 },
              }}
              label="I will attend this session as I’m curious, and look forward to learning more."
            />
            <FormControlLabel
              value="I’m NOT interested, I was just clicking around."
              control={
                <Radio
                  checkedIcon={<CheckBoxOnIcon />}
                  icon={<CheckBoxOffIcon />}
                />
              }
              sx={{
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "24px",
                color: "#55597A",
                mt: { xs: 1, md: 0 },
              }}
              label="I’m NOT interested, I was just clicking around."
            />
          </RadioGroup> */}
          <Grid container columnSpacing={2} rowSpacing={2} sx={{ mt: 1 }}>
            {/* <Grid item xs={12}>
              <InputLabel
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#55597A",
                  mb: "6px",
                }}
              >
                Meeting Title
              </InputLabel>
              <TextField
                value={meeting_title}
                fullWidth
                onChange={(e) => setmeeting_title(e.target.value)}
                variant="outlined"
                placeholder="Please enter the title for the meeting."
                type="text"
                size="small"
              />
            </Grid> */}

            <Grid item xs={12} md={6}>
              {" "}
              <InputLabel
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#55597A",
                  mb: "6px",
                }}
              >
                What's your phone number?
                <b>(optional)</b>
              </InputLabel>
              <TextField
                fullWidth
                value={meeting_phone}
                onChange={(e) => setmeeting_phone(e.target.value)}
                variant="outlined"
                placeholder="For sending meeting details & reminders"
                type="number"
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {" "}
              <InputLabel
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#55597A",
                  mb: "6px",
                }}
              >
                Do you have any guests for the meeting?
                <b>(optional)</b>
              </InputLabel>
              <TextField
                fullWidth
                value={meeting_guest}
                onChange={(e) => setmeeting_guest(e.target.value)}
                variant="outlined"
                placeholder="Emails"
                type="text"
                size="small"
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {/* <Button onClick={handleClosemeeting}>Close</Button> */}

          <LoadingButton
            disabled={btnLoading}
            color="inherit"
            variant="contained"
            size="small"
            fullWidth
            loading={btnLoading}
            // startIcon={<img src={Download} />}
            onClick={addMeetingViaCronofy}
            sx={{
              borderRadius: "8px",
              backgroundColor: "#5761FE",
              color: "#fff",
              py: 1,
              px: 2,
              "&:hover": {
                backgroundColor: "#6269d9",
              },
              "&.Mui-disabled": {
                backgroundColor: "#6269d9",
              },
              "&.MuiLoadingButton-loading": {
                "& .MuiCircularProgress-svg": {
                  color: "white", // Change the color of the loading bar here
                },
              },
              textAlign: "left",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "20px",
              boxShadow: "0px 1px 2px 0px #1018280D",
            }}
          >
            Confirm
          </LoadingButton>
          {/* <Button
            disabled={meetingBookingLoader}
            onClick={addMeetingViaCronofy}
            fullWidth
            sx={{
              borderRadius: "8px",
              backgroundColor: "#5761FE",
              color: "#fff",
              py: 1,
              px: 2,
              "&:hover": {
                backgroundColor: "#6269d9",
              },
              textAlign: "left",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "20px",
              boxShadow: "0px 1px 2px 0px #1018280D",
            }}
          >
            Confirm
          </Button> */}
        </DialogActions>
      </Dialog>
      <Grid
        item
        xs={8}
        md={10}
        lg={12}
        sx={{
          display: "flex",
          padding: "0px 0px 0px 0px",
        }}
      >
        {!data.isVideoPresent && (
          <Grid sx={{ display: "flex", padding: "12px 0px" }}>
            {isUser ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <UserAvatar />
                </Box>
              </>
            ) : (
              <>
                {/* <img src={avatar} /> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BotAvatar />
                </Box>
              </>
            )}
            <ListItemText
              sx={{
                marginLeft: "5px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#050C46",
                }}
              >
                {isUser
                  ? "You"
                  : `${campaignData?.companyName || ""} - AI Agent`}
              </Typography>
            </ListItemText>
          </Grid>
        )}

        {/* <ListItemText sx={{ textAlign: "right" }}>
          Thursday 16:56PM
          <Typography
            sx={{
              fontFamily: "DM Sans",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "18px",
              color: "#55597A",
            }}
          >
           {moment().format("dddd hh:mm A")}
          </Typography>
        </ListItemText> */}
      </Grid>

      <Box
        sx={{
          p: lgUp ? "5px 16px 6px 16px" : "10px 16px 11px 16px",
          zIndex: 999,
          borderRadius: isUser ? "16px 16px 0px 16px" : "16px 16px 16px 16px",
          backgroundColor: isUser ? "#050C46" : "#FFFFFF",
          my: isVideo ? 0 : 0,
          boxShadow: "0px 1px 2px 0px #1018280D",
          border: "1px solid #ECE9F5",
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          flexWrap={isUser ? "wrap" : "nowrap"}
        >
          {isUser ? <></> : ""}
          {/* <SmartToyIcon /> */}

          <Stack spacing={1} justifyContent="center" sx={{ width: "100%" }}>
            {data.isVideoPresent ? (
              <>
                <div
                  ref={videoRef}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: lgUp ? "" : "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: lgUp ? "22px" : "15px",
                      color: "#050C46",
                      fontWeight: "700",
                      margin: "3px 0px",
                    }}
                  >
                    Hi {leadInfo?.fname}, I created this special video for{" "}
                    <span
                      style={{
                        color: "#5862FF",
                        fontSize: lgUp ? "22px" : "15px",
                        fontWeight: "700",
                      }}
                    >
                      {leadInfo?.organization || "Prospect Company"}
                    </span>
                  </Typography>
                  {videoData?.data.data.length !== 0 && (
                    <Typography
                      sx={{
                        fontSize: lgUp ? "16px" : "12px",
                        color: "#050C46",
                        fontWeight: "400",
                        margin: "3px 0px",
                      }}
                    >
                      I’ve made an exclusive video about{" "}
                      {leadInfo?.organization || "Prospect Company"} regarding
                      our potential together.
                    </Typography>
                  )}
                  {/* {videoData?.data.status */}
                  {videoData?.data.data[0]?.video_urls[0].status ===
                    "success" && (
                      <iframe
                        className="videoFrame"
                        src={videoData?.data.data[0]?.video_urls[0].video_url}
                        width={lgUp ? "800" : "362"}
                        height={lgUp ? "452" : "205"}
                        allowFullScreen
                        style={{ borderRadius: "10px", marginTop: "2px" }}
                      ></iframe>
                    )}
                  <Typography
                    sx={{
                      fontSize: lgUp ? "16px" : "12px",
                      color: "#050C46",
                      fontWeight: "700",
                      margin: lgUp ? "10px 0px" : "2px 0px 0px 0px",
                    }}
                  >
                    <span
                      style={{
                        color: "#5862FF",
                        fontSize: lgUp ? "16px" : "12px",
                        fontWeight: "700",
                      }}
                    >
                      {leadInfo?.fname},
                    </span>{" "}
                    you're invited to an exclusive strategy meeting. Secure a
                    limited time slot below or click "chat now" to ask me
                    anything.
                  </Typography>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: lgUp ? "row" : "column",
                        margin: lgUp ? "5px 0px" : "0px 0px",
                      }}
                    >
                      <Button
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "8px",
                          border: "1px solid #5761FE",
                          backgroundColor: "#5761FE",
                          color: "white",
                          margin: lgUp ? "0px 12px" : "6px 0px 10px 0px",
                        }}
                        size="small"
                        logout
                        variant="contained"
                        onClick={(e) => {
                          scheduleMeet();
                        }}
                        endIcon={<img src={arrowRight} />}
                      >
                        Book a meeting
                      </Button>

                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          borderRadius: "8px",
                          border: "1px solid #5761FE",
                        }}
                        onClick={handleOpenChat}
                        endIcon={<img src={chatButton} />}
                      >
                        Chat now to ask anything
                      </Button>
                    </div>

                    {!isChatClick && lgUp && (
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: "50%",
                          border: "1px solid #5761FE",
                          backgroundColor: "#5761FE",
                          position: "absolute",
                          padding: "20px 15px",
                          marginBottom: "2px",
                          right: "20px",
                          bottom: "2%",
                        }}
                        onClick={handleOpenChat}
                      >
                        <img src={chatIcon} />
                      </Button>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div videoRef={videoRef}>
                {text !== "Schedule a meet as per the availability." ? (
                  <div>
                    {" "}
                    {
                      <Typography
                        id={id ? id : ""}
                        sx={{
                          whiteSpace: "break-spaces",
                          color: isUser ? "#FFFFFF" : "#050C46",
                          fontFamily: "DM Sans",
                          fontSize: { xs: "14px", md: "16px" },
                          lineHeight: "24px",
                          fontWeight: 400,
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        {id ? (
                          <>
                            <BouncingDotsLoader />
                          </>
                        ) : (
                          // <ReactMarkdown>{text}</ReactMarkdown>
                          <div>
                            {isMobile ? (
                              <TypingEffect text={text} videoRef={videoRef} />
                            ) : (
                              <span
                                dangerouslySetInnerHTML={{ __html: text }}
                              ></span>
                            )}
                            {!isUser && !eg && !data.isVideoPresent && (
                              <UserMeetingSchedule
                                openMeeting={handleClickOpen}
                                id={id ? id : ""}
                                isUser={isUser}
                              />
                            )}
                          </div>
                        )}
                      </Typography>
                    }
                  </div>
                ) : (
                  <div>
                    {" "}
                    <UserMeetingSchedule
                      openMeeting={handleClickOpen}
                      id={id ? id : ""}
                      isUser={isUser}
                    />
                  </div>
                )}
              </div>
            )}

            {eg && !isVideo && (
              <>
                <Grid container rowSpacing={1} columnGap={1}>
                  {
                    loading ? (
                      <Box
                        sx={{
                          margin: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {/* <label><strong>"PLEASE CONNECT A CALENDAR TO SHOW SLOTS."</strong></label>
                      <CircularProgress
                        color="inherit"
                        sx={{ margin: "auto" }}
                      /> */}
                      </Box>
                    ) : (
                      examples
                        .concat("SHOW MORE SLOTS")
                        ?.map((question, index) => {
                          // examples.length > 0 ? (
                          return (
                            <>
                              <Grid item key={index} sx={{ display: "flex" }}>
                                {" "}
                                <Button
                                  // size="small"
                                  variant="contained"
                                  sx={{
                                    borderRadius: "8px",
                                    backgroundColor: "#5761FE",
                                    color: "#fff",
                                    py: 1,
                                    px: 2,
                                    "&:hover": {
                                      backgroundColor: "#6269d9",
                                    },
                                    textAlign: "left",
                                    fontFamily: "Inter",
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight: "20px",
                                    boxShadow: "0px 1px 2px 0px #1018280D",
                                  }}
                                  onClick={(e) => {
                                    if (busy) return;
                                    handleOpen3(question, index);
                                  }}
                                >
                                  {question}
                                </Button>
                              </Grid>
                            </>
                          );
                        })
                    )
                    // )
                    //  : (
                    //   <Box
                    //     sx={{
                    //       margin: "20px",
                    //       display: "flex",
                    //       justifyContent: "center",
                    //       alignItems: "center",
                    //       width: "100%",
                    //     }}
                    //   >
                    //     <Typography>
                    //       Please add slots between 09:AM to 06:PM EST
                    //     </Typography>
                    //   </Box>
                    // )
                  }
                </Grid>
              </>
            )}

            {isMeet && (
              <div>
                {/* <Button
                variant="contained"
                size="small"
                onClick={addEventToUserCalander}
              >
                Add to Calander
              </Button> */}
                {cronofyElements.map((elements) => elements)}
              </div>
            )}
          </Stack>
        </Stack>
      </Box>

      {/* {((!eg && showSchedule) ||
        (busy === false && !isUser && !eg && !examples.includes(text))) && (
          <Box
            sx={{
              p: 2,
              zIndex: 999,
              borderRadius: isUser ? "16px 16px 0px 16px" : "16px 16px 16px 0px",
              backgroundColor: isUser ? "#050C46" : "#FFFFFF",
              my: isUser ? 0 : 1,
              boxShadow: "0px 1px 2px 0px #1018280D",
              border: "1px solid #ECE9F5",
            }}
          >
            <Stack spacing={1} justifyContent="center">
              
              <UserMeetingSchedule id={id ? id : ""} isUser={isUser} />

            </Stack>
          </Box>
        )} */}
      {Boolean(quickResponses?.length) && (
        <>
          <Grid container rowSpacing={1} columnGap={1}>
            {quickResponses.map((question, index) => {
              return (
                <>
                  <Grid item>
                    {" "}
                    <Button
                      key={index}
                      // size="small"
                      variant="outlined"
                      sx={{
                        borderRadius: "8px",
                        // backgroundColor: "#5761FE",
                        // color: "#fff",
                        color: "#5761FE",
                        py: 1,
                        px: 2,
                        "&:hover": {
                          backgroundColor: "#6269d9",
                          color: "#fff",
                        },
                        textAlign: "left",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        boxShadow: "0px 1px 2px 0px #1018280D",
                      }}
                      onClick={(e) => {
                        if (busy) return;
                        handleEgSelect(question);
                      }}
                    >
                      {question}
                    </Button>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
};

const ChatPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const midUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const videoRef = useRef();
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const queryParams = {};
  query.forEach((value, key) => (queryParams[key] = value));

  const {
    user: leadId,
    leadEmail,
    eid: emailId,
    fname,
    lname,
    companyName,
  } = queryParams;
  const userId1 = queryParams.user;
  const campaignId = queryParams.campaignId;
  const [campaignData, setCampaignData] = useState(null);
  const [isChatClick, setIsChatClick] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  // //Generate templates variable data from chatGPT
  // const generateAIVar = async (campaignInfo, leadInfo) => {
  //   const aiGen = {
  //     challenges: null,
  //     companyMission: null,
  //     sellingPoints: null,
  //     testimonials: null,
  //     prospectIndustry: null,
  //   };

  //   const {
  //     productDesc,
  //     campaignDesc,
  //     industry,
  //     testimonials,
  //     expertise,
  //     senderName,
  //     companyName,
  //   } = campaignInfo;
  //   const {
  //     emailBody,
  //     industry: prospectIndustry,
  //     organization,
  //     firstName,
  //     description,
  //   } = leadInfo;
  //   const aiParams = {
  //     challenges: { prospectIndustry, emailBody },
  //     companyMission: { organization, emailBody },
  //     sellingPoints: { expertise, companyName, productDesc },
  //     testimonials: { testimonials },
  //     prospectIndustry: { emailBody },
  //   };

  //   const chatTemplate = `<div>
  //   Hi <strong>${firstName}</strong>, I am a <strong>${senderName}</strong> from <strong>${companyName}</strong> inviting you as the <strong>${description}</strong> of <strong>${organization}</strong> to an exclusive meeting to discuss your [challenges].

  //   Based on [sellingPoints], I would like to meet and discuss <strong>${organization}</strong>’s <strong>[companyMission]</strong> in the [prospectIndustry].

  //   Based on <strong>[testimonials]</strong>, we could do the same or better for <strong>${organization}</strong>'s.
  //   </div>`;

  //   const aiPromises = Object.keys(aiGen).map(async (key) => {
  //     const content = getGPT_Prompt[key](aiParams[key]);
  //     const tempPrompt = [{ role: "user", content }];
  //     const result = await getGPTResponse(tempPrompt);
  //     const ai_msg = result.choices[0].message;
  //     return ai_msg.content;
  //   });

  //   const contentArray = await Promise.all(aiPromises);
  //   Object.keys(aiGen).forEach((key, index) => {
  //     aiGen[key] = contentArray[index];
  //   });
  //   const concatenatedString = Object.values(aiGen)
  //     .map((section) => `**${section}**`)
  //     .join(", ");

  //   const templateContent = `I will give you the TEMPLATE that I want to use, along with prompts for each section: ${concatenatedString}. Please assist me in writing content for these sections based on the provided prompts. The new content should seamlessly fit into the existing TEMPLATE, replacing the placeholders (texts within square brackets) without causing any grammatical errors or affecting its readability, styling. Ensure that the use of <strong> tags is strictly adhered to in each section. The objective is to use company information to craft a personalized introductory message for prospects, aiming to book an introductory call. Do not introduce new placeholders unless specifically mentioned.Please ensure by enclosing all company names (e.g., Leadingly, Encinos 3D Custom Products LLC, Marketing X, Google) in <strong> tags to highlight them.Each section must contain 25 words only.

  //   TEMPLATE:
  //   ${chatTemplate}`;

  //   const newPrompt = [{ role: "user", content: templateContent }];
  //   const res = await getGPTResponse(newPrompt, {
  //     rawRes: false,
  //     stream: false,
  //   });

  //   // const additionalInstructions = `Please ensure by enclosing all company names (e.g., Leadingly, Encinos 3D Custom Products LLC, Marketing X, Google) in <strong> tags to highlight them.`

  //   // const newRevisedPrompt = [{ role: 'user', content: additionalInstructions },
  //   // ];
  //   // const revisedRes = await getGPTResponse(newRevisedPrompt,{
  //   //   rawRes: true,
  //   //   stream: true,
  //   // });

  //   setHideStreamCont(false);

  //   let text = res.choices[0].message.content;

  //   if (text) {
  //     text += `${ReactDOMServer.renderToString(
  //       <LimitedSlotText
  //         timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
  //       />
  //     )}`;
  //     setHideStreamCont(true);
  //     // streamCont.innerText = "";
  //     setChats((prev) => [
  //       ...prev,
  //       {
  //         isUser: false,
  //         text,
  //         eg: true,
  //         showSchedule: true,
  //       },
  //     ]);
  //     setPrompt((prev) => [...prev, { role: "assistant", content: text }]);
  //   }
  // };

  const generateAIVar = async (campaignInfo, leadInfo) => {
    const aiGen = {
      challenges: null,
      companyMission: null,
      sellingPoints: null,
      testimonials: null,
      prospectIndustry: null,
    };

    const {
      productDesc,
      campaignDesc,
      industry,
      testimonials,
      expertise,
      senderName,
      companyName,
    } = campaignInfo;
    const {
      emailBody,
      industry: prospectIndustry,
      organization,
      firstName,
      description,
    } = leadInfo;
    const aiParams = {
      challenges: { prospectIndustry, emailBody },
      companyMission: { organization, emailBody },
      sellingPoints: { expertise, companyName, productDesc },
      testimonials: { testimonials },
      prospectIndustry: { emailBody },
    };

    const chatTemplate = `<div>
    Hi <strong>${firstName}</strong>, I am a ${senderName} from <strong>${companyName}</strong> inviting you as the <strong>${description}</strong> of <strong>${organization}</strong> to an exclusive meeting to discuss your [challenges].

    Based on [sellingPoints], I would like to meet and discuss <strong>${organization}</strong>’s [companyMission] in the [prospectIndustry].

    Based on <strong>[testimonials]</strong>, we could do the same or better for <strong>${organization}</strong>'s.
    </div>`;

    const aiPromises = Object.keys(aiGen).map(async (key) => {
      const content = getGPT_Prompt[key](aiParams[key]);
      const tempPrompt = [{ role: "user", content }];
      const result = await getGPTResponse(tempPrompt);
      const ai_msg = result?.choices?.[0]?.message;
      return ai_msg.content;
    });

    const contentArray = await Promise.all(aiPromises);
    Object.keys(aiGen).forEach((key, index) => {
      aiGen[key] = contentArray[index];
    });
    const concatenatedString = Object.values(aiGen)
      .map((section) => `**${section}**`)
      .join(", ");

    const templateContent = `I will give you the TEMPLATE that I want to use, along with prompts for each section: ${concatenatedString}. Please assist me in writing content for these sections based on the provided prompts. The new content should seamlessly fit into the existing TEMPLATE, replacing the placeholders (texts within square brackets) without causing any grammatical errors or affecting its readability, styling. Ensure that the use of <strong> tags is strictly adhered to in each section. The objective is to use company information to craft a personalized introductory message for prospects, aiming to book an introductory call. Do not introduce new placeholders unless specifically mentioned.Please ensure by enclosing all company names (e.g., Leadingly, Encinos 3D Custom Products LLC) in <strong> tags to highlight them.Each section must contain 25 words only.
    
    TEMPLATE:
    ${chatTemplate}`;

    return templateContent;
  };

  useEffect(() => {
    const box = document.querySelector("#content-div");

    const isInViewport = (element) => {
      let rect = {};
      if (element) {
        rect = element.getBoundingClientRect();
      }

      return (
        rect.bottom > 110 &&
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left >= 0 &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
      //  return (
      //    rect.top >= 0 &&
      //    rect.left >= 0 &&
      //    rect.bottom <=
      //      (window.innerHeight || document.documentElement.clientHeight) &&
      //    rect.right <=
      //      (window.innerWidth || document.documentElement.clientWidth)
      //  );
    };
    const handleScroll = () => {
      if (isInViewport(videoRef?.current)) {
        setIsVideoVisible(true);
      } else {
        setIsVideoVisible(false);
      }
    };
    if (campaignData?.link?.url == "") {
      box.addEventListener("scroll", handleScroll);
      // window.addEventListener("scroll", handleScroll);

      return () => {
        // window.removeEventListener("scroll", handleScroll);

        box.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    const box = document.querySelector("#content-div");

    const isInViewport = (element) => {
      let rect = {};
      if (element) {
        rect = element.getBoundingClientRect();
      }

      return (
        rect.bottom > 110 &&
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left >= 0 &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
      //  return (
      //    rect.top >= 0 &&
      //    rect.left >= 0 &&
      //    rect.bottom <=
      //      (window.innerHeight || document.documentElement.clientHeight) &&
      //    rect.right <=
      //      (window.innerWidth || document.documentElement.clientWidth)
      //  );
    };
    const handleScroll = () => {
      if (isInViewport(videoRef?.current)) {
        setIsVideoVisible(true);
      } else {
        setIsVideoVisible(false);
      }
    };
    if (campaignData?.link?.url == "") {
      box.addEventListener("scroll", handleScroll);
      // window.addEventListener("scroll", handleScroll);

      return () => {
        // window.removeEventListener("scroll", handleScroll);

        box.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const generateContext = ({
    organisationName,
    companyName,
    title,
    senderName,
    senderTitle,
    productDesc,
    campaignDesc,
    industry,
    testimonials,
    testimonials_v2,
    targets,
  }) => {
    const senderOrg = {
      name: companyName,
      businessDesc: campaignDesc,
      values: `Our company values innovation, excellence, and collaboration. We prioritize customer satisfaction, operate with integrity, and embrace empowerment and adaptability. Sustainability, social impact, diversity, and inclusion are integral to our mission, driving us to make a positive difference in the industry.`,
    };
    const salesAgent = {
      // name: senderName,
      name: "an AI Agent",
      role: "Customer Success Manager",
    };
    const communication = {
      purpose: `tell them about our new products and how can they help in your business`,
      type: `chat`,
    };
    const testimonial_v2Processed = testimonials_v2
      .filter((testimonial) => {
        return Boolean(testimonial.testimonial);
      })
      .map((testimonial) => {
        delete testimonial._id;
        return testimonial;
      });

    let context = `Never forget you're ${salesAgent.name}. You work ${
      /*`as a ${salesAgent.role}, `*/ ""
      } at company named ${senderOrg.name}.\n${senderOrg.name
      }'s business is the following: ${senderOrg.businessDesc
      }.\nCompany values are following: ${senderOrg.values
      }.\nYou are responding a potential customer in order to ${communication.purpose
      }.\nYour means of contacting the prospect is via ${communication.type
      }.\nIf you're asked about where you got the user's contact information, say that you got it from public records. Keep your responses in short length to retain the user's attention. Never produce lists, just answers. You must respond according to the previous conversation history and using the product description that is provided. Only generate one response at a time! When you are done generating, give the user a chance to respond and it should not be in email format. Strictly refrain from including any placeholders in the response.\nProduct description:\n${productDesc}.\n${Boolean(testimonial_v2Processed.length) &&
      `\nList of testimonials/reviews:\n${JSON.stringify(
        testimonial_v2Processed
      )}`
      }`;

    // console.log("context", context);
    return context;
  };
  const [leadInfo, setLeadInfo] = useState(
    Boolean(leadId)
      ? null
      : {
        email: leadEmail,
        fname,
        lname,
      }
  );
  const [history, setHistory] = useState([]);
  const [trackingCompleted, setTrackingCompleted] = useState(false);

  // useEffect(() => {
  const leadData = (leadId) => {
    if (!Boolean(leadId)) return;
    axios
      .post(`${API_SERVICE_BACKEND}/leadOpt`, {
        payload: {
          filter: {
            _id: leadId,
          },
          // projection: {},
          // checkExists: false,
        },
      })
      .then((res) => {
        const leadInfo = res.data;
        if (leadInfo.error) {
          toast.error(leadInfo.error);
          return;
        }
        if (!Boolean(leadInfo)) {
          const error = `No user found with id: ${leadId}`;
          toast.error(error);
          return;
        }
        leadInfo[0].fname = leadInfo[0]?.firstName;
        leadInfo[0].lname = leadInfo[0]?.lastName;
        // leadInfo[0].organization = leadInfo[0]?.organization;
        setLeadInfo(leadInfo[0]);
      });
  };
  // }, []);

  // check
  const [cronofyToken, setCronofyToken] = useState(null);
  const [calenderId, setCalenderId] = useState(null);
  const [sub, setSub] = useState();
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    const cronofycalendar = async () => {
      try {
        const responseDataLog = await fetch(
          `${API_SERVICE_BACKEND_3}/calendar`,
          {
            method: "GET",
            headers: {
              cronofyToken: cronofyToken,
            },
          }
        ).then((res) => res.json());
        setCalenderId(responseDataLog.calendars[0].calendar_id);
      } catch (err) { }
    };
    if (!calenderId && cronofyToken) {
      cronofycalendar();
    }
  }, [cronofyToken]);

  // check

  useEffect(() => {
    if (!leadInfo) return;

    const { email: leadEmail, fname, lname, userId, organization } = leadInfo;

    if (leadEmail && leadEmail !== "anonymous" && !history?.length) {
      (async () => {
        const res = await axios.get(`${API_SERVICE_BACKEND}/leadInfo`, {
          params: {
            campaignId,
            leadEmail,
          },
        });
      })();

      (async () => {
        const res = await axios.get(`${API_SERVICE_BACKEND}/getLeadConvo`, {
          params: {
            leadEmail,
            campaignId,
            emailId: emailId || null,
          },
        });
        if (res.status === 200) {
          setHistory(res.data.convo.history);
          setTrackingCompleted(true);
        } else if (res.status === 204) {
          const payload = {
            leadEmail,
            campaignId,
            emailId: emailId || null,
            firstName: fname,
            lastName: lname,
          };
          const res = await axios.post(
            `${API_SERVICE_BACKEND}/addConvo`,
            payload
          );
          if (res.status === 201) {
            setHistory(res.data.convo.history);
          } else if (res.status === 200) {
            // try to refetch the history
            // null;
          }
          setTrackingCompleted(true);
        }
      })();
    } else {
      setHistory([]);
      setTrackingCompleted(true);
    }
  }, [leadInfo]);

  // }

  const findcampaign = (campaignId) => {
    if (!campaignId) {
      navigate("/404");
      return;
    }
    axios
      .get(`${API_SERVICE_BACKEND}/filterCampaigns`, {
        params: { _id: campaignId },
      })
      .then((res) => {
        if (res.status === 200 && res.data.length) {
          setCampaignData(res.data[0]);
          const context = generateContext(campaignData);
          const initPrompt = [{ role: "user", content: context }];
          setPrompt(initPrompt);
          if (res.data[0].link?.enabled) {
            console.log("1111111", 1111111);
            handleOpenDialog("redirect");
          }
        } else {
          toast.warn("Invalid URL !");
        }
      })
      .catch((err) => {
        toast.error(err.response?.statusText);
      });
  };

  const [dialog, setDialog] = useState(false);
  const handleOpenDialog = (dialog) => {
    setDialog(dialog);
  };
  const handleCloseDialog = () => {
    setDialog(null);
  };

  const handleOpenChat = () => {
    // generateAIVar(campaignData, leadInfo);
    setIsChatClick(true);
    setIsVideo(false);
    setIsvideoHit(false);
    setHideStreamCont(true);
  };

  useEffect(() => {
    if (!campaignData || !trackingCompleted) {
      return;
    }
    if (campaignData.link?.enabled) {
      setTimeout(() => {
        window.location.replace(campaignData.link?.url);
      }, 2500);
    }
  }, [campaignData, trackingCompleted]);

  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState("");
  const [chats, setChats] = useState([]);
  const [prompt, setPrompt] = useState([]);
  const [prompting, setPrompting] = useState(true);
  const [promptForMeet, setPromptForMeet] = useState({
    hidden: false,
    show: false,
  });
  const handlePromptForMeet = (field, value) => {
    setPromptForMeet((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // useEffect(() => {
  //   if (!prompt?.length) return;
  // }, [prompt]);

  // const isRequestingMeet = async (input) => {
  //   getGPTResponse(
  //     [
  //       {handleSendClick
  //     {
  //       rawRes: true,
  //       stream: true,
  //     }
  //   )
  //     .then(async (res) => {
  //       setHideStreamCont(false);
  //       const text = await streamRes(res);

  //       if (text) {
  //         // const text = data.choices[0].message.content;
  //         const streamCont = document.getElementById("stream-container");
  //         setHideStreamCont(true);
  //         streamCont.innerText = "";
  //         setChats((prev) => [...prev, { isUser: false, text, eg: true }]);
  //         setPrompt((prev) => [...prev, { role: "assistant", content: text }]);
  //       }
  //     })
  //     .catch((err) => {
  //       setHideStreamCont(false);
  //       setChats((prev) => [
  //         ...prev,
  //         {
  //           isUser: false,
  //           text: "I'm experiencing some problems, try again later !",
  //         },
  //       ]);
  //     });
  // };

  const getGPTResponse = async (messages, options = {}) => {
    const { rawRes, stream, ...otherOptions } = options;
    // const res = await axiosOpenAI.post("/chat/completions", {
    //   model: "gpt-3.5-turbo",
    //   messages,
    //   stream: stream ? true : false,
    //   temperature: 0.9,
    //   max_tokens: 150,
    //   top_p: 1,
    //   frequency_penalty: 0,
    //   presence_penalty: 0.6,
    //   stop: [" user:", " assistant:"],
    // });
    const res = await fetch(`https://api.openai.com/v1/chat/completions`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_OPENAI_SECRET}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages,
        stream: stream ? true : false,
        temperature: 0.9,
        // max_tokens: 150,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0.6,
        stop: [" user:", " assistant:"],
      }),
    });
    if (rawRes) return res;
    const data = await res.json();
    return data;
  };

  const streamRes = async (res) => {
    try {
      const reader = res.body.getReader();
      let tempResFromStream = "";
      while (true) {
        const chunk = await reader.read();
        const { done, value } = chunk;
        if (done) break;
        const decodedChunk = decoder.decode(value);
        const lines = decodedChunk.split("\n");
        const parsedLines = lines
          ?.map((line) => line?.replace(/^data: /, "").trim())
          .filter((line) => line !== "" && line !== "[DONE]")
          .map((line) => JSON.parse(line));
        parsedLines?.forEach((line) => {
          const content = line?.choices[0].delta.content;
          const streamCont = document.getElementById("stream-container");
          if (content && streamCont) {
            // streamCont.innerText += content;
            tempResFromStream += content;
            const chatContainer = document.querySelector("#content-div");
            if (campaignData?.link?.url == "") {
              chatContainer.scrollTop = chatContainer?.scrollHeight;
            }
          }
        });
      }
      return tempResFromStream;
    } catch (err) {
      return false;
    }
  };

  const [hideStreamCont, setHideStreamCont] = useState(true);
  const getConvoUnreadCount = async () => {
    try {
      const campaigns = await jobsApi.getUserCampaigns(
        campaignData?.clientEmail
      );
      const campaignIds = campaigns.map(({ _id }) => _id);

      let url = `${API_SERVICE_BACKEND}/getCampConvo`;
      url += `/all?campaignIdListStr=${campaignIds.join(",")}`;
      // console.log(url);
      const response = await fetch(url);
      const data = await response.json();
      const count = data?.conversations.length;
      return count;
    } catch (err) {
      console.log(err);
    }
  };
  const submitInput = async (progInp, options = {}) => {
    if (!input && !progInp) return;
    const localInput = progInp ? progInp : input;
    handlePromptForMeet("show", false);
    setIsLoading(true);
    setPrompting(true);
    if (!options.hidden) {
      setChats((prev) => [...prev, { isUser: true, text: localInput }]);
    }

    if (checkKeywords(localInput)) {
      setInput("");
      setIsLoading(false);
      setPrompting(false);
      setChats((prev) => [
        ...prev,
        {
          isUser: false,
          text: "Schedule a meet as per the availability.",
          isMeetLink: true,
        },
      ]);
      return;
    }
    const lastPrompt = { role: "user", content: localInput };
    const tempPrompt = [...prompt, lastPrompt];
    // console.log("tempPrompt", tempPrompt);
    setPrompt(tempPrompt);
    setInput("");
    try {
      const res = await getGPTResponse(tempPrompt, {
        rawRes: true,
        stream: true,
      });
      setHideStreamCont(false);
      const text = await streamRes(res);

      if (text) {
        const streamCont = document.getElementById("stream-container");
        setHideStreamCont(true);
        streamCont.innerText = "";
        setChats((prev) => [...prev, { isUser: false, text }]);
        const aiResObj = { role: "assistant", content: text };
        setPrompt((prev) => [...prev, aiResObj]);
        axios
          .patch(`${API_SERVICE_BACKEND}/updateConvo`, {
            filter: { leadEmail: leadInfo.email, campaignId },
            chats: [lastPrompt, aiResObj],
          })
          .then((res) => { })
          .catch((err) => { });
      }
    } catch (error) {
      toast.error("Something went wrong, try later !");
    }
    const count = await getConvoUnreadCount();
    const conversation_detail = {
      email: campaignData?.clientEmail,
      ai_agent_name: campaignData?.title,
      prospect_fullname: leadInfo?.firstName + " " + leadInfo?.lastName,
      prospect_email: leadInfo?.email,
      prospect_title: leadInfo?.description,
      prospect_company: leadInfo?.organization,
      prospect_linkedin_url: leadInfo?.linkedin_url,
      conversation_count: count,
    };
    fetch(`${API_SERVICE_BACKEND}/intercom-event`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(conversation_detail),
    })
      .then((response) => response.json())
      .then((data) => console.log("Success:", data))
      .catch((error) => console.error("Error:", error));
    setIsLoading(false);
    setPrompting(false);
    handlePromptForMeet("show", true);
  };
  // useEffect(() => {
  //   const links = document.querySelectorAll(".full_calander_slot");
  //   links.forEach((link) =>
  //     link.addEventListener("click", () => {
  //       scheduleMeet();
  //     })
  //   );
  // }, [chats]);

  const [videoData, setVideoData] = useState([]);
  const [isvideoHit, setIsvideoHit] = useState(false);

  const getVideoInfo = async () => {
    const url = `${API_HIPPO_VIDEO}/getAllCompanyDataList`;
    const params = {
      email: leadInfo?.email,
      campaignId: campaignId,
    };
    const res = await axios.post(url, params);
    if (res !== undefined) {
      setVideoData(res);
    }
    if (res.data?.data[0]?.video_urls[0].status === "success") {
      setIsvideoHit(true);
      setIsVideo(true);

      setChats((prev) => [
        ...prev,
        {
          isUser: false,
          text: `Hi ${leadInfo?.fname} , I created this special video for`,
          isVideoPresent: true,
        },
      ]);
    }
    // videoData?.data.data[0]?.video_urls[0].status ==="success"
  };

  const fetchApi = async (initPrompt) => {
    getGPTResponse(
      [
        ...initPrompt,
        {
          role: "user",
          content:
            leadEmail === "anonymous"
              ? `Present yourself as a representative of the previously mentioned company in order to capture the customer's interest. Inquire if they would like to explore further about the products and services provided by your company, emphasizing how these offerings can benefit their own business ventures. Remember to keep it really short and consice. Customer Information:\nFIRST_NAME: ${leadInfo.fname}\nLAST_NAME: ${leadInfo.lname}`
              : await generateAIVar(campaignData, leadInfo),
        },
      ],
      {
        rawRes: true,
        stream: true,
      }
    )
      .then(async (res) => {
        // if (data.choices) {
        //   const message = data.choices[0].message;
        //   const { content: text } = message;
        //   setPrompt((prev) => [...prev, message]);
        //   setChats((prev) => [...prev, { isUser: false, text, eg: true }]);
        // }
        // setPrompting(false);
        // setIsLoading(false);
        setHideStreamCont(false);
        let text = await streamRes(res);
        const streamCont = document.getElementById("stream-container");

        if (text && streamCont) {
          // const text = data.choices[0].message.content;
          // text += `<br/><br/>${limitedSlotText(timezone)}`;
          // In your code, you can render the component like this:
          text += `${ReactDOMServer.renderToString(
            <LimitedSlotText
              timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
            />
          )}`;

          setHideStreamCont(true);
          streamCont.innerText = "";
          setChats((prev) => [
            ...prev,
            {
              isUser: false,
              text,
              eg: true,
              showSchedule: true,
              // quickResponses: ["Yes", "Not yet"],
            },
          ]);
          setPrompt((prev) => [...prev, { role: "assistant", content: text }]);
        }
      })
      .catch((err) => {
        setHideStreamCont(true);
        setChats((prev) => [
          ...prev,
          {
            isUser: false,
            text: "I'm experiencing some problems, try again later!",
          },
        ]);
      })
      .finally(() => {
        setIsLoading(false);
        setPrompting(false);
      });
  };

  useEffect(() => {
    if (leadInfo?.email) {
      getVideoInfo();
    }
  }, [leadInfo?.email]);

  useEffect(() => {
    if (!campaignData || !leadInfo || !history || campaignData.link?.enabled)
      return;

    const context = generateContext(campaignData);
    const initPrompt = [{ role: "user", content: context }];

    if (history.length !== 0) {
      const tempHistory = [
        ...initPrompt,
        ...history.map((chat) => {
          delete chat._id;
          return chat;
        }),
      ];
      setPrompt(tempHistory);
      const processedChats = [];
      for (let i = 1; i < tempHistory.length; i += 1) {
        const { role, content } = tempHistory[i];
        processedChats.push({ isUser: role === "user", text: content });
      }
      setChats(processedChats);
      setPrompting(false);
      return;
    }

    setIsLoading(true);
    setPrompt(initPrompt);

    if (
      !isvideoHit &&
      videoData &&
      videoData?.data &&
      videoData?.data?.data?.length !== 0
    ) {
      fetchApi(initPrompt);
    } else if (
      videoData &&
      videoData?.data &&
      videoData?.data?.data?.length === 0
    ) {
      // generateAIVar(campaignData, leadInfo);
      fetchApi(initPrompt);
    } else if (campaignId_urlParam && leadEmail === "anonymous") {
      fetchApi(initPrompt);
    } else {
      setHideStreamCont(true);
      setIsLoading(false);
      // setPrompt([]);
      setPrompting(false);
    }
  }, [campaignData, leadInfo, history, isvideoHit, videoData]);

  // }, [campaignData, histofry]);

  useEffect(() => {
    const chatContainer = document.querySelector("#chat-container");
    if (campaignData?.link?.url == "") {
      chatContainer.scrollTop = chatContainer?.scrollHeight;
    }
  }, [chats]);

  // useEffect(() => {
  //   const inputField = document.querySelector("#user-input");
  //   inputField.focus();
  // }, []);

  useEffect(() => {
    const getAccessToken = async () => {
      const userInfo = await getUserInfo(userEmail);
      const accessToken = userInfo?.extra?.accessToken?.access_token;
      const sub = userInfo?.extra?.accessToken?.sub;
      setSub(sub);
      setCronofyToken(accessToken);
    };
    if (userEmail) {
      getAccessToken();
    }
  }, [userEmail]);

  // Cronofy
  const [accessToken, setAccessToken] = useState(null);
  const [dateTimeOptions, setDateTimeOptions] = useState(null);
  const [userDetails, setUserDetails] = useState();
  const [testimonialDispaly, SetTestimonialDispaly] = useState(false);

  useEffect(() => {
    if (!campaignData || accessToken) return;
    setUserEmail(campaignData?.clientEmail);
    (async () => {
      const userInfo = await getUserInfo(campaignData?.clientEmail);
      const accessToken = userInfo?.extra?.access_token;
      setUserDetails(userInfo);
      setAccessToken(accessToken);
    })();
  }, [campaignData]);

  useEffect(() => {
    if (!accessToken) return;
    const now = Date.now();

    setDateTimeOptions({
      data_center: cronofy.data_center,
      target_id: "cronofy-date-time-picker",
      availability_query: {
        participants: [
          {
            required: "all",
            members: [{ sub: accessToken.sub, managed_availability: true }],
          },
        ],
        required_duration: { minutes: meetPrefs.durationInMins },
        available_periods: [
          {
            start: `${new Date(now + meetPrefs.delayInMS).toISOString().split(".")[0]
              }Z`,
            end: `${new Date(
              now +
              meetPrefs.allowMeetsUptoXDaysInFuture * 24 * 60 * 60 * 1000
            )
              .toISOString()
              .split(".")[0]
              }Z`,
          },
        ],
      },
    });
  }, [accessToken]);

  const scheduleMeet = () => {
    handlePromptForMeet("show", false);
    submitInput("meet", { hidden: true });
    setIsVideo(false);
  };

  const getRandomImageURL = () => {
    const randomIndex = Math.floor(Math.random() * 15) + 1; // Generate a random index between 1 and 15
    return `/assets/avatarImg/i-${randomIndex}.webp`; // Use the random index to construct the image URL
  };

  const [feedBackOpen, setFeedbackOpen] = useState(false);

  let testimonials = [
    {
      text: campaignData?.testimonials_v2?.[0]?.testimonial,
    },
    {
      text: campaignData?.testimonials_v2?.[1]?.testimonial,
    },
    {
      text: campaignData?.testimonials_v2?.[2]?.testimonial,
    },
  ];

  let testimonialsName = [
    {
      text: campaignData?.testimonials_v2?.[0]?.clientName,
    },
    {
      text: campaignData?.testimonials_v2?.[1]?.clientName,
    },
    {
      text: campaignData?.testimonials_v2?.[2]?.clientName,
    },
  ];

  let testimonialsCompanyName = [
    {
      text: campaignData?.testimonials_v2?.[0]?.clientCompanyName,
    },
    {
      text: campaignData?.testimonials_v2?.[1]?.clientCompanyName,
    },
    {
      text: campaignData?.testimonials_v2?.[2]?.clientCompanyName,
    },
  ];
  let filteredTestimonials = [];
  let filteredTestimonialsName = [];
  let filteredTestimonialsCompanyName = [];
  for (let i in testimonials) {
    if (testimonials[i].text !== "") {
      filteredTestimonials.push(testimonials[i]);
      filteredTestimonialsName.push(testimonialsName[i]);
      filteredTestimonialsCompanyName.push(testimonialsCompanyName[i]);
    }
  }
  testimonials = filteredTestimonials;
  testimonialsName = filteredTestimonialsName;
  testimonialsCompanyName = filteredTestimonialsCompanyName;

  const handleOpen1 = () => {
    setFeedbackOpen(true);
  };
  const mobileNav = useMobileNav();
  const currentDate = new Date();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const currentDay = daysOfWeek[currentDate.getDay()];

  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  const handleNext = () => {
    if (currentTestimonialIndex < testimonials.length - 1) {
      setCurrentTestimonialIndex(currentTestimonialIndex + 1);
    } else {
      setCurrentTestimonialIndex(0);
    }
  };

  const handlePrevious = () => {
    if (currentTestimonialIndex > 0) {
      setCurrentTestimonialIndex(currentTestimonialIndex - 1);
    } else {
      setCurrentTestimonialIndex(testimonials.length - 1);
    }
  };

  const userEmailId = localStorage.getItem("userEmail");

  const [mobileOpen, setMobileOpen] = useState(false);
  const myScrollableDivRef = useRef(null);

  const [scrollPosition, setScrollPosition] = useState(0);

  // const handleResize = () => {
  //   if (myScrollableDivRef.current) {
  //     const newHeight = myScrollableDivRef.current.clientHeight;
  //     const scrollDifference = newHeight - scrollPosition;
  //     myScrollableDivRef.current.scrollTop =
  //       myScrollableDivRef.current.scrollHeight - scrollDifference;
  //     setScrollPosition(newHeight);
  //   }
  // };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    setIsVideoVisible(false);
  };

  // useEffect(() => {

  //   if (myScrollableDivRef.current) {
  //     myScrollableDivRef.current.scrollTop =
  //       myScrollableDivRef.current.scrollHeight;
  //     setScrollPosition(myScrollableDivRef.current.scrollHeight);
  //   }

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  useEffect(() => {
    campaignData?.testimonials_v2.map((data) => {
      if (
        data.clientCompanyName ||
        data.clientName ||
        data.clientTitle ||
        data.testimonial
      ) {
        SetTestimonialDispaly(true);
      }
    });
  }, [campaignData?.testimonials]);

  const element = useRoutes(routes);

  const { urlId, campaignId: campaignId_urlParam } = useParams();

  const getOriginalUrl = async (options = {}) => {
    const { id } = options;
    if (!id) return;
    const res = await axios.get(`${API_SERVICE_BACKEND}/convertshorten`, {
      params: {
        id,
      },
    });
    return res.data?.originalURL;
  };

  useEffect(() => {
    if (element?.props?.match?.pathname.includes("/chat/private")) {
      if (urlId) {
        getOriginalUrl({ id: urlId }).then((url) => {
          if (url) {
            const regex = /\/([a-f\d]{24})\/([a-f\d]{24})\/([a-f\d]{24})/;

            const matches = url.match(regex);
            setSearchParams({
              ["user"]: matches[2],
              ["eid"]: matches[3],
              ["campaignId"]: matches[1],
            });

            findcampaign(matches[1]);
            leadData(matches[2]);
          } else {
            http: alert("Invalid URL");
          }
        });
        return;
      }
    } else if (element?.props?.match?.pathname.includes("/chat")) {
      if (campaignId_urlParam && leadEmail === "anonymous") {
        findcampaign(campaignId_urlParam);
        return;
      }
    }
  }, []);

  const visistWebsite = async () => {
    console.log("==", leadInfo.isWebSiteVisit, campaignData._id);
    if (leadInfo.isWebSiteVisit) return;

    await axios
      .post(`${API_SERVICE_BACKEND_2}/visit_Website`, {
        leadId: leadInfo._id,
        campaignId: campaignData._id,
      })
      .then((res) => {
        if (res.data.message == "success")
          setLeadInfo((pre) => ({ ...pre, isWebSiteVisit: true }));
      });
  };

  return (
    <>
      <Dialog
        open={Boolean(dialog)}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        {dialog === "redirect" ? (
          <>
            <DialogTitle id="alert-dialog-title">
              Please wait, we're redirecting you to
              <span style={{ color: "blue" }}>
                {campaignData.organisationName || "company"}'s
              </span>{" "}
              official webpage...
            </DialogTitle>
            {/* <DialogContent></DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Back</Button>
            <Button onClick={handleComplete} disabled={loading} autoFocus>
              Submit
            </Button>
          </DialogActions> */}
          </>
        ) : null}
      </Dialog>

      {campaignData?.link?.url == "" ? (
        <Box
          sx={{
            backgroundColor: "#F3F1FA",
            width: "100vw",
            height: "100vh",
            overflowX: "hidden",
            overflowY: "hidden",
            // pb: 1,
          }}
        >
          {/* <Box>
          <Drawer
            sx={{
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                background: "#050C46",
              },
            }}
            variant="permanent"
            anchor="top"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ height: "100%" }}>
                <List sx={{ color: "#fff" }}>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon sx={{ color: "#fff" }}>
                        <img src={vector} width="32.1px" height="32.11px" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Leadingly"
                        secondary="CHATBOT"
                        primaryTypographyProps={{
                          variant: "h6",
                          fontWeight: "bold",
                        }}
                        secondaryTypographyProps={{
                          fontWeight: "bold",
                          color: "#5862FF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </div>
              <div>
                <Typography
                  sx={{
                    color: "#FFF",
                    fontSize: { xs: "18px", md: "24px" },
                    // fontFamily: 'Roboto',
                    fontWeight: 700,
                  }}
                  variant="h2"
                >
                  Leadingly Agents
                </Typography>
              </div>
              <div>
                <List sx={{ color: "#fff" }}>
                  <ListItem disablePadding>
                    <ListItemButton
                      sx={{
                        border: "1px solid rgb(65, 84, 117)",
                        marginRight: "15px",
                        borderRadius: "10px",
                      }}
                    >
                      <ListItemIcon sx={{ color: "#fff" }}>
                        <LogoutIcon />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItemButton>
                  </ListItem>
                </List>
              </div>
            </div>
          </Drawer>
        </Box> */}
          <Box
            Box
            sx={{
              borderRadius: { xs: 0, md: "16px 16px 0px 0px" },
              backgroundColor: {
                xs: lgUp ? "#ffffff" : "#ECE9F5",
                md: "#F3F1FA",
              },
              // overflow: "auto",
              height: { xs: "calc(100vh - 61px)", md: "calc(100vh - 0px)" },
              // height: "100%",
              display: "flex",
              flexDirection: lgUp ? "row" : "column",
              alignItems: lgUp ? "center" : "flex-end",
              position: "relative",
              // marginBottom: "20px",
            }}
          >
            {lgUp && (
              <div
                style={{
                  backgroundColor: "rgb(224 224 233)",
                  padding: "25px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "18%",
                  height: "100%",
                  overflow: "scroll",
                }}
              >
                <Stack spacing={2}>
                  {/* <Typography
                  sx={{ color: "#050C46", fontSize: "18px", fontWeight: "700" }}
                >
                  {campaignData?.companyName || "Company Name"} •{" "}
                  {leadInfo?.organization || "Prospect Company"}
                </Typography> */}

                  <Typography
                    sx={{
                      color: "#050C46",
                      fontSize: "18px",
                      fontWeight: "500",
                      margin: "0px 10px",
                      fontFamily: "DM Sans",
                    }}
                  >
                    {/* Prepared exclusively for{" "}
                  {leadInfo?.fname + " " + leadInfo?.lname} at{" "}
                  {leadInfo?.organization || "Prospect Company"} Brands with
                  love */}
                    Hey{" "}
                    <Typography
                      sx={{
                        display: "inline",
                        color: "#050C46",
                        fontSize: "18px",
                        fontWeight: "700",
                        fontFamily: "DM Sans",
                      }}
                    >
                      {leadInfo?.fname}
                    </Typography>
                    , I've prepared this invitation exclusively for you.
                  </Typography>

                  <Button
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "8px",
                      border: "1px solid #5761FE",
                      backgroundColor: "#5761FE",
                      color: "white",
                      padding: "8px",
                      fontSize: "13px",
                    }}
                    logout
                    variant="contained"
                    onClick={(e) => {
                      scheduleMeet();
                    }}
                    endIcon={<img src={arrowRight} />}
                  >
                    Book A Meeting With Me
                  </Button>
                </Stack>

                <div>
                  {testimonialDispaly && (
                    <div>
                      <Typography
                        sx={{
                          color: "#050C46",
                          fontSize: "16px",
                          fontWeight: "500",
                          fontFamily: "DM Sans",
                        }}
                      >
                        See what others are saying about{" "}
                        {campaignData?.companyName}
                      </Typography>
                      <>
                        <Stack
                          spacing={2}
                          sx={{
                            backgroundColor: "white",
                            border: "1px solid white",
                            padding: "20px",
                            margin: "15px 0px",
                            borderRadius: "12px",
                          }}
                        >
                          <div>
                            <img src={Star} />
                            <img src={Star} />
                            <img src={Star} />
                            <img src={Star} />
                            <img src={Star} />
                          </div>
                          <Typography
                            sx={{
                              color: "#050C46",
                              fontSize: "13px",
                              fontWeight: "400",
                              fontFamily: "DM Sans",
                            }}
                          >
                            {testimonials[currentTestimonialIndex]?.text}
                          </Typography>
                          <Stack
                            spacing="10px"
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Avatar src={user} />
                            <Typography
                              style={{
                                color: "#050C46",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                            >
                              {testimonialsName[currentTestimonialIndex]?.text}
                            </Typography>
                            <Typography
                              style={{
                                color: "#7E82A5",
                                fontSize: "13px",
                                fontWeight: "500",
                              }}
                            >
                              {
                                testimonialsCompanyName[currentTestimonialIndex]
                                  ?.text
                              }
                            </Typography>
                          </Stack>
                          {testimonials?.length > 1 && (
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box>
                                <Tooltip title="Previous" placement="top" arrow>
                                  <IconButton
                                    onClick={handlePrevious}
                                    sx={{
                                      borderRadius: "50%",
                                      margin: 2,
                                      p: 0,
                                      border: "1px solid #EAECF0",
                                      backgroundColor: "white",
                                      color: "#5761FE",
                                      width: "2em",
                                      height: "2em",
                                    }}
                                  >
                                    <ChevronLeftOutlined />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                              {testimonials.map((testimonial, index) => (
                                <button
                                  style={{
                                    padding: "3px",
                                    backgroundColor:
                                      index === currentTestimonialIndex
                                        ? "#5761FE"
                                        : "#B3B5C7",
                                    borderRadius: "16px",
                                    border:
                                      index === currentTestimonialIndex
                                        ? "1px solid #5761FE"
                                        : "1px solid #B3B5C7",
                                    margin: 2,
                                  }}
                                  onClick={() =>
                                    setCurrentTestimonialIndex(index)
                                  }
                                >
                                  <div
                                    style={{
                                      width: "1px",
                                      height: "1px",
                                      borderRadius: "50%",
                                      cursor: "pointer",
                                    }}
                                  ></div>
                                </button>
                              ))}
                              <Box>
                                <Tooltip title="Next" placement="top" arrow>
                                  <IconButton
                                    onClick={handleNext}
                                    sx={{
                                      borderRadius: "50%",
                                      margin: 2,
                                      p: 0,
                                      border: "1px solid #EAECF0",
                                      backgroundColor: "white",
                                      color: "#5761FE",
                                      width: "2em",
                                      height: "2em",
                                    }}
                                  >
                                    <ChevronRightOutlined />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Stack>
                          )}
                        </Stack>
                      </>
                      {/* <Button variant="outlined" onClick={handleOpen1}>Feedback</Button> */}
                    </div>
                  )}
                  {(campaignData && campaignData?.companyWebsite && (
                    <a
                      href={
                        (campaignData && campaignData?.companyWebsite) || "#"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={visistWebsite}
                    >
                      <Button
                        endIcon={<img src={arrowRight} />}
                        fullWidth
                        style={{
                          backgroundColor: "#2970FF",
                          color: "white",
                          fontSize: "14px",
                        }}
                      >
                        Visit Our Website
                      </Button>
                    </a>
                  )) ||
                    ""}
                </div>

                {feedBackOpen && (
                  <Dialog
                    open={feedBackOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="md"
                  >
                    <DialogTitle
                      sx={{
                        color: "#050C46",
                        fontWeight: "700",
                        fontSize: "22px",
                      }}
                    >
                      Your Feedback
                      <IconButton
                        aria-label="close"
                        onClick={() => setFeedbackOpen(false)}
                        style={{
                          position: "absolute",
                          right: 8,
                          top: 4,
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent>
                      <QuillEditor
                        placeholder="Write something"
                        sx={{ height: 200 }}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => setFeedbackOpen(false)}
                        sx={{ border: "1px solid #ECE9F5" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => { }}
                        sx={{ backgroundColor: "#5761FE", color: "white" }}
                      >
                        Send
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}
              </div>
            )}

            {!lgUp && (
              <>
                <Drawer
                  variant="temporary"
                  anchor="right"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  PaperProps={{
                    sx: {
                      maxWidth: "100%",
                      width: "100%",
                    },
                  }}
                  ModalProps={{
                    keepMounted: true,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "white",
                      padding: "15px",
                    }}
                  >
                    <AvatarGroup sx={{ justifyContent: "flex-end" }}>
                      <Avatar
                        src={companyLogo1}
                        sx={{ width: "30px", height: "30px" }}
                      />
                      <Avatar
                        src={companyLogo2}
                        sx={{ width: "30px", height: "30px" }}
                      />
                    </AvatarGroup>
                    <Typography
                      sx={{
                        color: "#050C46",
                        fontSize: mdUp ? "23px " : "13px",
                        fontWeight: "500",
                        ml: "5px",
                        mr: "14px",
                      }}
                    >
                      Invitation to{" "}
                      <span style={{ fontWeight: "700" }}>
                        {leadInfo?.organization || "Prospect Company"}{" "}
                      </span>{" "}
                      from{" "}
                      <span style={{ fontWeight: "700" }}>
                        {campaignData?.companyName || ""}
                      </span>
                    </Typography>
                    <IconButton
                      aria-label="close"
                      onClick={handleDrawerToggle}
                      style={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Divider
                    sx={{
                      border: "1px solid #D1CBE4",
                    }}
                  ></Divider>
                  <div
                    style={{
                      backgroundColor: "#F8F7FC",
                      padding: "25px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Stack spacing={2}>
                      {/* <Typography
                      sx={{
                        color: "#050C46",
                        fontSize: mdUp ? "20px" : "16px",
                        fontWeight: "700",
                      }}
                    >
                      {campaignData?.companyName || ""} •{" "}
                      {leadInfo?.organization || "Prospect Company"}
                    </Typography> */}
                      <Typography
                        sx={{
                          color: "#050C46",
                          fontSize: mdUp ? "20px" : "16px",
                          fontWeight: "500",
                          margin: "0px 10px",
                          fontFamily: "DM Sans",
                        }}
                      >
                        {/* Prepared exclusively for{" "}
                      {leadInfo?.fname + " " + leadInfo?.lname} at{" "}
                      {leadInfo?.organization || "Prospect Company"} Brands with
                      love */}
                        Hey{" "}
                        <Typography
                          sx={{
                            display: "inline",
                            color: "#050C46",
                            fontSize: mdUp ? "20px" : "16px",
                            fontWeight: "700",
                            fontFamily: "DM Sans",
                          }}
                        >
                          {leadInfo?.fname}
                        </Typography>
                        , I've prepared this invitation exclusively for you.
                      </Typography>
                      <Button
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "8px",
                          fontSize: mdUp ? "18px" : "",
                          border: "1px solid #5761FE",
                          backgroundColor: "#5761FE",
                          color: "white",
                        }}
                        logout
                        variant="contained"
                        onClick={(e) => {
                          scheduleMeet();
                          handleDrawerToggle();
                        }}
                        endIcon={<img src={arrowRight} />}
                      >
                        Book A Meeting With Me
                      </Button>
                    </Stack>
                    <div>
                      {testimonialDispaly && (
                        <div>
                          <Typography
                            sx={{
                              color: "#050C46",
                              fontSize: mdUp ? "18px" : "16px",
                              fontWeight: "500",
                              fontFamily: "DM Sans",
                            }}
                          >
                            See what others are saying about{" "}
                            {campaignData?.companyName}
                          </Typography>
                          <>
                            <Stack
                              spacing={2}
                              sx={{
                                backgroundColor: "white",
                                border: "1px solid white",
                                padding: "20px",
                                margin: "15px 0px",
                                borderRadius: "12px",
                              }}
                            >
                              <div>
                                <img src={Star} />
                                <img src={Star} />
                                <img src={Star} />
                                <img src={Star} />
                                <img src={Star} />
                              </div>
                              <Typography
                                sx={{
                                  color: "#050C46",
                                  fontSize: mdUp ? "16px" : "13px",
                                  fontWeight: "400",
                                  fontFamily: "DM Sans",
                                }}
                              >
                                {testimonials[currentTestimonialIndex]?.text}
                              </Typography>
                              <Stack spacing={1}>
                                <Avatar src={user} />
                                <Typography
                                  style={{
                                    color: "#050C46",
                                    fontSize: mdUp ? "18px" : "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  {
                                    testimonialsName[currentTestimonialIndex]
                                      ?.text
                                  }
                                </Typography>
                                <Typography
                                  style={{
                                    color: "#7E82A5",
                                    fontSize: mdUp ? "18px" : "13px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {
                                    testimonialsCompanyName[
                                      currentTestimonialIndex
                                    ]?.text
                                  }
                                </Typography>
                              </Stack>

                              {testimonials?.length > 1 && (
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box>
                                    <Tooltip
                                      title="Previous"
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton
                                        onClick={handlePrevious}
                                        sx={{
                                          borderRadius: "50%",
                                          margin: 2,
                                          p: 0,
                                          border: "1px solid #EAECF0",
                                          backgroundColor: "white",
                                          color: "#5761FE",
                                        }}
                                      >
                                        <ChevronLeftOutlined />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                  {testimonials.map((testimonial, index) => (
                                    <button
                                      style={{
                                        padding: "5px",
                                        backgroundColor:
                                          index === currentTestimonialIndex
                                            ? "#5761FE"
                                            : "#B3B5C7",
                                        borderRadius: "16px",
                                        border:
                                          index === currentTestimonialIndex
                                            ? "1px solid #5761FE"
                                            : "1px solid #B3B5C7",
                                        margin: 2,
                                      }}
                                      onClick={() =>
                                        setCurrentTestimonialIndex(index)
                                      }
                                    >
                                      <div
                                        style={{
                                          width: "1px",
                                          height: "1px",
                                          borderRadius: "50%",
                                          cursor: "pointer",
                                        }}
                                      ></div>
                                    </button>
                                  ))}
                                  <Box>
                                    <Tooltip title="Next" placement="top" arrow>
                                      <IconButton
                                        onClick={handleNext}
                                        sx={{
                                          borderRadius: "50%",
                                          margin: 2,
                                          p: 0,
                                          border: "1px solid #EAECF0",
                                          backgroundColor: "white",
                                          color: "#5761FE",
                                        }}
                                      >
                                        <ChevronRightOutlined />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Stack>
                              )}
                            </Stack>
                          </>
                        </div>
                      )}
                      {(campaignData && campaignData?.companyWebsite && (
                        <a
                          href={
                            (campaignData && campaignData?.companyWebsite) ||
                            "#"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={visistWebsite}
                        >
                          <Button
                            endIcon={<img src={arrowRight} />}
                            fullWidth
                            style={{
                              backgroundColor: "#2970FF",
                              color: "white",
                              fontSize: "14px",
                            }}
                          >
                            Visit Our Website
                          </Button>
                        </a>
                      )) ||
                        ""}
                    </div>

                    {feedBackOpen && (
                      <Dialog
                        open={feedBackOpen}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth="md"
                      >
                        <DialogTitle
                          sx={{
                            color: "#050C46",
                            fontWeight: "700",
                            fontSize: "22px",
                          }}
                        >
                          Your Feedback
                          <IconButton
                            aria-label="close"
                            onClick={() => setFeedbackOpen(false)}
                            style={{
                              position: "absolute",
                              right: 8,
                              top: 4,
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent>
                          <QuillEditor
                            placeholder="Write something"
                            sx={{ height: 200 }}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => setFeedbackOpen(false)}
                            sx={{ border: "1px solid #ECE9F5" }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={() => { }}
                            sx={{
                              backgroundColor: "#5761FE",
                              color: "white",
                            }}
                          >
                            Send
                          </Button>
                        </DialogActions>
                      </Dialog>
                    )}
                  </div>
                </Drawer>
              </>
            )}

            {!lgUp && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "white",
                    padding: "18px 8px 10px 13px",
                    width: "100%",
                  }}
                >
                  <AvatarGroup sx={{ justifyContent: "flex-end" }}>
                    <Avatar src={companyLogo1} />
                    <Avatar src={companyLogo2} />
                  </AvatarGroup>
                  <Typography
                    sx={{
                      color: "#050C46",
                      fontSize: mdUp ? "22px" : lgUp ? "24px" : "13px",
                      fontWeight: "400",
                      ml: "10px",
                    }}
                  >
                    Invitation to{" "}
                    <span style={{ fontWeight: "700" }}>
                      {leadInfo?.organization || "Prospect Company"}{" "}
                    </span>{" "}
                    from{" "}
                    <span style={{ fontWeight: "700" }}>
                      {campaignData?.companyName || ""}
                    </span>
                  </Typography>
                  <Box component="header" sx={{}}>
                    <Stack>
                      <IconButton onClick={handleDrawerToggle}>
                        <img src={menuIcon} />
                      </IconButton>
                    </Stack>
                  </Box>
                </div>
                {/* <Divider
            sx={{
              border:"1px solid red",
              
            }}
          ></Divider> */}
              </>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "",
                alignItems: "center",
                height: "100%",
                width: "100%",
                pl: "8px",
                pr: "8px",
              }}
            >
              {/* <Box
              sx={{
                display: { xs: "flex", md: "none" },
                justifyContent: "center",
                alignItems: "center",
                pb: 2,
              }}
            >
               <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "18px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  // letterSpacing: "-0.03em",
                  color: "#050C46",
                }}
              >
                {leadInfo?.organization && (
                  <>
                    Invitation to{" "}
                    <span style={{ fontWeight: 700 }}>
                      {leadInfo?.organization || "Prospect Company"}
                    </span>{" "}
                    from{" "}
                  </>
                )}
                <span style={{ fontWeight: 700 }}>
                  {userDetails?.companyName}
                </span>
              </Typography> 
            </Box> */}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  // width: { xs: "100%", sm: "95%", md: "90%", lg: "75%" },
                  width: "100%",
                  justifyContent: "flex-end",
                  pb: { xs: "54px", md: "25px" },
                }}
              >
                <Stack
                  direction="column"
                  id="content-div"
                  ref={myScrollableDivRef}
                  sx={{
                    overflow: { xs: "auto", sm: "unset" },
                    position: "relative",
                    maxHeight: "100%",
                    backgroundColor: "transparent",
                    marginTop: lgUp ? " " : { xs: "5px" },
                    marginBottom: lgUp ? "70px" : { xs: "80px", sm: "0px" },
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: lgUp ? "row" : "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: lgUp ? "70px 7px 0px 0px" : "4px 7px 0px 0px",
                      padding: lgUp ? "12px" : "0px",
                      border: lgUp ? "1px solid white" : "1px solid #ECE9F5",
                      borderRadius: "12px",
                      backgroundColor: lgUp ? "white" : "#ECE9F5",
                      marginBottom: lgUp ? "0px" : "6px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {lgUp && (
                        <>
                          <AvatarGroup sx={{ justifyContent: "flex-end" }}>
                            <Avatar src={companyLogo1} />
                            <Avatar src={companyLogo2} />
                          </AvatarGroup>
                          <Typography
                            sx={{
                              color: "#050C46",
                              fontSize: lgUp ? "24px" : "13px",
                              fontWeight: "400",
                              ml: "10px",
                            }}
                          >
                            Invitation to{" "}
                            <span style={{ fontWeight: "700" }}>
                              {leadInfo?.organization || "Prospect Company"}{" "}
                            </span>{" "}
                            from{" "}
                            <span style={{ fontWeight: "700" }}>
                              {campaignData?.companyName || ""}
                            </span>
                          </Typography>
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        border: "5px solid white",
                        borderRadius: "12px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          border: mdUp ? "none" : "1px solid #ECE9F5",
                          borderRadius: "8px",
                          margin: mdUp ? "7px 7px 6px 0px" : "4px 7px 6px 6px",
                          backgroundColor: lgUp
                            ? " #ECE9F5"
                            : mdUp
                              ? "transparent"
                              : "#ECE9F5",
                          padding: lgUp ? "12px 16px 12px 12px" : "0px",
                        }}
                      >
                        <div>
                          <AvatarGroup
                            max={lgUp ? 6 : 4}
                            sx={{ alignItems: "center" }}
                          >
                            {[
                              avatar1,
                              avatar2,
                              avatar3,
                              avatar4,
                              avatar5,
                              avatar6,
                              avatar3,
                              avatar4,
                              avatar5,
                              avatar6,
                            ].map((avatar, index) => (
                              <Avatar
                                key={index}
                                src={avatar}
                                sx={{
                                  width: lgUp ? "38px" : "33px",
                                  height: lgUp ? "38px" : "33px",
                                  marginLeft: index > 0 ? "-7px" : "0",
                                }}
                              />
                            ))}
                          </AvatarGroup>
                        </div>
                        <Typography
                          sx={{
                            color: "#050C46",
                            fontSize: lgUp ? "16px" : "12px",
                            fontWeight: "500",
                            ml: "10px",
                          }}
                        >
                          others recently booked a meeting
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: lgUp ? "5px 0px" : "0px 0px 6px 0px",
                    }}
                  >
                    {/* <Divider
                    sx={{
                      "&::after": {
                        border: "1px solid red",
                        flex: 1,
                      },
                    }}
                  ></Divider> */}
                    <Typography
                      sx={{
                        border: "1px solid #D1CBE4",
                        backgroundColor: "white",
                        borderRadius: "6px",
                        padding: "10px 15px",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      {currentDay}
                    </Typography>
                    <Divider
                      sx={{
                        " &::before": {
                          borderColor: "red",
                          flex: 1,
                        },
                      }}
                    ></Divider>
                  </div>
                  <Stack
                    id="sticky-header"
                    sx={{
                      width: "100%",
                      pr: "7px",
                      backgroundColor: "rgb(236, 233, 245)",
                      position: "fixed",
                      left: 0,
                      px: "10px",
                      zIndex: 9999,
                      display: {
                        xs: isVideoVisible ? "flex" : "none",
                        sm: "none",
                      },
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "6px",
                    }}
                  >
                    <div
                      style={{
                        border: "5px solid white",
                        borderRadius: "12px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          border: mdUp ? "none" : "1px solid #ECE9F5",
                          borderRadius: "8px",
                          margin: mdUp ? "7px 7px 6px 0px" : "4px 7px 6px 6px",
                          backgroundColor: mdUp ? "transparent" : "#ECE9F5",
                          padding: lgUp ? "12px 16px 12px 12px" : "0px",
                        }}
                      >
                        <div>
                          <AvatarGroup
                            max={lgUp ? 6 : 4}
                            sx={{ alignItems: "center" }}
                          >
                            {[
                              avatar1,
                              avatar2,
                              avatar3,
                              avatar4,
                              avatar5,
                              avatar6,
                              avatar3,
                              avatar4,
                              avatar5,
                              avatar6,
                            ].map((avatar, index) => (
                              <Avatar
                                key={index}
                                src={avatar}
                                sx={{
                                  width: lgUp ? "38px" : "33px",
                                  height: lgUp ? "38px" : "33px",
                                }}
                              />
                            ))}
                          </AvatarGroup>
                        </div>
                        <Typography
                          sx={{
                            color: "#050C46",
                            fontSize: lgUp ? "16px" : "12px",
                            fontWeight: "500",
                            ml: "10px",
                          }}
                        >
                          others recently booked a meeting
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: lgUp ? "10px 0px" : "0px 0px 5px 0px",
                      }}
                    >
                      {/* <Divider
                    sx={{
                      "&::after": {
                        border: "1px solid red",
                        flex: 1,
                      },
                    }}
                  ></Divider> */}
                      <Typography
                        sx={{
                          border: "1px solid #D1CBE4",
                          backgroundColor: "white",
                          borderRadius: "6px",
                          padding: "10px 15px",
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        {currentDay}
                      </Typography>
                      <Divider
                        sx={{
                          " &::before": {
                            borderColor: "red",
                            flex: 1,
                          },
                        }}
                      ></Divider>
                    </div>
                  </Stack>
                  <Stack
                    id="chat-container"
                    ref={myScrollableDivRef}
                    sx={{
                      flexGrow: 1,
                      overflowY: { xs: "unset", sm: "auto" },
                      mb: {
                        xs: lgUp ? "0vh" : "0px",
                        md: lgUp ? "0vh" : "3vh",
                      },
                      // maxHeight: { xs: "86vh", md: "77vh" },
                      maxHeight: { xs: "auto", sm: "86vh", md: "77vh" },
                      // height: "calc(100vh-72px)",
                      boxShadow: 0,
                      /* width */
                      // pr: { xs: 0.5, md: 1 },
                      "&::-webkit-scrollbar": {
                        width: { xs: "4px", md: "10px" },
                        zIndex: 999,
                        // display: "none",
                      },

                      /* Track */
                      "&::-webkit-scrollbar-track": {
                        // boxShadow: "inset 0 0 5px grey",
                        borderRadius: "10px",
                        background: "#6e7d99",
                        // display: "none",
                      },

                      /* Handle */
                      "&::-webkit-scrollbar-thumb": {
                        background: "#1C2536",
                        borderRadius: "5px",
                        // display: "none",
                      },

                      /* Handle on hover */
                      "&::-webkit-scrollbar-thumb:hover": {
                        background: "#1C2536",
                        // display: "none",
                      },
                    }}
                  >
                    {leadInfo && userDetails && chats.length <= 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          height: "616px",
                          width: "100%",
                          backgroundColor: "white",
                          // margin: "0 auto",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <BouncingDotsLoader />
                      </Box>
                    ) : (
                      chats.map((chat, index) => {
                        return (
                          <ChatBlock
                            cronofyToken={cronofyToken}
                            calenderId={calenderId}
                            sub={sub}
                            lgUp={lgUp}
                            midUp={midUp}
                            mdUp={mdUp}
                            userEmail={userEmail}
                            isChatClick={isChatClick}
                            isVideo={isVideo}
                            videoData={videoData}
                            videoRef={myScrollableDivRef}
                            scrollPosition={scrollPosition}
                            setScrollPosition={setScrollPosition}
                            setIsVideo={setIsVideo}
                            handleOpenChat={handleOpenChat}
                            setIsChatClick={setIsChatClick}
                            scheduleMeet={scheduleMeet}
                            userDetails={userDetails}
                            key={index}
                            data={chat}
                            busy={isLoading}
                            handleEgSelect={submitInput}
                            extra={{
                              accessToken,
                              dateTimeOptions,
                              setDateTimeOptions,
                              leadInfo: {
                                email: leadInfo.email,
                                fname: leadInfo.firstName,
                                lname: leadInfo.lastName,
                                organization: leadInfo.organization,
                                ...leadInfo,
                              },
                              campaignData,
                              handlePromptForMeet,
                            }}
                          />
                        );
                      })
                    )}
                    {/* {!promptForMeet.hidden && promptForMeet.show && (
                    <h3 style={{ margin: "12px auto" }}>
                      If you need any further assistance, please feel free to
                      connect with our representative.
                      <a
                        href="#"
                        onClick={(e) => {
                          scheduleMeet();
                        }}
                      >
                        Schedule a meeting
                      </a>
                    </h3>
                  )} */}
                    {userDetails && !hideStreamCont ? (
                      <ChatBlock
                        cronofyToken={cronofyToken}
                        calenderId={calenderId}
                        setScrollPosition={setScrollPosition}
                        sub={sub}
                        userEmail={userEmail}
                        videoRef={myScrollableDivRef}
                        scheduleMeet={scheduleMeet}
                        userDetails={userDetails}
                        data={{
                          isUser: false,
                          text: "",
                          id: "stream-container",
                        }}
                        extra={{
                          accessToken,
                          dateTimeOptions,
                          setDateTimeOptions,
                        }}
                      />
                    ) : (
                      isLoading && (
                        <>
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="center"
                            sx={{ py: 1, mb: 2 }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                opacity: "50%",
                              }}
                            >
                              <BotAvatar />
                            </Box>
                            <Skeleton
                              // style={{ marginTop: "8px" }}
                              variant="rectangular"
                              width="100%"
                              height={50}
                              sx={{
                                backgroundColor: "rgba(87, 97, 254, 0.3)",
                                borderRadius: "8px",
                              }}
                            />
                          </Stack>
                        </>
                      )
                    )}
                  </Stack>
                </Stack>
              </Box>
              {(!isVideo || history.length !== 0) && (
                <Box
                  sx={{
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    marginLeft: lgUp ? "16%" : "7%",
                    pl: { xs: 0, md: "5px" },
                    pr: { xs: 0, md: "10px" },
                    position: "absolute",
                    // top:{}
                    bottom: { xs: "0px", md: "16px" },
                    zIndex: 999,
                  }}
                >
                  <TextField
                    variant="standard"
                    id="user-input"
                    placeholder="Type your message here"
                    sx={{
                      width: lgUp ? "84%" : "93%",
                      py: { xs: 1.5, md: 1 },
                      borderRadius: { xs: "0px", md: "8px" },
                      background: "#FFF",
                      // boxShadow: "0px 4px 14px 0px rgba(28, 37, 54, 0.25)",
                      boxShadow: "0px 1px 2px 0px #1018280D",
                      "& div fieldset": {
                        border: "none",
                      },
                      px: 2,
                      fontSize: "18px",
                      border: "1px solid #ECE9F5",
                      // fontFamily: "Roboto",
                    }}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key !== "Enter" || prompting) return;
                      submitInput();
                    }}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            variant="contained"
                            onClick={() => submitInput()}
                            sx={{
                              borderRadius: "8px",
                              backgroundColor: "#5761FE",
                              color: "#fff",
                              py: 0.9,
                              px: 2,
                              "&:hover": {
                                backgroundColor: "#6269d9",
                              },
                              textAlign: "left",
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: 600,
                              lineHeight: "20px",
                              boxShadow: "0px 1px 2px 0px #1018280D",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Send{" "}
                            <img src={send} style={{ marginLeft: "8px" }} />
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "99.15%",
          }}
        >
          <BotAvatar />
          {/* <Dialog
        open={true}
        // onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth={true}
        maxWidth="lg"
      > */}
          {/* {dialog === "redirect" ? (
          <> */}
          {/* <DialogTitle id="alert-dialog-title">
          Redirecting you to our Sales Agent...
        </DialogTitle> */}
          {/* <DialogContent></DialogContent> */}
          {/* <DialogActions>
          <Button onClick={handleClose}>Back</Button>
          <Button onClick={handleComplete} disabled={loading} autoFocus>
            Submit
          </Button>
        </DialogActions> */}
          {/* </>
        ) : null} */}
          {/* </Dialog> */}
        </Box>
      )}
    </>
  );
};

export default ChatPage;
