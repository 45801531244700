import { useCallback, useEffect, useState } from "react";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import ArrowRightIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import Lock01Icon from "@untitled-ui/icons-react/build/esm/Lock01";
import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  SvgIcon,
  Typography,
  Unstable_Grid2 as Grid,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
} from "@mui/material";
import {
  ArgumentAxis,
  Chart,
  Series,
  Tooltip as XTooltip,
  ValueAxis,
} from "devextreme-react/chart";
import { RouterLink } from "src/components/router-link";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { paths } from "src/paths";
import { EcommerceSalesRevenue } from "src/sections/dashboard/ecommerce/ecommerce-sales-revenue";
import { useLocation } from "react-router";
import { jobsApi } from "src/api/jobs";
import axios from "axios";
import { API_SERVICE_BACKEND } from "src/config";
import { addDays, format, subDays } from "date-fns";
import { prompts } from "src/data/prompts";
import { html as htmlParser, textFromHTML } from "src/utils/parser";
import toast from "react-hot-toast";
const axiosbe = axios.create({
  baseURL: API_SERVICE_BACKEND,
});

export const monthName = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const chartDataInit = {
  cold_leads: [],
  warm_leads: [],
  meetings: [],
  dates: [],
};
const now = Date.now();
const lastNDays = 30;

const Page = () => {
  usePageView();

  const location = useLocation();
  const queryStr = location.search;
  const queryParams = new URLSearchParams(queryStr);
  const email = queryParams.get("email");
  // console.log(email);

  const [campaigns, setCampaigns] = useState([]);
  const [recommendations, setRecommendations] = useState({});
  const [loadingCampaigns, setLoadingCampaigns] = useState(true);
  const [allCampaignsData, setAllCampaignsData] = useState([
    chartDataInit,
    null,
  ]);
  const [indiCampData, setIndiCampData] = useState([]);
  // const [recommendations, setRecommendations] = useState([]);

  const getAugmentRes = useCallback(
    ({ clicks, conversations, meetings, params }) => {
      const dateWiseCount = {};
      let tempFrom = params.from;
      while (true) {
        dateWiseCount[format(tempFrom, "yyyy-MM-dd")] = {
          clicks: 0,
          conversations: 0,
          meetings: 0,
        };
        if (tempFrom.toLocaleDateString() === params.to.toLocaleDateString()) {
          break;
        }
        tempFrom = addDays(tempFrom, 1);
      }

      clicks.forEach((click) => {
        const { _id, count, date } = click;
        dateWiseCount[_id].clicks += count;
      });
      conversations.forEach((conversation) => {
        const { _id, count, date } = conversation;
        dateWiseCount[_id].conversations += count;
      });
      meetings.forEach((meet) => {
        const { _id, count, date } = meet;
        dateWiseCount[_id].meetings += count;
      });

      // console.log(dateWiseCount);
      const augRes = {
        dates: Object.keys(dateWiseCount).map((date) => {
          const [y, m, d] = date.split("-");
          return `${d}/${m}/${y}`;
        }),
        cold_leads: Object.values(dateWiseCount).map(({ clicks }) => {
          return parseInt(clicks);
        }),
        warm_leads: Object.values(dateWiseCount).map(({ conversations }) => {
          return parseInt(conversations);
        }),
        meetings: Object.values(dateWiseCount).map(({ meetings }) => {
          return parseInt(meetings);
        }),
      };
      // console.log(augRes);
      return augRes;
    },
    []
  );

  // clicks, meetings and conversations
  const getChartData = useCallback(
    async (email) => {
      try {
        // const campaigns = await jobsApi.getUserCampaigns(email);
        // console.log(campaigns);
        const campaignIds = campaigns.map(({ _id }) => _id);
        // console.log(campaignIds);
        const params = {
          campaignIds: campaignIds.join(","),
          from: subDays(now, lastNDays),
          to: new Date(now),
        };
        // console.log(params);
        // const clicksConvoMeetRes = await axiosbe.get("/getConvoChartData", {
        //   params,
        // });

        // if (clicksConvoMeetRes.data.error) {
        //   throw new Error(clicksConvoMeetRes.data.error);
        // }
        // console.log(clicksConvoMeetRes.data);
        // const { clicks, conversations, meetings } = clicksConvoMeetRes.data;
        
        // let emailSentChartData = await jobsApi.getChartData(email, null);
        let emailSentChartData = await jobsApi.getChartData(email, null,params);
        const { clicks, conversations, meetings } = emailSentChartData;
        emailSentChartData = emailSentChartData?.dailySends
          ?.filter(({ _id }) => _id !== null)
          .map((obj) => ({
            ...obj,
            _id: format(new Date(obj._id), "dd MMM"),
          }));
        // console.log(emailSentChartData);

        const augRes = getAugmentRes({
          clicks,
          conversations,
          meetings,
          params,
        });
        return { augRes, emailSentChartData };
      } catch (err) {
        console.log("Error fetching chart data!");
        console.log(err);
      }
    },
    [campaigns]
  );

  const getIndividualCampaignStats = useCallback(
    async (email) => {
      try {
        // const campaigns = await jobsApi.getUserCampaigns(email);
        // console.log(campaigns);
        const campaignIds = campaigns.map(({ _id }) => _id);
        // console.log(campaignIds);

        const pendingStatQueries = campaignIds.map(
          async (campaignId, index) => {
            const params = {
              campaignIds: campaignId,
              from: subDays(now, lastNDays),
              to: new Date(now),
            };
            // console.log(params);
            // const clicksConvoMeetRes = await axiosbe.get("/getConvoChartData", {
            //   params,
            // });
            // if (clicksConvoMeetRes.data.error) {
            //   throw new Error(clicksConvoMeetRes.data.error);
            // }
            // console.log(clicksConvoMeetRes.data);

            // const { clicks, conversations, meetings } = clicksConvoMeetRes.data;
            
            // let emailSentChartData = await jobsApi.getChartData(
              //   null,
              //   campaignId
              // );
              let emailSentChartData = await jobsApi.getChartData(
                null,
                campaignId,
                params
              );
              const { clicks, conversations, meetings } = emailSentChartData;
            emailSentChartData = emailSentChartData?.dailySends
              ?.filter(({ _id }) => _id !== null)
              .map((obj) => ({
                ...obj,
                _id: format(new Date(obj._id), "dd MMM"),
              }));
            // console.log(emailSentChartData);

            const stats = await jobsApi.getCampaignStats(campaignId);

            const prompt = prompts.report.recommendation({
              campaign: campaigns[index],
            });
            // console.log(prompt);
            axiosbe
              .post(`/recommendation/${campaignId}`, {
                payload: { prompt },
              })
              .then((recommRes) => {
                // console.log(recommRes);
                setRecommendations((prev) => ({
                  ...prev,
                  [campaignId]:
                    recommRes?.data ||
                    "Error getting recommendation, please try again!",
                }));
              });

            const augRes = getAugmentRes({
              clicks,
              conversations,
              meetings,
              params,
            });
            return {
              augRes,
              emailSentChartData,
              stats,
            };
          }
        );
        const individualStatsArr = await Promise.all(pendingStatQueries);
        // console.log(individualStatsArr);
        return individualStatsArr;
        // .map((stats, index) => ({
        //   campaignId: campaigns[index]?._id,
        //   ...stats,
        // }));
      } catch (err) {
        console.log("Error fetching individual chart data!");
        console.log(err);
      }
    },
    [campaigns]
  );

  const [emptyReport, setEmptyReport] = useState(false);
  useEffect(() => {
    if (!email) return;
    jobsApi
      .getUserCampaigns(email)
      .then((campaigns) => {
        if (!Boolean(campaigns?.length)) {
          setEmptyReport(true);
          toast.error(
            `No campaigns found corresponding to following email: <${email}>`,
            {
              duration: 7000,
              position: "top-center",
            }
          );
        }
        setCampaigns(campaigns);
      })
      .catch((err) => {
        console.log(err);
        setLoadingCampaigns(false);
      });
  }, []);

  useEffect(() => {
    if (!Boolean(campaigns.length)) return;
    (async () => {
      const allCampChartData = await getChartData(email);
      // console.log(allCampChartData);
      setAllCampaignsData(allCampChartData);

      const individialChartDataArr = await getIndividualCampaignStats(email);
      // console.log(individialChartDataArr);
      setIndiCampData(individialChartDataArr || []);
      setLoadingCampaigns(false);
    })();
  }, [campaigns]);

  return (
    <>
      <Seo title="Campaign Stats" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Link
                color="text.primary"
                component={RouterLink}
                href={paths.index}
                sx={{
                  alignItems: "center",
                  display: "inline-flex",
                }}
                underline="hover"
              >
                <SvgIcon sx={{ mr: 1 }}>
                  <ArrowLeftIcon />
                </SvgIcon>
                <Typography variant="subtitle2">Home</Typography>
              </Link>
            </div>
            <Typography variant="h3">Report</Typography>
          </Stack>
          {emptyReport ? (
            <div style={{ marginTop: "50px" }}>
              <center>
                <Typography variant="h5">
                  Insufficient data for generating report.
                </Typography>
                <br />
                <Typography variant="body">
                  Have you created any campaigns?
                </Typography>
              </center>
            </div>
          ) : (
            <Box mt={6}>
              <Grid container spacing={6}>
                <Grid xs={12}>
                  <Stack
                    spacing={{
                      xs: 3,
                      lg: 4,
                    }}
                  >
                    <EcommerceSalesRevenue
                      title="Total engagements"
                      chartSeries={[
                        {
                          name: "Clicks",
                          data: allCampaignsData?.augRes?.cold_leads,
                        },
                        {
                          name: "Conversations",
                          data: allCampaignsData?.augRes?.warm_leads,
                        },
                        {
                          name: "Meetings",
                          data: allCampaignsData?.augRes?.meetings,
                        },
                      ]}
                      categories={allCampaignsData?.augRes?.dates?.map(
                        (date) => {
                          const [d, m, y] = date.split("/");
                          return `${d} ${monthName[parseInt(m) - 1]}`;
                        }
                      )}
                    />
                    {/* <Card>
                    <CardHeader title={`Total emails sent`} />
                    <CardContent>
                      <Chart
                        id={`total-emailsSent`}
                        dataSource={allCampaignsData?.emailSentChartData}
                        height={300}
                      >
                        <Series
                          valueField="count"
                          argumentField="_id"
                          name="Emails Sent"
                          type="bar"
                          color="#9E77ED"
                        />
                        <ArgumentAxis />
                        <ValueAxis />
                        <XTooltip
                          enabled={true}
                          customizeTooltip={({ point: { data } }) => {
                            return { text: data.count };
                          }}
                        />
                      </Chart>
                    </CardContent>
                  </Card> */}
                  </Stack>
                </Grid>
              </Grid>
              <Box mt={8}>
                <Stack direction="row" spacing={2}>
                  <Typography variant="h5">Campaigns</Typography>
                  {loadingCampaigns && <CircularProgress size={25} />}
                </Stack>
                <br />
                <Grid container spacing={1} mt={2}>
                  {indiCampData.map(
                    (
                      {
                        augRes: campStats, // chartData
                        emailSentChartData: emailsSent,
                        stats,
                        // recommendation,
                      },
                      index
                    ) => {
                      // console.log(campStats, emailsSent, stats);
                      const campaign = campaigns[index];
                      const runTimeInDays = +(
                        (Date.now() - new Date(campaign.createdAt).getTime()) /
                        (24 * 60 * 60 * 1000)
                      ).toFixed(2);

                      const rates = {
                        delivered: stats.totalEmailed
                          ? (stats.emailSentStatus.delivered /
                              stats.totalEmailed) *
                            100
                          : 0,
                        opened: stats.emailSentStatus.delivered
                          ? (stats.emailsOpened /
                              stats.emailSentStatus.delivered) *
                            100
                          : 0,
                        clicked: stats.emailsOpened
                          ? (stats.clicks / stats.emailsOpened) * 100
                          : 0,
                        conversations: stats.clicks
                          ? (stats.conversations / stats.clicks) * 100
                          : 0,
                        meetings: stats.conversations
                          ? (stats.meetings / stats.conversations) * 100
                          : 0,
                      };

                      return (
                        <Grid item xs={12} mb={2}>
                          <Card>
                            <CardHeader
                              title={`${index + 1}.  ${campaign.title} (${
                                campaign._id
                              })`}
                            />
                            <CardContent sx={{ pt: 0 }}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Stack
                                    spacing={{
                                      xs: 3,
                                      lg: 4,
                                    }}
                                  >
                                    <EcommerceSalesRevenue
                                      title={`Engagements`}
                                      chartSeries={[
                                        {
                                          name: "Clicks",
                                          data: campStats.cold_leads,
                                        },
                                        {
                                          name: "Conversations",
                                          data: campStats.warm_leads,
                                        },
                                        {
                                          name: "Meetings",
                                          data: campStats.meetings,
                                        },
                                      ]}
                                      categories={campStats.dates?.map(
                                        (date) => {
                                          const [d, m, y] = date.split("/");
                                          return `${d} ${
                                            monthName[parseInt(m) - 1]
                                          }`;
                                        }
                                      )}
                                    />
                                  </Stack>
                                </Grid>
                                {/* <Grid item xs={6}>
                                <Card>
                                  <CardHeader title={`Emails sent`} />
                                  <CardContent>
                                    <Chart
                                      id={`${campaigns[index]?._id}-emailsSent`}
                                      dataSource={emailsSent}
                                      height={300}
                                    >
                                      <Series
                                        valueField="count"
                                        argumentField="_id"
                                        name="Emails Sent"
                                        type="bar"
                                        color="#9E77ED"
                                      />
                                      <ArgumentAxis />
                                      <ValueAxis />
                                      <XTooltip
                                        enabled={true}
                                        customizeTooltip={({
                                          point: { data },
                                        }) => {
                                          return { text: data.count };
                                        }}
                                      />
                                    </Chart>
                                  </CardContent>
                                </Card>
                              </Grid> */}
                                <Grid item xs={12}>
                                  <Card>
                                    <CardHeader title={`Additional Info`} />
                                    <CardContent sx={{ pt: 0 }}>
                                      <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                          <Card>
                                            <CardHeader
                                              title={`Setup Information`}
                                            />
                                            <CardContent sx={{ pt: 0 }}>
                                              <Stack
                                                spacing={{
                                                  xs: 3,
                                                  lg: 4,
                                                }}
                                              >
                                                <pre>
                                                  <b>Campaign Name:</b>
                                                  <br />
                                                  {campaign.title} <br />
                                                  <br />
                                                  <b>Sender Name:</b>
                                                  <br />
                                                  {campaign.senderName} <br />
                                                  <br />
                                                  <b>List of Titles:</b>
                                                  <br />
                                                  {campaign.targets?.join("\n")}
                                                  <br />
                                                  <br />
                                                  <b>List of Industries:</b>
                                                  <br />
                                                  {campaign.industry?.join(
                                                    "\n"
                                                  )}
                                                  <br />
                                                  <br />
                                                  <b>List of Company Size:</b>
                                                  <br />
                                                  {campaign.addFilters?.compSize
                                                    ?.map((range) =>
                                                      range.split(",").join("-")
                                                    )
                                                    .join("\n")}
                                                  <br />
                                                </pre>
                                              </Stack>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Card>
                                            <CardHeader
                                              title={`Campaign Stats`}
                                            />
                                            <CardContent sx={{ pt: 0 }}>
                                              <Stack
                                                spacing={{
                                                  xs: 3,
                                                  lg: 4,
                                                }}
                                              >
                                                <pre>
                                                  <b>Campaign Run Time:</b>{" "}
                                                  {`${runTimeInDays} days`}
                                                  <br />
                                                  <b>
                                                    Total Leads Generated:
                                                  </b>{" "}
                                                  {stats.leadsGen}
                                                  <br />
                                                  <b>Sent:</b>{" "}
                                                  {stats.totalEmailed} <br />
                                                  <b>Delivered:</b>{" "}
                                                  {
                                                    stats.emailSentStatus
                                                      .delivered
                                                  }
                                                  <br />
                                                  <b>Deliverability:</b>{" "}
                                                  {+rates.delivered.toFixed(2)}%{" "}
                                                  <br />
                                                  <b>Opened:</b>{" "}
                                                  {stats.emailsOpened} <br />
                                                  <b>Open Rate:</b>{" "}
                                                  {+rates.opened.toFixed(2)}
                                                  %
                                                  <br />
                                                  <b>Clicked:</b> {stats.clicks}{" "}
                                                  <br />
                                                  <b>Click Rate:</b>{" "}
                                                  {+rates.clicked.toFixed(2)}%{" "}
                                                  <br />
                                                  <b>Conversations:</b>{" "}
                                                  {stats.conversations} <br />
                                                  <b>Conversation Rate:</b>{" "}
                                                  {
                                                    +rates.conversations.toFixed(
                                                      2
                                                    )
                                                  }
                                                  % <br />
                                                  <b>Meetings:</b>{" "}
                                                  {stats.meetings} <br />
                                                  <b>Meeting Rate:</b>{" "}
                                                  {+rates.meetings.toFixed(2)}%
                                                </pre>
                                              </Stack>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Grid>
                                <Grid item xs={12}>
                                  <Card>
                                    <CardHeader title={`Recommendations`} />
                                    <CardContent sx={{ pt: 0 }}>
                                      <div>
                                        {recommendations[campaign._id] ? (
                                          htmlParser(
                                            recommendations[campaign._id]
                                          )
                                        ) : (
                                          <center>
                                            <CircularProgress size={25} />
                                          </center>
                                        )}
                                      </div>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </Box>
            </Box>
          )}
        </Container>
      </Box>
    </>
  );
};

export default Page;
