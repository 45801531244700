export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
};

export const auth0Config = {
  base_url: process.env.REACT_APP_AUTH0_BASE_URL,
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  issuer_base_url: process.env.REACT_APP_AUTH0_ISSUER_BASE_URL,
};

export const firebaseConfig = {
  apiKey: "AIzaSyCCALxX7DpsMsXlPI0euN18muaLerRJEiA",
  authDomain: "leadingly-application.firebaseapp.com",
  projectId: "leadingly-application",
  storageBucket: "leadingly-application.appspot.com",
  messagingSenderId: "210905395537",
  appId: "1:210905395537:web:b3519715bb52ce0614a370",
  measurementId: "G-PP2R56FRJ9",
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID,
};

export const mapboxConfig = {
  apiKey: process.env.REACT_APP_MAPBOX_API_KEY,
};

export const MIN_LEADS = 20000;
export const MAILBOXES_PER_CAMPAIGN = 0; // remember to change this to 10
export const LEADS_PER_PAGE = 50;
export const UPDATE_CHARTS_EVERY_X_SECS = 10;
export const DEFAULT_DAYS_ON_CHART = 7; // @ browse campaign page

// ---------------------------Switch PRODUCTION / LOCAL------------------------------------------------

// Local
let pvtIP;
// pvtIP = "192.168.29.26";
const domain = pvtIP || "localhost";

// export const selfURL = `http://${domain}:3000`;
// export const COMMUNICATION_SERVER = `http://${domain}:8090`;
// export const EMAIL_SERVER = `http://${domain}:8085`;
// export const SOCKET_URL = `http://${domain}:8080`;
// export const API_SERVICE_BACKEND = `http://${domain}:8080/api/v1/main`; // leadingly_testapp_backend
// export const API_SERVICE_BACKEND_2 = `http://${domain}:8090/api/v1/main`; // leadingly_backend
// export const API_SERVICE_BACKEND_3 = `http://${domain}:8081/api/v1/main`; // meetings_b2brocket_server
// export const API_SERVICE_BACKEND_CRONOFY = `${API_SERVICE_BACKEND_3}`;
// export const ADMIN_SERVER = API_SERVICE_BACKEND_2;
// export const EMAIL_SERVICE_AZURE = "http://localhost:8282/api/v1/main";
// export const EMAIL_SERVICE_AZURE_SOCKET = "http://localhost:8282";

// Production
export const selfURL = "https://app.b2brocket.ai";
export const COMMUNICATION_SERVER =
  "https://campaign-incoming-mail-service-azure-devops.azurewebsites.net";
export const EMAIL_SERVER =
  "https://campaign-email-service-azure-devops.azurewebsites.net";
export const SOCKET_URL =
  "https://campaign-app-server-azure-pipeline.azurewebsites.net";
export const API_SERVICE_BACKEND =
  "https://campaign-app-server-azure-pipeline.azurewebsites.net/api/v1/main"; // leadingly_testapp_backend
export const API_SERVICE_BACKEND_2 =
  "https://leadinglyapi.herokuapp.com/api/v1/main"; // leadingly_backend
export const API_SERVICE_BACKEND_3 =
  "https://meeting-b2brocket-api-dd7509e4910e.herokuapp.com/api/v1/main"; // meetings_b2brocket_server

export const API_SERVICE_BACKEND_CRONOFY = `${API_SERVICE_BACKEND_3}`;
export const ADMIN_SERVER = API_SERVICE_BACKEND_2;
export const EMAIL_SERVICE_AZURE =
  "https://leadingly-azure-generate-sample-emails.azurewebsites.net/api/v1/main";
export const EMAIL_SERVICE_AZURE_SOCKET =
  "https://leadingly-azure-generate-sample-emails.azurewebsites.net";

// export const SALES_CHAT = "https://react-sales-agent.vercel.app/chat";
// export const SALES_CHAT = "http://www.brandheroes.io/chat";
// export const COMMUNICATION_SERVER =
//   "https://email-receive-service-54411742d1b6.herokuapp.com";
// export const EMAIL_SERVER =
//   "https://campaign-email-service-16e74e4353e9.herokuapp.com";
// export const SOCKET_URL =
//   "https://campaign-app-server-aa5e033e3a87.herokuapp.com";
// export const API_SERVICE_BACKEND =
//   "https://campaign-app-server-aa5e033e3a87.herokuapp.com/api/v1/main";

// ----------------------------------------------------------------------------------------------------

export const SALES_CHAT = `${selfURL}/chat`;
export const API_HIPPO_VIDEO =
  "https://b2brocket-hippo-video-183e2c663396.herokuapp.com/api/v1/main";
export const API_HIPPO_URL =
  "https://b2brocket-hippo-video-183e2c663396.herokuapp.com/api/v1/main";
export const TINYMCE_EDITOR_API = process.env.REACT_APP_TINYMCE_API;

// Heroku, Netlify deployment
// export const SALES_CHAT = "https://react-sales-agent.vercel.app/chat";
// export const EMAIL_SERVER =
//   "https://campaign-email-service-200f5ab1ce43.herokuapp.com";
// export const SOCKET_URL =
//   "https://campaign-app-server-53f7eb36ce72.herokuapp.com";
// export const API_SERVICE_BACKEND =
//   "https://campaign-app-server-53f7eb36ce72.herokuapp.com/api/v1/main";

// Azure deployment
// export const SALES_CHAT =
//   "https://lively-smoke-0529ef710.3.azurestaticapps.net/chat";
// export const EMAIL_SERVER = "https://campaign-email-service.azurewebsites.net";
// export const SOCKET_URL = "https://campaign-app-server.azurewebsites.net";
// export const API_SERVICE_BACKEND =
//   "https://campaign-app-server.azurewebsites.net/api/v1/main";

// others
// export const SOCKET_URL = "https://leadinglyapi.herokuapp.com";
// export const API_SERVICE_BACKEND = "https://leadinglyapi.herokuapp.com/api/v1/main";

export const cronofy = {
  redirect_uri: selfURL,
  client_id: process.env.REACT_APP_CRONOFY_CLIENT_ID,
  client_secret: process.env.REACT_APP_CRONOFY_CLIENT_SECRET,
  data_center: "us",
  sub: "acc_64a4f90c4359010594c8f1fc",
  scope: "read_write",
  meetPrefs: {
    delayInMS: 5 * 60 * 1000, // 5 mins
    durationInMins: 15,
    allowMeetsUptoXDaysInFuture: 14,
  },
};

export const APP_TIMEZONE =
  process.env.REACT_APP_TIMEZONE || "America/New_York";
