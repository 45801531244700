import axios from "axios";
import { stubFalse } from "lodash";
import {
  API_LOCAL_IP,
  API_SERVICE_BACKEND,
  API_SERVICE_BACKEND_2,
} from "src/config";
import { deepCopy } from "src/utils/deep-copy";
// import { companies, company } from './data';

class JobsApi {
  getCampaignDetails = async (campaignId) => {
    const res = await axios.get(`${API_SERVICE_BACKEND}/filterCampaigns`, {
      params: { _id: campaignId },
    });
    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error(res.data);
    }
  };

  getCampaignStats = async (campaignId) => {
    const res = await axios.get(
      `${API_SERVICE_BACKEND}/getStats/${campaignId}`
    );
    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error(res.data);
    }
  };

  getDashBoardStats = async (campaignId) => {
    const res = await axios.get(
      `${API_SERVICE_BACKEND}/getDashBoard/${campaignId}`
    );
    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error(res.data);
    }
  };

  getUserCampaigns = async (email) => {
    // console.log(email)
    try {
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/userCampaigns/${email}`
      );

      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  getCampaignLeads = async (
    campaignId,
    page,
    limit = 100,
    abortController,
    email,
    sortOptions,
    exportBool
  ) => {
    // console.log(campaignId)
    console.warn(sortOptions);
    const res = await axios.get(
      // `http://192.168.18.60:8080/api/v1/main/campaignLeads/${campaignId}`,
      `${API_SERVICE_BACKEND}/campaignLeads/${campaignId}`,
      {
        params: {
          email,
          page,
          limit,
          sortJSON: encodeURI(JSON.stringify(sortOptions)),
          exportBool,
        },
        signal: abortController?.signal,
      }
    );
    if (res.status === 200) {
      return [...res.data.leads, { count: res.data.count }];
    } else {
      throw new Error(res.data);
    }
  };

  getCampaignLeadsV2 = async (
    campaignId,
    page,
    limit = 100,
    abortController,
    email,
    sortOptions,
    exportBool,
    search
  ) => {
    // console.log(campaignId)
    console.warn(sortOptions);
    const res = await axios.get(
      `${API_SERVICE_BACKEND_2}/campaignLeadsSearch/${campaignId}`,
      {
        params: {
          email,
          page,
          limit,
          sortJSON: encodeURI(JSON.stringify(sortOptions)),
          exportBool,
          search,
        },
        signal: abortController?.signal,
      }
    );
    if (res.status === 200) {
      return { leads: res.data.data.leads, metaData: res.data.data.metaData };
    } else {
      throw new Error(res.data);
    }
  };

  getChartData = async (email, campaignIds, params) => {
    // console.log(campaignId)
    const res = await axios.get(`${API_SERVICE_BACKEND_2}/dashboard-chart`, {
      params: { clientEmail: email, campaignIds, ...(params ? params : {}) },
    });
    if (res.status === 200) {
      return res.data.data;
    } else {
      throw new Error(res.data);
    }
  };

  updateCampaign = async (campaignId, payload) => {
    // console.log(campaignId, payload);
    try {
      const res = await axios.patch(`${API_SERVICE_BACKEND}/updateCampaign`, {
        id: campaignId,
        payload,
      });
      if (res.status === 204) {
        return true;
      }
    } catch (e) {
      console.log(e.message);
    }
    return false;
  };
}

export const jobsApi = new JobsApi();
