import "../style.css";
import {
  Typography,
  Stack,
  Box,
  IconButton,
  Popover,
  Tooltip,
  Divider,
  Grid,
} from "@mui/material";

import { useState, useEffect } from "react";
import { API_SERVICE_BACKEND } from "src/config";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import OpenIcon from "src/components/logos/mailOpened.png";
import ClickIcon from "src/components/logos/mailClicked.png";

const StepEmailContent = ({ content, status }) => {
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const handleOpen1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleOpen2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const id1 = open1 ? "mouse-over-popover1" : undefined;
  const id2 = open2 ? "mouse-over-popover2" : undefined;
  const [emailData, setEmailData] = useState({});
  let sortedClicks = emailData?.clicked;
  let sortedOpens = emailData?.opened;
  useEffect(() => {
    const fetchEmailContent = async () => {
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/getEmailSentInfo?id=${content}`
      );

      if (!res.data) return;
      setEmailData({
        clicked: res.data.clicked,
        opened: res.data.opened,
        email: res.data.email,
        from: res.data.from,
        to: res.data.to,
        sentAt: res.data.createdAt,
      });
      sortedClicks = res?.data?.clicked?.reverse();
      sortedOpens = res?.data?.opened?.reverse();
    };
    if (content) {
      fetchEmailContent();
    }
  }, [content]);
  const getTimeZone = () => {
    const intl = Intl.DateTimeFormat().resolvedOptions().timeZone; // Asia/Tokyo
    moment.tz(intl).zoneName(); // JST
    return moment.tz(intl).zoneAbbr();
  };

  return (
    <>
      <Stack
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!content && status === 2 ? (
          <Typography sx={{ p: "28px" }}>
            The prospect has potentially unsubscribed from the email journey.
          </Typography>
        ) : (
          <Stack sx={{ px: "28px", pt: "18px" }} gap={2}>
            <Grid container>
              <Grid item xs={10}>
                <Stack gap={0.2}>
                  <Typography sx={{ fontWeight: "700" }}>
                    Email sent to {emailData?.to}
                  </Typography>
                  <Typography>From: {emailData?.from}</Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    Sent at:{" "}
                    {moment(emailData?.sentAt).format("MM/DD/YYYY, h:mm A")}{" "}
                    {getTimeZone()}
                  </Typography>               
                </Stack>
              </Grid>
              <Grid item xs={2}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Tooltip title="Clicked" placement="top" arrow>
                    <IconButton
                      onClick={handleOpen1}
                      aria-owns={open1 ? "mouse-over-popover1" : undefined}
                      aria-haspopup="true"
                      sx={{ p: 0 }}
                      // onMouseEnter={handleOpen1}
                      // onMouseLeave={handleClose1}
                    >
                      <img src={ClickIcon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Opened" placement="top" arrow>
                    <IconButton
                      aria-owns={open2 ? "mouse-over-popover2" : undefined}
                      aria-haspopup="true"
                      onClick={handleOpen2}
                      sx={{ p: 0 }}
                      // onMouseEnter={handleOpen2}
                      // onMouseLeave={handleClose2}
                    >
                      <img src={OpenIcon} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
            <Grid xs={12}>
            <Typography sx={{ fontWeight: "700" }}>
                    Subject: {emailData?.email?.subject}
                  </Typography>
            </Grid>

            <Divider sx={{ mb: "-12px" }} />
            <Typography sx={{ mt: 0 }}>
              {" "}
              {ReactHtmlParser(emailData?.email?.html)}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Popover
        sx={{ "& .MuiPopover-paper": { p: 2 } }}
        id={id1}
        open={open1}
        anchorEl={anchorEl1}
        onClose={handleClose1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {emailData?.clicked?.length === 0 ? (
          <Typography>Not clicked</Typography>
        ) : (
          <Stack direction={"column"} gap={1}>
            {sortedClicks?.map((click, index) => (
              <Typography key={index}>
                {moment(click).format("MM/DD/YYYY, h:mm A")}
              </Typography>
            ))}
          </Stack>
        )}
      </Popover>
      <Popover
        sx={{ "& .MuiPopover-paper": { p: 2 } }}
        id={id2}
        open={open2}
        anchorEl={anchorEl2}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {emailData?.opened?.length === 0 ? (
          <Typography>Not opened</Typography>
        ) : (
          <Stack direction={"column"} gap={1}>
            {sortedOpens?.map((open, index) => (
              <Typography key={index}>
                {moment(open).format("MM/DD/YYYY, h:mm A")}
              </Typography>
            ))}
          </Stack>
        )}
      </Popover>
    </>
  );
};

export default StepEmailContent;
