import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { saveAs } from "file-saver";
import DownloadCloud02 from "@untitled-ui/icons-react/build/esm/DownloadCloud02";
// import SearchLgIcon from "@untitled-ui/icons-react/build/esm/SearchLg";
import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import ChevronUp from "@untitled-ui/icons-react/build/esm/ChevronUp";
import VideoCallRoundedIcon from "@mui/icons-material/VideoCallRounded";
import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  SvgIcon,
  Typography,
  CircularProgress,
  useTheme,
  Paper,
  alpha,
  OutlinedInput,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

import LoadingButton from "@mui/lab/LoadingButton";
// import Pagination from "@mui/material/Pagination";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { customersApi } from "src/api/customers";
import { Seo } from "src/components/seo";
import { useMounted } from "src/hooks/use-mounted";
import { usePageView } from "src/hooks/use-page-view";
import { useSelection } from "src/hooks/use-selection";
import { CustomerListSearch } from "src/sections/dashboard/customer/customer-list-search";
// import { CustomerListTable } from "src/sections/dashboard/customer/customer-list-table";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import IconButton from "@mui/material/IconButton";

import {
  ADMIN_SERVER,
  // API_LOCAL_IP,
  API_SERVICE_BACKEND,
  API_SERVICE_BACKEND_2, API_SERVICE_BACKEND_3,
  APP_TIMEZONE,
  COMMUNICATION_SERVER,
  LEADS_PER_PAGE,
} from "../../../config";
import { jobsApi } from "src/api/jobs";
import { useAuth } from "src/hooks/use-auth";
import axios from "axios";
// import { cronofyApi } from "src/api/cronofy";
// import { formattedDate, formattedTime } from "../calendar";
import { textFromHTML } from "src/utils/parser";
import TimeLine from "src/components/logos/timeLine.png";
// import Status from "src/components/logos/status.png";
// import Replies from "src/components/logos/Replies.png";
import { useLocation, useNavigate } from "react-router";
import { paths } from "src/paths";
import { TimelLinePage } from "./TimeLinePage";
import { html as htmlParser } from "src/utils/parser";

// import { useSelector } from "react-redux";
// import { useActionData } from "react-router";
import MessageChatSquareIcon from "src/icons/untitled-ui/duocolor/message-chat-square";
// import { set } from "lodash";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import moment from "moment";

// const regex = new RegExp(
//   /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm
// );

const excludedEmails = [
  "gautamsir076@gmail.com",
  "shiva@leadingly.io",
  "noah@leadingly.io",
  "noah@b2brocket.ai",
];

const useCustomersSearch = () => {
  const [state, setState] = useState({
    filters: {
      query: undefined,
      hasAcceptedMarketing: undefined,
      isProspect: undefined,
      isReturning: undefined,
      clicks: false,
      conversations: false,
      meetings: false,
      unsubscribe: false,
    },
    page: 0,
    rowsPerPage: 5,
    sortBy: "updatedAt",
    sortDir: "desc",
  });

  const handleFiltersChange = useCallback((filters) => {
    // console.log(filters,"filtersfiltersfilters")
    setState((prevState) => ({
      ...prevState,
      filters,
    }));
  }, []);

  const handleSortChange = useCallback((sort) => {
    setState((prevState) => ({
      ...prevState,
      sortBy: sort.sortBy,
      sortDir: sort.sortDir,
    }));
  }, []);

  const handlePageChange = useCallback((event, page) => {
    // // console.log(page, "page");
    setState((prevState) => ({
      ...prevState,
      page,
    }));
  }, []);

  const handleRowsPerPageChange = useCallback((event) => {
    setState((prevState) => ({
      ...prevState,
      rowsPerPage: parseInt(event.target.value, 10),
    }));
  }, []);

  return {
    handleFiltersChange,
    handleSortChange,
    handlePageChange,
    handleRowsPerPageChange,
    state,
  };
};

const useCustomersStore = (searchState) => {
  const isMounted = useMounted();
  const [state, setState] = useState({
    customers: [],
    customersCount: 0,
  });

  const handleCustomersGet = useCallback(async () => {
    try {
      const response = await customersApi.getCustomers(searchState);

      if (isMounted()) {
        setState({
          customers: response.data,
          customersCount: response.count,
        });
      }
    } catch (err) {
      console.error(err);
    }
  }, [searchState, isMounted]);

  useEffect(
    () => {
      handleCustomersGet();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchState]
  );

  return {
    ...state,
  };
};

const useCustomersIds = (customers = []) => {
  return useMemo(() => {
    return customers.map((customer) => customer.id);
  }, [customers]);
};

// const MeetingStatusButtons = ({ id, status }) => {
//   const [activeButton, setActiveButton] = useState(status || false);
//   const handleClick = (buttonNumber) => {
//     setActiveButton(buttonNumber);
//   };
//   const handleSubmit = async () => {
//     try {
//       var dataSend = {
//         _id: id,
//         meeting_status: activeButton,
//       };
//       const responseDataLog = await fetch(
//         `${API_SERVICE_BACKEND_3}/updateusermeetings`,
//         {
//           method: "PUT",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(dataSend),
//         }
//       );
//       if (responseDataLog.status === 200 || responseDataLog.status === 201) {
//         toast.success("Meeting Status Update successfully");
//       }
//     } catch (err) {
//       console.log(err);
//       toast.error("Meeting Status Not Update successfully");
//     }
//   };
//   useEffect(() => {
//     if (activeButton !== status) {
//       handleSubmit();
//     }
//   }, [activeButton]);

//   return (
//     <>
//       <Button
//         variant="outlined"
//         style={{
//           marginTop: "5px",
//           fontSize: "13px",
//           color: activeButton ? "green" : "grey",
//           border: activeButton ? "1px solid green" : "1px solid grey",
//         }}
//         onClick={() => handleClick(true)}
//       >
//         Confirm Attendance
//       </Button>

//       <Button
//         variant="outlined"
//         style={{
//           marginTop: "5px",
//           fontSize: "13px",
//           color: activeButton ? "grey" : "green",
//           border: activeButton ? "1px solid grey" : "1px solid green",
//         }}
//         onClick={() => handleClick(false)}
//       >
//         Mark As No-Show
//       </Button>
//     </>
//   );
// };

const MeetingStatusButtons = ({ id, status }) => {
  const [activeButton, setActiveButton] = useState(status || false);
  const handleClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
    handleSubmit(buttonNumber);
  };
  const handleSubmit = async (buttonNumber) => {
    try {
      var dataSend = {
        _id: id,
        meeting_status: buttonNumber,
      };
      const responseDataLog = await fetch(
        `${API_SERVICE_BACKEND_3}/updateusermeetings`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataSend),
        }
      );
      if (responseDataLog.status === 200 || responseDataLog.status === 201) {
        toast.success("Meeting Status Update successfully");
      }
    } catch (err) {
      console.log(err);
      toast.error("Meeting Status Not Update successfully");
    }
  };
  // useEffect(() => {
  //   console.log("status: ", status);
  //   console.log("activeButton: ", activeButton);
  //   if (activeButton !== status) {
  //     handleSubmit();
  //   }
  // }, [activeButton]);
  return (
    <>
      <Button
        variant="outlined"
        color={"success"}
        style={{ marginTop: "5px", fontSize: "13px" }}
        onClick={() => handleClick(true)}
      >
        Confirm Attendance
      </Button>
      <Button
        variant="outlined"
        color={"error"}
        style={{ marginTop: "5px", fontSize: "13px" }}
        onClick={() => handleClick(false)}
      >
        Mark As No-Show
      </Button>
    </>
  );
};

let controller;

const leadFilters = {
  emailsSent: { label: "Email Sent", active: false },
  views: { label: "Viewed", active: false },
  clicks: { label: "Clicked", active: false },
  responded: { label: "Responded", active: false },
  meetings: { label: "Meeting Scheduled", active: false },
  conversations: { label: "Conversations", active: false },
  unsubscribe: { label: "Unsubscribe", active: false },
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const getServerDateTime = async () => {
  try {
    const responseData = await axios(
      `${API_SERVICE_BACKEND_2}/get-server-date-time`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    return responseData.data;
  } catch (err) {
    console.log(err);
  }
};

const Page = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const customersSearch = useCustomersSearch();
  const customersStore = useCustomersStore(customersSearch.state);
  const customersIds = useCustomersIds(customersStore.customers);
  const customersSelection = useSelection(customersIds);
  const [array, setArray] = useState([]);
  const [clicksArray, setClicksArray] = useState([]);
  const [conversationArray, setConversationArray] = useState([]);
  const [meetingsArray, setMeetingsArray] = useState([]);
  const theme = useTheme();
  const [expand, setExpand] = useState(false);
  const [dialog, setDialog] = useState(null);
  const [open, setOpen] = useState(false);
  const [timelineOpen, setTimelineOpen] = useState(false);
  const [currOpenEmail, setCurrOpenEmail] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isZohoIntegration, setZohoIntegration] = useState(false);
  const [visible, setVisible] = useState(true);
  const [exportingCSV, setExportingCSV] = useState(false);
  const [loadingAILeads, setLoadingAILeads] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [currCampaign, setCurrCampaign] = useState(null);
  const [currCampPage, setCurrCampPage] = useState(1);
  const [currCampIndex, setCurrCampIndex] = useState(0);
  const [leads, setLeads] = useState([]);
  const [timelineData, setTimelineData] = useState({});
  const [filteredLeads, setFilteredLeads] = useState(leads);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(leadFilters);
  const [count, setCount] = useState(-1);
  const [sortBy, setSortBy] = useState({
    creationTime: -1,
    emailsSentTime: 1,
  });
  const [disablePage, setDisablePage] = useState(true);
  const [customerArr, setCustomerArr] = useState([]);
  const [hstransferLoading, setHsTransferLoading] = useState(false);
  const [zhtransferLoading, setZhTransferLoading] = useState(false);
  const [Baropen, setBarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const openSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setBarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setBarOpen(false);
  };

  const query = new URLSearchParams(location.search);
  const campaignId = query.get("campaignId");
  const { user } = useAuth();
  // console.log(filters,"filters")

  const handleOpen = (dialog) => {
    setDialog(dialog);
    setOpen(true);
  };

  const viewSentEmail = (data) => {
    setCurrOpenEmail(data);
    handleOpen("emailView");
  };

  const viewReplies = (data) => {
    // console.log(data);
    navigate(paths.dashboard.conversations, {
      state: { data: { ...data, campaignId } },
    });
  };

  // console.log(customersSearch,"customersSearch")

  usePageView();

  const handleRowSelect = (rowId, data) => {
    // console.log(rowId, "rowId");
    // console.log(data, "data");
    const selectedIndex = selectedItems.indexOf(rowId);
    let newSelected = [];
    let newdataSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, rowId);
      newdataSelected = newdataSelected.concat(selectedData, {
        _id: rowId,
        ...data,
      });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
    }
    setSelectedItems(newSelected);
    // console.log(newdataSelected, "newdataSelected");
    setSelectedData(newdataSelected);
  };

  const handleRowSelectAll = () => {
    // If all rows are already selected, clear the selection; otherwise, select all rows
    const newSelectedRows =
      selectedItems.length === filteredLeads.length
        ? []
        : filteredLeads.map((row) => row._id);

    const newdataSelected =
      selectedData.length === filteredLeads.length
        ? []
        : filteredLeads.map((row) => {
            return {
              _id: row._id,
              firstName: row.firstName,
              lastName: row.lastName,
              email: row.email,
            };
          });

    setSelectedItems(newSelectedRows);
    setSelectedData(newdataSelected);

    // console.log(newSelectedRows, "newSelectedRows");
    // console.log(newdataSelected, "newdataSelected");
    // console.log(selectedItems, "rowsselect");
  };

  const getUserCSVData = async (orgId) => {
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/getusercsvdata/${orgId}`
      );
      const data = await response.json();
      setArray(data?.data?.csv_data || []);
      generateClicksArray(
        data?.data?.csv_data || [],
        data?.data?.filteredDataMetrics || []
      );
      generateConversationArray(
        data?.data?.csv_data || [],
        data?.data?.filteredDataMetrics || []
      );
      generateMeetingsArray(
        data?.data?.csv_data || [],
        data?.data?.filteredDataMetrics || []
      );
      // console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  const generateClicksArray = (array, filteredData) => {
    var newArr = [];
    for (let i = 0; i < filteredData?.length; i++) {
      const { visitorsIndex } = filteredData[i];
      for (let j = 0; j < visitorsIndex?.length; j++) {
        if (array[visitorsIndex[j]] === undefined) {
          // console.log("Undefined at :", visitorsIndex[j]);
          continue;
        }
        newArr.push(array[visitorsIndex[j]]);
      }
    }
    setClicksArray(newArr);
    // console.log("clicksArray: ", newArr);
  };

  const generateConversationArray = (array, filteredData) => {
    var newArr = [];
    for (let i = 0; i < filteredData?.length; i++) {
      const { communicationIndex } = filteredData[i];
      for (let j = 0; j < communicationIndex?.length; j++) {
        if (array[communicationIndex[j]] === undefined) {
          // console.log("Undefined at :", communicationIndex[j]);
          continue;
        }
        newArr.push(array[communicationIndex[j]]);
      }
    }
    setConversationArray(newArr);
    // console.log("conversations array: ", newArr);
  };

  const getUserMeetings = async (email, orgId) => {
    try {
      // setloadingmeetings(true);
      // const response = await fetch(
      //   `${API_SERVICE_BACKEND_3}/getusermeetings/${email}/${orgId}/${page}/${LEADS_PER_PAGE}`
      // );
      const response = await axios.get(
        `${API_SERVICE_BACKEND_3}/getusermeetings/${email}/${orgId}`,
        {
          params: {
            page,
            limit: LEADS_PER_PAGE,
          },
        }
      );
      // console.log("response.data", response.data);
      const data = response.data;
      // console.log(data.data);
      var meetings = data.response.data;
      var metaData = data.response.metaData;
      var tempMeeting = [];
      await Promise.all(
        meetings.map((item) => {
          var dataAdd = {
            title: item?.meeting_title,
            _id: item?._id,
            meeting_status: item?.meeting_status,
            date: item?.meeting_date,
            allDay: false,
            description: item?.meeting_purpose || item?.meeting_with || "",
            link: item?.meeting_link,
            time: item?.meeting_time,
            meeting_date: item?.meeting_date,
            guest: item?.meeting_guest,
            timezone: item?.timezones || item?.meeting_timezone || "",
            createdAt: new Date(item?.createdAt).toUTCString().substring(0, 16),
            prospect_name: item?.prospect_name || "",
            prospectEmail: item?.event_id?.split("/")[1] || "",
          };
          console.log("dataAdd :: ", dataAdd.createdAt);
          tempMeeting.push(dataAdd);
        })
      );
      // console.log(tempMeeting);

      // const campaigns = await jobsApi.getUserCampaigns(user?.email);
      // const eventsPromises = campaigns.map(({ _id: campaignId }) => {
      //   return cronofyApi.readEventsFromSysDB({
      //     campaignId,
      //     projFields: [],
      //   });
      // });
      // const campEvents = await Promise.all(eventsPromises).then((events) =>
      //   events.map(({ events }) => events)
      // );
      // // console.log(campEvents);
      // campEvents.forEach((campaignEvent) => {
      //   campaignEvent.forEach((event) => {
      //     const modEvent = {
      //       allDay: false,
      //       createdAt: formattedDate(new Date(event.createdAt)),
      //       date: formattedDate(new Date(event.start)),
      //       description: event.description,
      //       link: "",
      //       meeting_date: formattedDate(new Date(event.start)),
      //       time: formattedTime(new Date(event.start)),
      //       timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // auto detect user timezone
      //       title: event.summary,
      //     };
      //     // console.log(modEvent);
      //     tempMeeting.push(modEvent);
      //   });
      // });

      // console.log(tempMeeting);
      return { tempMeeting, metaData };
      // console.log(tempMeeting);
      // setuserAllMeetings(tempMeeting);
      // setloadingmeetings(false);
    } catch (err) {
      console.log(err);
    }
  };

  const generateMeetingsArray = async (array, filteredData) => {
    var newArr = [];
    // for (let i = 0; i < filteredData.length; i++) {
    //   const { meetingsIndex } = filteredData[i];
    //   for (let j = 0; j < meetingsIndex.length; j++) {
    //     if (array[meetingsIndex[j]] === undefined) {
    //       console.log("Undefined at :", meetingsIndex[j]);
    //       continue;
    //     }
    //     newArr.push(array[meetingsIndex[j]]);
    //   }
    // }

    // console.log(newArr);

    const email = localStorage.getItem("userEmail");
    const orgId = localStorage.getItem("organization_id");
    const meetings = await getUserMeetings(email, orgId);
    newArr = [...meetings.tempMeeting];

    setMeetingsArray(newArr);
    // console.log("meetings array: ", newArr);
  };

  const arrayToCsv = (data) => {
    const csvRows = [];
    const headers = Object.keys(data[0]);

    // Creating the header row
    csvRows.push(headers.join(","));

    // Creating the data rows
    for (const row of data) {
      const values = headers.map((header) => row[header]);
      csvRows.push(values.join(","));
    }

    return csvRows.join("\n");
  };

  const headersMap = {
    _id: "Lead ID",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    linkedin_url: "LinkedIn URL",
    description: "Description",
    organization: "Organization",
    address: "Address",
    sentAt: "Sent At",
    opened: "Seen",
    campaignId: "Campaign ID",
  };

  const headersMapMeeting = {
    title: "Title",
    description: "Description",
    meeting_date: "Date",
    time: "Time",
    timezone: "Timezone",
    createdAt: "Booked On",
  };

  const arrayToCsvForAiLeads = (data) => {
    const csvRows = [];
    const headers = [
      "_id",
      "firstName",
      "lastName",
      "email",
      "linkedin_url",
      "description",
      "organization",
      // "industry",
      "address",
      // "emailStatus",
      // "emailBody",
      "sentAt",
      "opened",
      "campaignId",
      // "followUpRequired",
    ];
    // Creating the header row
    csvRows.push(headers.map((header) => headersMap[header]).join(","));

    data.forEach((lead) => {
      const { city, state, country } = lead.address ? lead.address : {};
      lead.address = `${city || "UNKNOWN"} | ${state || "UNKNOWN"} | ${
        country || "UNKNOWN"
      }`;

      lead.campaignId = lead.campaignIds[0];
      const formattedSentEmailDate = new Date(lead.sentAt).toLocaleDateString(
        "en-US",
        {
          // weekday: 'short',
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      );
      lead.sentAt = formattedSentEmailDate;

      const leadRow = headers.map((key) => {
        // console.log(lead[key]);
        const processedData = (String(lead[key]) || "").split(",").join(" | ");
        if (key === "emailBody") {
          return textFromHTML(processedData || "").replace("\n", "(*newline)");
        }
        return processedData || "";
      });
      csvRows.push(leadRow.join(","));
    });

    return csvRows.join("\n");
  };

  const arrayToCsvForMeetings = (data) => {
    const csvRows = [];
    const headers = [
      "title",
      "description",
      "meeting_date",
      "time",
      "timezone",
      "createdAt",
    ];
    // Creating the header row
    csvRows.push(headers.map((header) => headersMapMeeting[header]).join(","));

    data.forEach((lead) => {
      const formattedSentEmailDate = new Date(
        lead.meeting_date
      ).toLocaleDateString("en-US", {
        // weekday: 'short',
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
      lead.meeting_date = formattedSentEmailDate;

      const leadRow = headers.map((key) => {
        // console.log(lead[key]);
        const processedData = (String(lead[key]) || "").split(",").join(" | ");
        if (key === "emailBody") {
          return textFromHTML(processedData || "").replace("\n", "(*newline)");
        }
        return processedData || "";
      });
      csvRows.push(leadRow.join(","));
    });

    return csvRows.join("\n");
  };

  const downloadCsvFile = (csvData, filename) => {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, filename);
  };

  const exportCSVData = async () => {
    // if (customersSearch.state.filters.unsubscribe) return;
    // if (
    //   !customersSearch.state.filters.clicks &&
    //   !customersSearch.state.filters.conversations &&
    //   !customersSearch.state.filters.meetings
    // ) {
    //   const csvArrayasCSV = arrayToCsv(array);
    //   downloadCsvFile(csvArrayasCSV, "contactsAll.csv");
    // } else if (customersSearch.state.filters.clicks) {
    //   const csvArrayasCSV = arrayToCsv(clicksArray);
    //   downloadCsvFile(csvArrayasCSV, "contactsClicks(Cold_Leads).csv");
    // } else if (customersSearch.state.filters.conversations) {
    //   const csvArrayasCSV = arrayToCsv(conversationArray);
    //   downloadCsvFile(csvArrayasCSV, "contactsConversation(Warm_Leads).csv");
    // } else if (customersSearch.state.filters.meetings) {
    //   const csvArrayasCSV = arrayToCsv(meetingsArray);
    //   downloadCsvFile(csvArrayasCSV, "contactsMeetings.csv");
    // }
    setExportingCSV(true);
    const currTabSel = Object.entries(filters).find(
      ([key, { active }]) => active
    );
    const currTab = currTabSel?.[0] || "all";
    // console.log(`Fetching leads for "${currTab}" to export as csv`);
    const exportFileName = `leads_${currTab}_${user.id}.csv`;

    const filterKeys = Object.keys(filters);
    const trimmedFilters = {};
    for (let key of filterKeys) {
      if (!filters[key].active) {
        continue;
      }
      trimmedFilters[key] = true;
    }
    if (Object.keys(trimmedFilters)?.length) {
      // fetchFilteredLeads(trimmedFilters, campaignId, controller)
      if (["responded", "meetings"].includes(currTab)) {
        fetchFilteredLeads(trimmedFilters, "all", null, sortBy, true, "").then(
          (data) => {
            if (!data?.metaData) {
              // if (!data?.length) {
              //   setCurrCampIndex((prev) => prev + 1);
              //   setCurrCampPage(1);
              //   return;
              // }
              const lastElem = data?.pop();
              const count = lastElem?.count;
              // console.log(`Export row count for "${currTab}": ${count}`);
              if (!count) {
                data?.push(lastElem);
              }
              // console.log(data.slice(0, 5));
              const csvArrayasCSV = arrayToCsvForAiLeads(data);
              setExportingCSV(false);
              downloadCsvFile(csvArrayasCSV, exportFileName);
            } else {
              const csvArrayasCSV = arrayToCsvForMeetings(data.tempMeeting);
              setExportingCSV(false);
              downloadCsvFile(csvArrayasCSV, exportFileName);
            }
          }
        );
        setExportingCSV(false);
        // console.log(`Export for ${currTab}, coming soon!`);
        return;
      }

      fetchFilteredLeads(trimmedFilters, "all", null, sortBy, true, "").then(
        (data) => {
          if (!data?.metaData) {
            // if (!data?.length) {
            //   setCurrCampIndex((prev) => prev + 1);
            //   setCurrCampPage(1);
            //   return;
            // }
            const lastElem = data?.pop();
            const count = lastElem?.count;
            // console.log(`Export row count for "${currTab}": ${count}`);
            if (!count) {
              data?.push(lastElem);
            }
            // console.log(data.slice(0, 5));
            const csvArrayasCSV = arrayToCsvForAiLeads(data);
            setExportingCSV(false);
            downloadCsvFile(csvArrayasCSV, exportFileName);
          } else {
            const csvArrayasCSV = arrayToCsvForAiLeads(data.leads);
            setExportingCSV(false);
            downloadCsvFile(csvArrayasCSV, exportFileName);
          }
        }
      );
      return;
    }

    await jobsApi
      // .getCampaignLeads(campaignId, currCampPage, LEADS_PER_PAGE, controller)
      .getCampaignLeadsV2(
        null,
        page,
        LEADS_PER_PAGE,
        null,
        user.email,
        sortBy,
        true,
        searchQuery
      )
      .then((data) => {
        // if (!data?.length) {
        //   setCurrCampIndex((prev) => prev + 1);
        //   setCurrCampPage(1);
        //   return;
        // }
        // console.log(campaignId, data);
        // const lastElem = data?.pop();
        const count = data.metaData.count;
        // console.log(count);
        if (!count) {
          data?.push(data.metaData.count);
        }
        const csvArrayasCSV = arrayToCsvForAiLeads(data.leads);
        setExportingCSV(false);
        downloadCsvFile(csvArrayasCSV, exportFileName);
      });
    setExportingCSV(false);
  };

  const setLeadsByExludingEmail = async (leads) => {
    // const excludedEmails = ["gautamsir076@gmail.com", "shiva@leadingly.io", "noah@leadingly.io", "noah@b2brocket.ai"];
    const excludedEmails = [];
    const filteredLeads = leads.filter(
      (lead) => !excludedEmails.includes(lead.email)
    );
    setLeads(filteredLeads);
  };

  const changeLeadsFilters = (key) => {
    setPage(1);
    setLeadsByExludingEmail([]);
    // setCurrCampaign(null);
    // setCurrCampIndex(0);
    // setCurrCampPage(1);
    setFilters((prev) => {
      const temp = { ...prev[key] };
      if ([...Object.keys(filters)].includes(key)) {
        temp.active = !temp.active;
        return { ...leadFilters, [key]: temp };
      }
      return { ...leadFilters };
    });
  };

  useEffect(() => {
    // console.log(filters);
    setCount(-1);
    const currTabSel = Object.entries(filters).find(
      ([key, { active }]) => active
    );
    const currTab = currTabSel?.[0] || "all";
    const visBool = ![
      "responded",
      // "clicks",
      "meetings",
      // "conversations",
    ].includes(currTab);
    setVisible(visBool);
  }, [filters]);

  const fetchFilteredLeads = async (
    filters,
    campaignId,
    controller,
    sortOptions,
    exportBool = false,
    search
  ) => {
    // setLoadingAILeads(true);
    // also need to add conversations filters
    const allIds = campaigns?.map(({ _id }) => _id)?.join(",");
    // console.log(allIds);
    const key = Object.keys(filters)[0];
    // console.log(key)
    if (["responded", "meetings"].includes(key)) {
      let temp;
      if (key === "responded") {
        // update backend to accomodate multiple campaignIds as params
        const res = await axios.post(
          `${COMMUNICATION_SERVER}/api/v1/main/getFilteredEmails`,
          {
            payload: {
              campaignId: campaignId === "all" ? allIds : campaignId,
              page,
              filters,
            },
          },
          { signal: controller?.signal }
        );
        const respondedLeads = res.data;
        temp = respondedLeads.map(({ _id: email, responses }) => email);
      }
      // else if (["clicks", "conversations"].includes(key)) {
      //   // pagination of this filter is pending
      //   const queryParams = {
      //     page,
      //     // limit: Math.floor(
      //     //   LEADS_PER_PAGE / (campaigns ? campaigns.length : 1)
      //     // ),
      //     limit: LEADS_PER_PAGE,
      //     search: search,
      //     // campaignIdListStr: allIds,
      //   };
      //   if (key === "clicks") {
      //     console.log("4", 4);

      //     queryParams["empty"] = true;
      //   }
      //   // console.log(queryParams);
      //   let tempCampClicks = campaigns.map(async ({ _id: campId }) => {
      //     const res = await axios.get(
      //       `${API_SERVICE_BACKEND_2}/searchCampConvo/${campId}?${new URLSearchParams(
      //         queryParams
      //       ).toString()}`,
      //       { signal: controller?.signal }
      //     );
      //     // const data = await res.json();
      //     tempCampClicks = res?.data?.data?.conversations?.map(
      //       ({ leadEmail: email }) => email
      //     );
      //     return tempCampClicks;
      //   });

      //   tempCampClicks = await Promise.all(tempCampClicks);
      //   // console.log(tempCampClicks);

      //   let tempLeadsPromise = tempCampClicks.map(async (temp, index) => {
      //     const campId = campaigns[index]?._id;
      //     const tempLeads = await axios.post(
      //       `${API_SERVICE_BACKEND}/leadInfo`,
      //       {
      //         campaignId: campId,
      //         // campaignIdListStr: allIds,
      //         leadEmail: temp.join(","),
      //       },
      //       { signal: controller?.signal }
      //     );
      //     return tempLeads;
      //   });

      //   tempLeadsPromise = await Promise.all(tempLeadsPromise);
      //   // console.log(tempLeadsPromise);

      //   const payload = [];
      //   tempLeadsPromise.forEach((tempLeads, index) => {
      //     // console.log(tempLeads)
      //     // const campaignId = campaigns[index];
      //     const campaignId = tempLeads?.data?._id;
      //     if (!campaignId) return;
      //     const campaignEmailsList = tempLeads?.data?.lead?.map(
      //       (lead) => lead?.email
      //     );
      //     payload.push({ campaignId, emailsList: campaignEmailsList });
      //   });

      //   const leadsRes = await axios.post(
      //     `${API_SERVICE_BACKEND}/getUsersAllCampConvo?${new URLSearchParams(
      //       queryParams
      //     ).toString()}`,
      //     {
      //       payload,
      //       sortJSON: sortOptions,
      //       exportBool,
      //     },
      //     { signal: controller?.signal }
      //   );
      //   // console.log(leadsRes);

      //   const campEmails = leadsRes.data?.conversations?.map(
      //     ({ leadEmail, campaignId }) => ({ campaignId, leadEmail })
      //   );
      //   // console.log(campEmails);

      //   tempCampClicks = {};
      //   campEmails.forEach(({ campaignId, leadEmail }) => {
      //     if (!tempCampClicks[campaignId]) tempCampClicks[campaignId] = [];
      //     tempCampClicks[campaignId].push(leadEmail);
      //   });
      //   // console.log(tempCampClicks);

      //   tempLeadsPromise = Object.entries(tempCampClicks).map(
      //     async ([campId, temp], index) => {
      //       // const campId = campaigns[index]?._id;
      //       const tempLeads = await axios.post(
      //         `${API_SERVICE_BACKEND}/leadInfo`,
      //         {
      //           campaignId: campId,
      //           // campaignIdListStr: allIds,
      //           leadEmail: temp.join(","),
      //         },
      //         { signal: controller?.signal }
      //       );
      //       return tempLeads;
      //     }
      //   );
      //   tempLeadsPromise = await Promise.all(tempLeadsPromise);

      //   const leads = [];
      //   tempLeadsPromise.forEach((tempLeads, index) => {
      //     // const campaignId = campaigns[index];
      //     const campaignId = tempLeads?.data?._id;

      //     leads.push(
      //       ...tempLeads?.data?.lead?.map((lead) => ({ ...lead, campaignId }))
      //     );
      //   });

      //   const sortedLeads = new Array(leadsRes.data?.count || 0);
      //   campEmails.forEach(({ campaignId, leadEmail }, ix) => {
      //     const index = leads.findIndex(
      //       (lead) => lead.campaignId === campaignId && lead.email === leadEmail
      //     );
      //     sortedLeads[ix] = leads[index];
      //   });

      //   // leads.push({ count: leadsRes.data?.count });
      //   sortedLeads.push({ count: leadsRes.data?.count });

      //   // setLeadsByExludingEmail(tempLeads.data.lead);
      //   // setLoadingAILeads(false);
      //   // return leads;
      //   return sortedLeads;
      // }
      else if (key === "meetings") {
        temp = [];
        const accessToken = user.dbRef?.extra?.accessToken?.access_token;
        // console.log("accessToken: ", accessToken);
        // const events = await cronofyApi.readEvents({ accessToken });
        // const events = await cronofyApi.readEventsFromSysDB({
        //   campaignId,
        //   campaignIdListStr: allIds,
        //   projFields: ["event_id"],
        // });

        const email = localStorage.getItem("userEmail");
        const orgId = localStorage.getItem("organization_id");
        const meetings = await getUserMeetings(email, orgId);
        let newArr = [...meetings.tempMeeting];
        setMeetingsArray(newArr);
        return meetings;

        // temp = events.events
        //   ?.map((event) => {
        //     const { event_id } = event;
        //     const [campId, email] = event_id.split("/");
        //     if (campaignId === "all") {
        //       if (!allIds.includes(campId)) return null;
        //       return email;
        //     }
        //     if (campId !== campaignId) return null;
        //     return email;
        //   })
        //   .filter((email) => email !== null);
      }
      // console.log("temp :: ", temp);
      // // console.log(temp.length);
      // const tempLeads = await axios.post(
      //   // `${API_LOCAL_IP}/leadInfo`,
      //   `${API_SERVICE_BACKEND}/leadInfo`,
      //   {
      //     campaignId,
      //     campaignIdListStr: allIds,
      //     leadEmail: temp.join(","),
      //   },
      //   { signal: controller?.signal }
      // );
      // console.log("tempLeads.data", tempLeads.data);
      // setLeadsByExludingEmail(tempLeads.data.lead);
      // setLoadingAILeads(false);
      // return tempLeads.data.lead;
    } else if (
      [
        "clicks",
        "conversations",
        "emailsSent",
        "views",
        "unsubscribe",
      ].includes(key)
    ) {
      // console.log(filters);
      const res = await axios.post(
        `${API_SERVICE_BACKEND_2}/getFilteredLeads`,
        // `http://192.168.16.41:8080/api/v1/main/getFilteredLeads`,
        {
          payload: {
            campaignId: campaignId,
            campaignIdListStr: allIds,
            page,
            limit: LEADS_PER_PAGE,
            limit: LEADS_PER_PAGE,
            filters,
            sortJSON: sortOptions,
            search: search,
            exportBool,
          },
        },
        {
          signal: controller?.signal,
        }
      );
      // console.log(res);
      // setLoadingAILeads(false);
      if (res.data.status !== 1) {
        // setLeadsByExludingEmail([]);
        return [];
      } else {
        // setLeadsByExludingEmail(res.data);
        return res.data.data;
      }
    }
  };

  // useEffect(() => {
  //   if (!campaigns?.length) return;
  //   if (currCampIndex >= campaigns.length) {
  //     setCurrCampaign(null);
  //   }
  //   setCurrCampaign(campaigns[currCampIndex]);
  // }, [campaigns, currCampIndex]);

  // useEffect(() => {
  //   if (currCampPage <= 0) {
  //     setCurrCampIndex((prev) => {
  //       const newCampIndex = Math.min(0, prev - 1);
  //       const tempCamp = campaigns[newCampIndex];
  //       // console.log(tempCamp);
  //       let tempCurrCampPage;
  //       if (tempCamp) {
  //         const totalLeads = tempCamp?.operationStat?.leadsGen;
  //         tempCurrCampPage = Math.ceil(totalLeads / LEADS_PER_PAGE);
  //       }
  //       setCurrCampPage(tempCurrCampPage);
  //       return newCampIndex;
  //     });
  //   }
  // }, [currCampPage]);

  const totalPageCount = Math.ceil(count / LEADS_PER_PAGE);
  // console.log("LEADS_PER_PAGE: ", LEADS_PER_PAGE);
  // console.log("count: ", count);

  // 1 -> asc, -1 -> desc; assuming binary values

  const changeSort = (field, value) => {
    setSortBy((prev) => ({ ...prev, [field]: value }));
  };

  // useEffect(() => {
  //   console.log(currCampaign);
  // }, [currCampaign]);
  // useEffect(() => {
  //   console.log(currCampIndex, currCampPage);
  // }, [currCampIndex, currCampPage]);

  const changeAugPage = (inc) => {
    setDisablePage(true);
    setPage((prev) => prev + inc);
    // setCurrCampPage((prev) => prev + inc);
  };

  const handleSearchChange = async (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    if (query === "") {
      setFilteredLeads(leads);
    }
  };

  const handleCustomerChecked = (e, data) => {
    if (e.target.checked) {
      customerArr.push(data);
      // console.log("yesssss");
    } else {
      const index = customerArr.findIndex(
        (element) => element._id === data._id
      );
      // console.log(index);
      if (index > -1) {
        customerArr.splice(index, 1);
      }
    }
    setCustomerArr(customerArr);
    // if(customerArr?.length >0){

    // }
  };

  const [exportDisabled, setExportDisabled] = useState({
    hubspot: false,
    zoho: false,
  });
  const handleExportDisabled = (data = {}) => {
    const { key, value } = data;
    setExportDisabled((prev) => ({ ...prev, [key]: value }));
  };

  const headers = new Headers({
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.REACT_APP_HUBSPOT_API_KEY}`,
  });

  const makeHubspotApiRequest = async (contact) => {
    // console.log(contact, "contact going to backend");
    // console.log(user, "user is coming");

    let access_token = user.integrations.hubspot?.access_token;
    let email = user?.email;

    console.log(access_token, "access_token is coming", email);
    const payload = {
      email,
      contact,
      access_token: access_token,
      refresh_token: user.integrations.hubspot?.refresh_token,
      tokenObj: user.integrations.hubspot,
    };
    try {
      if (!access_token) {
        openSnackbar(
          "Please go to settings and connect your HubSpot.",
          "warning"
        );
      }
      const response = await fetch(`${API_SERVICE_BACKEND_2}/exportContacts`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      const resData = await response.json();
      // console.log(response.status);
      if (response.status === 201 && resData.res.results.length > 0) {
        openSnackbar("[Hubspot] Contact created successfully", "success");
        return true;
      } else if (resData.res.results.length == 0) {
        openSnackbar("[Hubspot] Contacts already exits.", "success");
        return true;
      } else {
        const errorData = await response.json();
        console.log(errorData.message);
        openSnackbar("Failed to export Contacts.", "error");
        return false;
      }
    } catch (error) {
      openSnackbar("Failed to export Contacts.", "error");
      return false;
    }
  };

  // const makeHubspotApiRequest = async (contact) => {
  //   const payload = {
  //     contact,
  //     access_token: "COunoczBMRIHAAEAQAAAARikqJ4VIPCa0R0ovoF2MhRB6DdRWafYn9QKtiy3EywXemxSVjowAAAAQQAAAAAAAAAAAAAAAACAAAAAAAAAAAAAIAAAAAAA4AEAAAAAAAAAAAAAABACQhRpNz_vzdWuHGCXOSZdgePL_4zc60oDbmExUgBaAA",
  //     refresh_token: user.integrations.hubspot?.refresh_token,
  //   };
  //   try {
  //     const response = await fetch(`${API_SERVICE_BACKEND}/contacts`, {
  //       method: "POST",
  //       headers: headers,
  //       body: JSON.stringify(payload),
  //     });
  //     if (response.status === 201) {
  //       // Success: Display a success toast
  //       // toast.success(`Contact created successfully`);
  //       return true;
  //     } else {
  //       // Handle other response statuses here, if needed
  //       const errorData = await response.json();
  //       // Show an error toast with the error message
  //       // console.log(errorData.message);
  //       toast.error(`Failed to export '${contact.email}'`);
  //       return false;
  //     }
  //   } catch (error) {
  //     // Error while making the API request: Display an error toast
  //     // console.log("API request error: " + error.message);
  //     toast.error(`Failed to export '${contact.email}'`);
  //     return false;
  //   }
  // };

  const handleContactTransHubspot = async (e, data) => {
    handleExportDisabled({ key: "hubspot", value: true });

    if (selectedItems?.length > 0) {
      let batchUsers = selectedData;
      const contacts = batchUsers.map((user) => ({
        Last_Name: user?.lastName,
        First_Name: user?.firstName,
        email: user?.email,
      }));

      setHsTransferLoading(true);
      const success = await makeHubspotApiRequest(contacts);

      setSelectedItems([]);
      setHsTransferLoading(false);
    } else {
      openSnackbar("Please select contacts", "warning");
    }
    handleExportDisabled({ key: "hubspot", value: false });
  };

  useEffect(() => {
    if (!user) return;
    const tokenZoho = user.integrations.zoho?.access_token;
    if (tokenZoho) {
      setZohoIntegration(true);
    } else {
      setZohoIntegration(false);
    }
  }, [user]);

  const makeZohoBatchUsersRequest = async (contacts) => {
    handleExportDisabled({ key: "zoho", value: true });
    const access_token = user.integrations.zoho?.access_token;
    console.log(user, "user");
    const payload = {
      email: user.email,
      contacts,
      access_token,
      tokenObj: user.integrations.zoho,
    };
    try {
      if (!access_token) {
        openSnackbar("Please go to settings and connect your Zoho.", "warning");
      }
      const response = await fetch(`${API_SERVICE_BACKEND_2}/zoho-contacts`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      if (response.status === 201) {
        // Success: Display a success toast
        // console.log(response);
        const jsonRes = await response.json();

        if (
          user.integrations.zoho?.access_token &&
          jsonRes.data?.access_token
        ) {
          user.integrations.zoho.access_token = jsonRes.data.access_token;
        }
        openSnackbar(`[Zoho] Contact created successfully`, "success");
      } else {
        const errorData = await response.json();
        openSnackbar(`${errorData.message}`, "error");
      }
    } catch (error) {
      openSnackbar(`API request error: ${error.message}`, "error");
    } finally {
      handleExportDisabled({ key: "zoho", value: false });
    }
  };

  const handleContactTransZoho = async () => {
    if (selectedItems?.length > 0) {
      let batchUsers = selectedData;
      const contacts = batchUsers.map((user) => ({
        Last_Name: user?.lastName,
        First_Name: user?.firstName,
        Email: user?.email,
      }));
      setZhTransferLoading(true);
      // console.log("BATCH USERS: ----> ", contacts);
      const success = await makeZohoBatchUsersRequest(contacts);

      setSelectedItems([]);
      setZhTransferLoading(false);
    } else {
      openSnackbar("Please select contacts", "warning");
    }
  };

  // Assuming you have a state variable selectedItems to manage selected items

  const handleClose = () => {
    setOpen(false);
    setDialog(null);
    setCurrOpenEmail(null);
  };

  // console.log("SELECTED ITEMS ------> ", selectedItems);
  const handleCheckboxChange = (item) => {
    const selectedIndex = selectedItems.findIndex(
      (element) => element._id === item._id
    );

    let newSelected = [...selectedItems];

    if (selectedIndex === -1) {
      newSelected = [...selectedItems, item];
    } else {
      newSelected = [
        ...selectedItems.slice(0, selectedIndex),
        ...selectedItems.slice(selectedIndex + 1),
      ];
    }

    setSelectedItems(newSelected);
  };
  const handleSelectAllChange = () => {
    const isAllSelected = !selectAll;

    if (isAllSelected) {
      setSelectedItems([...leads]); // Select all items
    } else {
      setSelectedItems([]); // Deselect all items
    }

    setSelectAll(isAllSelected);
  };

  useEffect(() => {
    if (!user?.email) return;
    jobsApi.getUserCampaigns(user.email).then((data) => {
      setCampaigns(data || []);
      if (!data || data?.length === 0) {
        setLoadingAILeads(false);
      }
      // if (data?.length) {
      //   setCurrCampaign(data[0]);
      // }
    });
  }, [user]);

  useEffect(() => {
    // console.log(filters);
    setCount(-1);
    const currTabSel = Object.entries(filters).find(
      ([key, { active }]) => active
    );
    const currTab = currTabSel?.[0] || "all";
    const visBool = ![
      "responded",
      // "clicks",
      "meetings",
      // "conversations",
    ].includes(currTab);
    setVisible(visBool);
  }, [filters]);

  useEffect(
    () => {
      (async () => {
        if (campaigns?.length === 0) return;
        setLoadingAILeads(true);
        // if (!currCampaign) {
        //   setLeadsByExludingEmail([]);
        //   setDisablePage(false);
        //   return;
        // }
        controller?.abort();
        controller = new AbortController();
        // const campaignId = currCampaign?._id;

        const filterKeys = Object.keys(filters);
        const trimmedFilters = {};
        for (let key of filterKeys) {
          if (!filters[key].active) {
            continue;
          }
          trimmedFilters[key] = true;
        }
        if (Object.keys(trimmedFilters)?.length) {
          // fetchFilteredLeads(trimmedFilters, campaignId, controller)
          await fetchFilteredLeads(
            trimmedFilters,
            "all",
            controller,
            sortBy,
            false,
            searchQuery
          )
            .then((data) => {
              console.log("data::::::: ", data);
              if (!data?.metaData?.count) {
                // if (!data?.length) {
                //   setCurrCampIndex((prev) => prev + 1);
                //   setCurrCampPage(1);
                //   return;
                // }
                if (data?.length) {
                  const lastElem = data?.pop();
                  var count = lastElem?.count;
                  if (count === undefined) {
                    data?.push(lastElem);
                  }
                  setCount(count);
                } else {
                  setCount(data?.metaData?.count);
                }
                // if(trimmedFilters.messages){

                // }

                setLeadsByExludingEmail(data?.length > 0 ? data : []);
                setDisablePage(false);
                setLoadingAILeads(false);
              } else {
                setCount(data.metaData.count);
                setLeadsByExludingEmail(
                  data?.leads?.length > 0 ? data?.leads : []
                );
                setDisablePage(false);
                setLoadingAILeads(false);
              }
            })
            .catch((error) => {
              console.log("error", error);
            });
          return;
        }
        await jobsApi
          // .getCampaignLeads(campaignId, currCampPage, LEADS_PER_PAGE, controller)
          .getCampaignLeadsV2(
            null,
            page,
            LEADS_PER_PAGE,
            controller,
            user.email,
            sortBy,
            false,
            searchQuery
          )
          .then((data) => {
            // if (!data?.length) {
            //   setCurrCampIndex((prev) => prev + 1);
            //   setCurrCampPage(1);
            //   return;
            // }
            // console.log(campaignId, data);
            setCount(data.metaData.count);
            setLeadsByExludingEmail(data.leads);
            setDisablePage(false);
            setLoadingAILeads(false);
          })
          .catch((error) => {
            console.log("error", error);
          });

        setLoadingAILeads(false);
      })();
    },
    // [currCampaign, currCampPage, filters]
    [page, filters, campaigns, sortBy, searchQuery]
  );

  useEffect(() => {
    var orgId = localStorage.getItem("organization_id");
    getUserCSVData(orgId);
  }, []);

  useEffect(() => {
    setFilteredLeads(leads);
  }, [leads]);

  // console.log(filters.emailsSent.active, "filtersfiltersfiltersfilters");
  // console.log(filteredLeads, "filteredLeads");
  // console.log("SELECTED ITEMS ------> ", selectedItems);
  // console.log(customersSearch.state, "customersSearch");
  // console.log("loadingAILeads", loadingAILeads);
  console.log("campaigns", campaigns);
  console.log("state", customersSearch.state);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {dialog === "emailView" ? (
          <>
            <DialogTitle id="alert-dialog-title">
              Email sent to {currOpenEmail?.name} ({currOpenEmail?.address})
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {htmlParser(currOpenEmail?.email)}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
              {/* <Button onClick={showWarning} disabled={loading} autoFocus>
                Continue
              </Button> */}
            </DialogActions>
          </>
        ) : null}
      </Dialog>
      <Seo title="Leadingly" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4,
          backgroundColor: alpha(theme.palette.primary.main, 0.05),
        }}
      >
        <Container
          maxWidth="xl"
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
        >
          <Stack spacing={4}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={4}
            >
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <Typography variant="h4">Leads</Typography>
                <Stack alignItems="center" direction="row" spacing={1}>
                  {/* <Button
                    color="inherit"
                    size="small"
                    startIcon={(
                      <SvgIcon>
                        <Upload01Icon />
                      </SvgIcon>
                    )}
                  >
                    Import
                  </Button> */}
                  {true && (
                    <LoadingButton
                      loading={exportingCSV}
                      sx={{
                        backgroundColor: alpha(theme.palette.primary.main, 0.8),
                        color: theme.palette.primary.contrastText,
                        "&:hover": {
                          backgroundColor: theme.palette.primary.main,
                        },
                      }}
                      loadingPosition="start"
                      startIcon={
                        <SvgIcon>
                          <DownloadCloud02 />
                        </SvgIcon>
                      }
                      size="small"
                      // variant="outlined"
                      onClick={exportCSVData}
                      disabled={hstransferLoading || zhtransferLoading}
                    >
                      Export
                    </LoadingButton>
                  )}
                  <Snackbar
                    open={Baropen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                  >
                    <Alert
                      onClose={handleSnackbarClose}
                      severity={snackbarSeverity}
                    >
                      {snackbarMessage}
                    </Alert>
                  </Snackbar>

                  {user.integrations.hubspot ? (
                    <LoadingButton
                      loading={hstransferLoading}
                      disabled={
                        exportDisabled.hubspot ||
                        selectedItems.length === 0 ||
                        zhtransferLoading
                      }
                      color="primary"
                      // onClick={handleDrawerOpen}
                      startIcon={
                        <SvgIcon>
                          <MessageChatSquareIcon />
                        </SvgIcon>
                      }
                      variant="contained"
                      onClick={(e) =>
                        handleContactTransHubspot(
                          e,
                          customersSearch.state.filters.meetings
                        )
                      }
                    >
                      Transfer to HubSpot
                    </LoadingButton>
                  ) : (
                    <Chip
                      label="Hubspot Not Connected"
                      style={{ height: "6vh", borderRadius: "10px" }}
                    />
                  )}
                  {isZohoIntegration ? (
                    <LoadingButton
                      loading={zhtransferLoading}
                      disabled={
                        exportDisabled.zoho ||
                        selectedItems.length === 0 ||
                        hstransferLoading
                      }
                      color="primary"
                      startIcon={
                        <SvgIcon>
                          <MessageChatSquareIcon />
                        </SvgIcon>
                      }
                      variant="contained"
                      onClick={(e) =>
                        handleContactTransZoho(
                          e,
                          customersSearch.state.filters.meetings
                        )
                      }
                    >
                      Transfer to Zoho
                    </LoadingButton>
                  ) : (
                    <Chip
                      label="Zoho Not Connected"
                      style={{ height: "6vh", borderRadius: "10px" }}
                    />
                  )}
                </Stack>
              </Stack>

              {/* <Stack
                alignItems="center"
                direction="row"
                spacing={3}
              >
                <Button
                  startIcon={(
                    <SvgIcon>
                      <PlusIcon />
                    </SvgIcon>
                  )}
                  variant="contained"
                >
                  Add
                </Button>
              </Stack> */}
            </Stack>
            {/* <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
              spacing={1}
              sx={{
                px: 3,
                py: 2,
              }}
            >
              <IconButton
                disabled={disablePage || page === 1}
                onClick={() => changeAugPage(-1)}
              >
                <SvgIcon fontSize="small">
                  <ChevronLeftIcon />
                </SvgIcon>
              </IconButton>
              <Typography variant="caption">{page}</Typography>
              <IconButton
                disabled={disablePage || leads.length < LEADS_PER_PAGE}
                onClick={() => changeAugPage(1)}
              >
                <SvgIcon fontSize="small">
                  <ChevronRightIcon />
                </SvgIcon>
              </IconButton>
            </Stack> */}

            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              sx={{
                p: 0,
                borderBottom: `1px solid ${theme.palette.grey[200]}`,
              }}
            >
              <CustomerListSearch
                onFiltersChange={customersSearch.handleFiltersChange}
                onAIFiltersChange={changeLeadsFilters}
                onSortChange={customersSearch.handleSortChange}
                sortBy={customersSearch.state.sortBy}
                sortDir={customersSearch.state.sortDir}
              />
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              width="100%"
              my={2}
            >
              <OutlinedInput
                onChange={handleSearchChange}
                placeholder="Search contacts"
                startAdornment={
                  <InputAdornment position="start">
                    <SvgIcon>
                      <SearchMdIcon />
                    </SvgIcon>
                  </InputAdornment>
                }
                value={searchQuery}
              />
              {visible && (
                <div>
                  <Typography variant="caption" display="block" gutterBottom>
                    Date wise sort
                  </Typography>
                  <Button
                    color="inherit"
                    size="small"
                    variant="outlined"
                    sx={{
                      border: `1px solid ${theme.palette.grey[300]}`,
                      backgroundColor: "white",
                      "&:hover": {
                        backgroundColor: "white",
                        border: `1px solid ${theme.palette.primary.main}`,
                      },
                    }}
                    startIcon={
                      <SvgIcon>
                        {sortBy.creationTime === 1 ? (
                          <ArrowDownwardIcon />
                        ) : (
                          <ArrowUpwardIcon />
                        )}
                      </SvgIcon>
                    }
                    onClick={() => {
                      changeSort(
                        "creationTime",
                        sortBy.creationTime === 1 ? -1 : 1
                      );
                    }}
                  >
                    {/* <span className="d-block" style={{ fontSize: 10 }}>
                      Date wise sort
                    </span> */}
                    {sortBy.creationTime === 1
                      ? "Oldest First"
                      : "Latest First"}
                  </Button>
                </div>
              )}
            </Stack>
            <Card
              sx={{
                border: `1px solid ${theme.palette.grey[300]}`,
              }}
            >
              {!loadingAILeads ? (
                campaigns?.length > 0 ? (
                  <>
                    {false ? (
                      // {customersSearch.state.filters.unsubscribe ? (
                      <Typography sx={{ ml: 2, mb: 2 }}></Typography>
                    ) : customersSearch.state.filters.meetings ? (
                      <TableContainer sx={{ mt: 0 }} component={Paper}>
                        <Table
                          sx={{ minWidth: "100%" }}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow
                              sx={{
                                "& .MuiTableCell-root": {
                                  backgroundColor: "#FFFFFF",
                                  borderBottom: `1px solid ${theme.palette.grey[300]}`,
                                  color: theme.palette.neutral[500],
                                },
                              }}
                            >
                              <TableCell align="left">#</TableCell>
                              <TableCell align="left">TITLE</TableCell>
                              <TableCell align="left">PROSPECT NAME</TableCell>
                              <TableCell align="left">DESCRIPTION</TableCell>
                              <TableCell align="left">DATE</TableCell>
                              <TableCell align="left">TIME</TableCell>
                              <TableCell align="left">TIMEZONE</TableCell>
                              <TableCell align="left">PROSPECT EMAIL</TableCell>
                              <TableCell align="left">MEETING STATUS</TableCell>
                              <TableCell align="center">JOIN MEET</TableCell>
                              <TableCell align="left">BOOKED ON</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {customersSearch.state.filters.meetings &&
                              // page === 1 &&
                              meetingsArray.map((item, index) => {
                                const formattedSentEmailDate = new Date(
                                  item.meeting_date
                                ).toLocaleDateString("en-US", {
                                  weekday: "short",
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                });

                                return (
                                  <TableRow
                                    // key={item.id}
                                    key={index}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                      "& .MuiTableCell-root": {
                                        backgroundColor: "#FFFFFF",
                                        borderBottom: `1px solid ${theme.palette.grey[300]}`,
                                        color: theme.palette.neutral[900],
                                        fontWeight: "500",
                                      },
                                    }}
                                  >
                                    {/* <TableCell>{leads.length + index + 1}</TableCell> */}
                                    <TableCell align="left">
                                      #{LEADS_PER_PAGE * (page - 1) + index + 1}
                                    </TableCell>
                                    <TableCell align="left">
                                      {item.title}
                                    </TableCell>
                                    <TableCell align="left">
                                      {item.prospect_name}
                                    </TableCell>
                                    <TableCell align="left">
                                      {item.description}
                                    </TableCell>
                                    <TableCell align="left">
                                      {formattedSentEmailDate}
                                    </TableCell>
                                    <TableCell align="left">
                                      {item.time}
                                    </TableCell>
                                    <TableCell align="left">
                                      {item.timezone}
                                    </TableCell>
                                    <TableCell align="left">
                                      {item.prospectEmail}
                                    </TableCell>
                                    <TableCell align="left">
                                      <MeetingStatusButtons
                                        id={item?._id}
                                        status={item?.meeting_status}
                                      />
                                    </TableCell>
                                    {item.link ? (
                                      <TableCell align="center">
                                        <Button
                                          variant="outlined"
                                          onClick={() => {
                                            window.open(item.link, "_blank");
                                          }}
                                        >
                                          Join
                                          <VideoCallRoundedIcon fontSize="large" />
                                        </Button>
                                      </TableCell>
                                    ) : (
                                      <TableCell align="center" />
                                    )}
                                    <TableCell align="left">
                                      {item.createdAt}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <>
                        <TableContainer sx={{ mt: 0 }} component={Paper}>
                          <Table
                            sx={{ Width: "100%" }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow
                                sx={{
                                  "& .MuiTableCell-root": {
                                    backgroundColor: "#FFFFFF",
                                    borderBottom: `1px solid ${theme.palette.grey[300]}`,
                                    color: theme.palette.neutral[500],
                                  },
                                }}
                              >
                                <TableCell>
                                  {/*  <Checkbox
                                    checked={
                                      selectedItems.length ===
                                      filteredLeads.length
                                    }
                                    onChange={handleRowSelectAll}
                                  />*/}
                                </TableCell>
                                <TableCell align="left">#</TableCell>
                                <TableCell
                                  align="left"
                                  width={
                                    filters.emailsSent.active ? "0%" : "10%"
                                  }
                                >
                                  FIRST NAME
                                </TableCell>
                                <TableCell align="left">LAST NAME</TableCell>
                                <TableCell align="left">TITLE</TableCell>
                                <TableCell align="left">COMPANY</TableCell>
                                <TableCell align="left">Location</TableCell>
                                <TableCell align="left">EMAIL</TableCell>
                                {!filters.emailsSent.active && (
                                  <TableCell
                                    align="left"
                                    sx={{ minWidth: "100px" }}
                                  >
                                    Date
                                  </TableCell>
                                )}

                                {/* <TableCell align="left" width="25%">
                                PHONE
                              </TableCell> */}
                                {/* <TableCell align="left" >AGENT</TableCell> */}
                                {/* <TableCell align="left" width={filters.emailsSent.active ? "0%" :"20%"} >CONVERSATION</TableCell>
                              <TableCell align="left" width={filters.emailsSent.active ? "0%" :"10%"}>
                                ENGAGED DATE
                              </TableCell> */}
                                {filters.emailsSent.active && (
                                  <>
                                    <TableCell align="left">TimeLine</TableCell>
                                    <TableCell align="left">
                                      Email Status
                                    </TableCell>
                                    {/* <TableCell align="left" >
                                Replies
                              </TableCell> */}
                                  </>
                                )}
                                {/* <TableCell></TableCell> */}
                                {/* {!filters.emailsSent.active && <TableCell align="left">Status</TableCell>} */}
                                <TableCell></TableCell>

                                {/* <TableCell align="center">Title</TableCell>
                              <TableCell align="center">Company</TableCell>
                              <TableCell align="center">Location</TableCell>
                              <TableCell align="center">Email</TableCell>
                              {/* <TableCell align="center">
                                Primary Phone
                              </TableCell> */}
                                {/*<TableCell align="left">Profile</TableCell>
                              <TableCell align="left">Industry</TableCell>
                              <TableCell align="left">
                                Activity Type
                              </TableCell> */}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {true ? (
                                filteredLeads?.map(
                                  (
                                    {
                                      _id,
                                      emailStatus,
                                      firstName,
                                      lastName,
                                      email,
                                      emailBody,
                                      description,
                                      linkedin_url,
                                      organization,
                                      industry,
                                      address,
                                      campaignIds,
                                      sentAt,
                                      nextFollowUpStep,
                                      enrolledFor,
                                      emailSeqStatus,
                                    },
                                    index
                                  ) => {
                                    if (excludedEmails.includes(email)) return;
                                    const { city, state, country } = address
                                      ? address
                                      : {};
                                    // const formattedSentEmailDate = new Date(
                                    //   sentAt
                                    // ).toLocaleDateString("en-US", {
                                    //   weekday: "short",
                                    //   year: "numeric",
                                    //   month: "short",
                                    //   day: "numeric",
                                    // });
                                    // console.log("sentAt :::::::::", sentAt);
                                    const formattedSentEmailDate =
                                      sentAt !== undefined
                                        ? new Date(sentAt).toLocaleDateString(
                                            "en-US",
                                            {
                                              weekday: "short",
                                              year: "numeric",
                                              month: "short",
                                              day: "numeric",
                                              hour: "2-digit",
                                              minute: "2-digit",
                                            }
                                          )
                                        : "-";
                                    return (
                                      <>
                                        <TableRow
                                          key={_id}
                                          sx={{
                                            borderBottom: `1px solid ${theme.palette.grey[300]}`,

                                            "&:last-child td, &:last-child th":
                                              {
                                                border: 0,
                                              },
                                            "& .MuiTableCell-root": {
                                              borderBottom: `1px solid ${theme.palette.grey[300]}`,
                                            },
                                          }}
                                        >
                                          <TableCell>
                                            <Checkbox
                                              checked={
                                                selectedItems.indexOf(_id) !==
                                                -1
                                              }
                                              onChange={() =>
                                                handleRowSelect(_id, {
                                                  firstName,
                                                  lastName,
                                                  email,
                                                })
                                              }
                                            />
                                          </TableCell>
                                          <TableCell>
                                            #
                                            {LEADS_PER_PAGE * (page - 1) +
                                              index +
                                              1}
                                            {/* {currCampIndex + 1}. */}
                                            {/* {LEADS_PER_PAGE * (currCampPage - 1) +
                                      index +
                                      1} */}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            sx={{
                                              color: theme.palette.neutral[900],
                                              fontWeight: "500",
                                            }}
                                          >
                                            {firstName}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            sx={{
                                              color: theme.palette.neutral[900],
                                              fontWeight: "500",
                                            }}
                                          >
                                            {lastName}
                                          </TableCell>
                                          {/* <TableCell align="left" width="25%">
                                          xxx-xxx-xxxx
                                        </TableCell> */}
                                          <TableCell align="left">
                                            {description}
                                          </TableCell>
                                          <TableCell align="left">
                                            {organization}
                                          </TableCell>

                                          <TableCell align="left">{`${
                                            city == null ? "" : `${city},`
                                          } ${
                                            state == null ? "" : `${state},`
                                          } ${
                                            country == null ? "" : country
                                          }`}</TableCell>
                                          <TableCell align="left">
                                            {email}
                                          </TableCell>
                                          {!filters.emailsSent.active && (
                                            <TableCell align="left">
                                              {formattedSentEmailDate}
                                            </TableCell>
                                          )}
                                          {/* <TableCell align="left">
                                                    {Boolean(linkedin_url) ? (
                                                      <a
                                                        href={linkedin_url}
                                                        target="_blank"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          x="0px"
                                                          y="0px"
                                                          width="36"
                                                          height="36"
                                                          viewBox="0 0 48 48"
                                                        >
                                                          <path
                                                            fill="#0288D1"
                                                            d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                                                          ></path>
                                                          <path
                                                            fill="#FFF"
                                                            d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                                                          ></path>
                                                        </svg>
                                                      </a>
                                                    ) : (
                                                      <Typography variant="h6">
                                                        -
                                                      </Typography>
                                                    )}
                                                  </TableCell> */}
                                          {/* <TableCell align="left">
                                        {campaignIds[0] || "#"}
                                        </TableCell> */}
                                          {/* {filters.emailsSent.active && ( */}
                                          <>
                                            {filters.emailsSent.active && (
                                              <TableCell>
                                                <IconButton
                                                  size="small"
                                                  onClick={() => {
                                                    setTimelineData({
                                                      nextFollowUpStep,
                                                      enrolledFor,
                                                      emailSeqStatus,
                                                      emailStatus,
                                                    });
                                                    setTimelineOpen(true);
                                                  }}
                                                >
                                                  <img src={TimeLine} />
                                                </IconButton>
                                              </TableCell>
                                            )}

                                            {filters.emailsSent.active && (
                                              <TableCell>
                                                {emailStatus !== 0 ? (
                                                  <>
                                                    <IconButton
                                                      size="small"
                                                      color={
                                                        emailStatus === 1
                                                          ? "success"
                                                          : "error"
                                                      }
                                                      onClick={() =>
                                                        viewSentEmail({
                                                          name: `${firstName} ${lastName}`,
                                                          email: emailBody,
                                                          address: email,
                                                        })
                                                      }
                                                    >
                                                      {emailStatus === 1 ? (
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          x="0px"
                                                          y="0px"
                                                          width="36"
                                                          height="36"
                                                          viewBox="0 0 48 48"
                                                        >
                                                          <path
                                                            fill="#c8e6c9"
                                                            d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                                                          ></path>
                                                          <path
                                                            fill="#4caf50"
                                                            d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"
                                                          ></path>
                                                        </svg>
                                                      ) : (
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          x="0px"
                                                          y="0px"
                                                          width="36"
                                                          height="36"
                                                          viewBox="0 0 48 48"
                                                        >
                                                          <linearGradient
                                                            id="GCWVriy4rQhfclYQVzRmda_hRIvjOSQ8I0i_gr1"
                                                            x1="9.812"
                                                            x2="38.361"
                                                            y1="9.812"
                                                            y2="38.361"
                                                            gradientUnits="userSpaceOnUse"
                                                          >
                                                            <stop
                                                              offset="0"
                                                              stop-color="#f44f5a"
                                                            ></stop>
                                                            <stop
                                                              offset=".443"
                                                              stop-color="#ee3d4a"
                                                            ></stop>
                                                            <stop
                                                              offset="1"
                                                              stop-color="#e52030"
                                                            ></stop>
                                                          </linearGradient>
                                                          <path
                                                            fill="url(#GCWVriy4rQhfclYQVzRmda_hRIvjOSQ8I0i_gr1)"
                                                            d="M24,4C12.955,4,4,12.955,4,24s8.955,20,20,20s20-8.955,20-20C44,12.955,35.045,4,24,4z M24,38	c-7.732,0-14-6.268-14-14s6.268-14,14-14s14,6.268,14,14S31.732,38,24,38z"
                                                          ></path>
                                                          <linearGradient
                                                            id="GCWVriy4rQhfclYQVzRmdb_hRIvjOSQ8I0i_gr2"
                                                            x1="6.821"
                                                            x2="41.08"
                                                            y1="6.321"
                                                            y2="40.58"
                                                            gradientTransform="translate(-.146 .354)"
                                                            gradientUnits="userSpaceOnUse"
                                                          >
                                                            <stop
                                                              offset="0"
                                                              stop-color="#f44f5a"
                                                            ></stop>
                                                            <stop
                                                              offset=".443"
                                                              stop-color="#ee3d4a"
                                                            ></stop>
                                                            <stop
                                                              offset="1"
                                                              stop-color="#e52030"
                                                            ></stop>
                                                          </linearGradient>
                                                          <polygon
                                                            fill="url(#GCWVriy4rQhfclYQVzRmdb_hRIvjOSQ8I0i_gr2)"
                                                            points="13.371,38.871 9.129,34.629 34.629,9.129 38.871,13.371"
                                                          ></polygon>
                                                        </svg>
                                                      )}
                                                    </IconButton>
                                                    {sentAt && (
                                                      <Typography variant="subtitle2">
                                                        {formattedSentEmailDate}
                                                      </Typography>
                                                    )}
                                                  </>
                                                ) : (
                                                  <img
                                                    width="36"
                                                    height="36"
                                                    src="https://img.icons8.com/plumpy/24/minus--v1.png"
                                                    alt="minus--v1"
                                                  />
                                                )}
                                              </TableCell>
                                            )}
                                            {/* <TableCell align="center">
                                    <IconButton
                                      size="small"
                                      color="info"
                                      onClick={() =>
                                        viewReplies({
                                          firstName,
                                          lastName,
                                          email,
                                          emailBody,
                                          description,
                                          linkedin_url,
                                          organization,
                                          industry,
                                          address,
                                        })
                                      }
                                    >
                                      <img src={Replies}/>
                                    </IconButton>
                                  </TableCell> */}
                                          </>

                                          <TableCell align="center">
                                            <Button
                                              sx={{
                                                backgroundColor: alpha(
                                                  theme.palette.primary.main,
                                                  0.1
                                                ),
                                                minWidth: "30px",
                                                minHeight: "30px",
                                                p: "6px",
                                                borderRadius: "8px",
                                                color: "#000000",
                                                "&:hover": {
                                                  backgroundColor: alpha(
                                                    theme.palette.primary.main,
                                                    0.2
                                                  ),
                                                },
                                              }}
                                              onClick={() => {
                                                if (expand === _id) {
                                                  setExpand(false);
                                                } else {
                                                  setExpand(_id);
                                                }
                                              }}
                                            >
                                              {expand && expand === _id ? (
                                                <ChevronUp />
                                              ) : (
                                                <ChevronDown />
                                              )}
                                            </Button>
                                          </TableCell>

                                          {/* <TableCell align="center">
                                        {description}
                                      </TableCell>
                                      <TableCell align="center">
                                        {organization}
                                      </TableCell>
                                      <TableCell align="center">{`${city}, ${state}, ${country}`}</TableCell>
                                      <TableCell align="center">
                                        {Boolean(linkedin_url) ? (
                                          <a
                                            href={linkedin_url}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              x="0px"
                                              y="0px"
                                              width="36"
                                              height="36"
                                              viewBox="0 0 48 48"
                                            >
                                              <path
                                                fill="#0288D1"
                                                d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                                              ></path>
                                              <path
                                                fill="#FFF"
                                                d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                                              ></path>
                                            </svg>
                                          </a>
                                        ) : (
                                          <Typography variant="h6">
                                            -
                                          </Typography>
                                        )}
                                      </TableCell>
                                      <TableCell align="center">
                                        {industry}
                                      </TableCell>
                                      <TableCell align="center">NA</TableCell> */}
                                        </TableRow>
                                        {expand && expand === _id && (
                                          <TableRow>
                                            <TableCell colSpan="9">
                                              <Table
                                                sx={{
                                                  backgroundColor: alpha(
                                                    theme.palette.primary.main,
                                                    0.05
                                                  ),
                                                  borderRadius: 1,
                                                  border: `1px solid ${alpha(
                                                    theme.palette.primary.main,
                                                    0.1
                                                  )}`,
                                                }}
                                              >
                                                <TableHead>
                                                  <TableRow
                                                    sx={{
                                                      "& .MuiTableCell-root": {
                                                        color:
                                                          theme.palette
                                                            .neutral[500],
                                                      },
                                                    }}
                                                  >
                                                    <TableCell align="left">
                                                      Title
                                                    </TableCell>
                                                    <TableCell align="left">
                                                      Company
                                                    </TableCell>
                                                    <TableCell align="left">
                                                      Location
                                                    </TableCell>

                                                    <TableCell align="left">
                                                      Profile
                                                    </TableCell>
                                                    <TableCell align="left">
                                                      Industry
                                                    </TableCell>
                                                    <TableCell align="left">
                                                      Activity Type
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  <TableRow
                                                    key={_id}
                                                    sx={{
                                                      "&:last-child td, &:last-child th":
                                                        {
                                                          border: 0,
                                                        },
                                                      "& .MuiTableCell-root": {
                                                        color:
                                                          theme.palette
                                                            .neutral[900],
                                                        fontWeight: "500",
                                                      },
                                                    }}
                                                  >
                                                    <TableCell align="left">
                                                      {description}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                      {organization}
                                                    </TableCell>
                                                    <TableCell align="left">{`${city}, ${state}, ${country}`}</TableCell>

                                                    <TableCell align="left">
                                                      {Boolean(linkedin_url) ? (
                                                        <a
                                                          href={linkedin_url}
                                                          target="_blank"
                                                        >
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            x="0px"
                                                            y="0px"
                                                            width="36"
                                                            height="36"
                                                            viewBox="0 0 48 48"
                                                          >
                                                            <path
                                                              fill="#0288D1"
                                                              d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                                                            ></path>
                                                            <path
                                                              fill="#FFF"
                                                              d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                                                            ></path>
                                                          </svg>
                                                        </a>
                                                      ) : (
                                                        <Typography variant="h6">
                                                          -
                                                        </Typography>
                                                      )}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                      {industry}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                      NA
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      </>
                                    );
                                  }
                                )
                              ) : (
                                <div style={{ margin: "auto" }}>
                                  <CircularProgress />
                                </div>
                              )}
                              {/* {!customersSearch.state.filters.clicks &&
                          !customersSearch.state.filters.conversations &&
                          // !customersSearch.state.filters.meetings &&
                          page === 1 &&
                          array.map((item, index) => (
                            <TableRow
                              key={item.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>{leads.length + index + 1}</TableCell>
                              {Object.values(item).map((val) => (
                                <TableCell align="center">
                                  {regex.test(val) ? (
                                    <Tooltip title="View linkedin profile">
                                      <a href={val} target="_blank">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          x="0px"
                                          y="0px"
                                          width="36"
                                          height="36"
                                          viewBox="0 0 48 48"
                                        >
                                          <path
                                            fill="#0288D1"
                                            d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                                          ></path>
                                          <path
                                            fill="#FFF"
                                            d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                                          ></path>
                                        </svg>
                                      </a>
                                    </Tooltip>
                                  ) : (
                                    <>{val}</>
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        {customersSearch.state.filters.clicks &&
                          page === 1 &&
                          clicksArray.map((item, index) => (
                            <TableRow
                              key={item.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>{leads.length + index + 1}</TableCell>
                              {Object.values(item).map((val) => (
                                <TableCell align="center">
                                  {regex.test(val) ? (
                                    <Tooltip title="View linkedin profile">
                                      <a href={val} target="_blank">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          x="0px"
                                          y="0px"
                                          width="36"
                                          height="36"
                                          viewBox="0 0 48 48"
                                        >
                                          <path
                                            fill="#0288D1"
                                            d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                                          ></path>
                                          <path
                                            fill="#FFF"
                                            d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                                          ></path>
                                        </svg>
                                      </a>
                                    </Tooltip>
                                  ) : (
                                    <>{val}</>
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        {customersSearch.state.filters.conversations &&
                          page === 1 &&
                          conversationArray.map((item, index) => (
                            <TableRow
                              key={item.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>{leads.length + index + 1}</TableCell>
                              {Object.values(item).map((val) => (
                                <TableCell align="center">
                                  {regex.test(val) ? (
                                    <Tooltip title="View linkedin profile">
                                      <a href={val} target="_blank">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          x="0px"
                                          y="0px"
                                          width="36"
                                          height="36"
                                          viewBox="0 0 48 48"
                                        >
                                          <path
                                            fill="#0288D1"
                                            d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                                          ></path>
                                          <path
                                            fill="#FFF"
                                            d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                                          ></path>
                                        </svg>
                                      </a>
                                    </Tooltip>
                                  ) : (
                                    <>{val}</>
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))} */}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )}
                    {/* <CustomerListTable
                count={customersStore.customersCount}
                items={customersStore.customers}
                onDeselectAll={customersSelection.handleDeselectAll}
                onDeselectOne={customersSelection.handleDeselectOne}
                onPageChange={customersSearch.handlePageChange}
                onRowsPerPageChange={customersSearch.handleRowsPerPageChange}
                onSelectAll={customersSelection.handleSelectAll}
                onSelectOne={customersSelection.handleSelectOne}
                page={customersSearch.state.page}
                rowsPerPage={customersSearch.state.rowsPerPage}
                selected={customersSelection.selected}
              /> */}
                    {/* {customersSearch.state.filters.meetings === true ? (
                      ""
                    ) : ( */}
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      p={3}
                    >
                      <Typography
                        sx={{
                          color: theme.palette.neutral[900],
                          fontWeight: "500",
                        }}
                      >
                        Page {page} of {totalPageCount}
                      </Typography>
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          onClick={() => {
                            if (page > 1) {
                              setPage(page - 1);
                            }
                          }}
                          disabled={page === 1}
                          startIcon={<ChevronLeftIcon />}
                          variant="outlined"
                          sx={{
                            borderColor: theme.palette.grey[300],
                            color: "#000000",
                          }}
                        >
                          Previous
                        </Button>
                        <Button
                          onClick={() => {
                            if (page < totalPageCount) {
                              setPage(page + 1);
                            }
                          }}
                          disabled={page === totalPageCount}
                          endIcon={<ChevronRightIcon />}
                          variant="outlined"
                          sx={{
                            borderColor: theme.palette.grey[300],
                            color: "#000000",
                          }}
                        >
                          Next
                        </Button>
                      </Stack>
                    </Stack>
                    {/* )} */}
                  </>
                ) : (
                  <h1 style={{ textAlign: "center" }}>No Campaigns Found</h1>
                )
              ) : (
                <div style={{ paddingBlock: "20px" }}>
                  <center>
                    <CircularProgress />
                  </center>
                </div>
              )}
            </Card>
            {/* <Stack alignItems="center">
              <Pagination
                shape="rounded"
                count={Math.ceil(count / LEADS_PER_PAGE)}
                page={page}
                onChange={(e, value) => {
                  setPage(value);
                }}
              />
            </Stack> */}
          </Stack>
        </Container>
        {timelineOpen && (
          <TimelLinePage
            open={timelineOpen}
            setTimelineOpen={setTimelineOpen}
            timelineData={timelineData}
          />
        )}
      </Box>
    </>
  );
};

export default Page;
