export const INDUSTRY_OPTIONS = [
  // "Accounting",
  // "Airlines/Aviation",
  // "Agriculture",
  // "Alternative Dispute Resolution",
  // "Alternative Medicine",
  // "Animation",
  // "Apparel/Fashion",
  // "Architecture/Planning",
  // "Arts/Crafts",
  // "Automotive",
  // "Aviation/Aerospace",
  // "Banking/Mortgage",
  // "Biotechnology/Greentech",
  // "Broadcast Media",
  // "Building Materials",
  // "Business Supplies/Equipment",
  // "Capital Markets/Hedge Fund/Private Equity",
  // "Chemicals",
  // "Civic/Social Organization",
  // "Civil Engineering",
  // "Commercial Real Estate",
  // "Computer Games",
  // "Computer Hardware",
  // "Computer Networking",
  // "Computer Software/Engineering",
  // "Computer/Network Security",
  // "Construction",
  // "Consumer Electronics",
  // "Consumer Goods",
  // "Consumer Services",
  // "Cosmetics",
  // "Dairy",
  // "Defense/Space",
  // "Design",
  // "E-Learning",
  // "Education Management",
  // "Electrical/Electronic Manufacturing",
  // "Entertainment/Movie Production",
  // "Environmental Services",
  // "Events Services",
  // "Executive Office",
  // "Facilities Services",
  // "Farming",
  // "Financial Services",
  // "Fine Art",
  // "Fishery",
  // "Food Production",
  // "Food/Beverages",
  // "Fundraising",
  // "Furniture",
  // "Gambling/Casinos",
  // "Glass/Ceramics/Concrete",
  // "Government Administration",
  // "Government Relations",
  // "Graphic Design/Web Design",
  // "Health/Fitness",
  // "Higher Education/Acadamia",
  // "Hospital/Health Care",
  // "Hospitality",
  // "Human Resources/HR",
  // "Import/Export",
  // "Individual/Family Services",
  // "Industrial Automation",
  // "Information Services",
  // "Information Technology/IT",
  // "Insurance",
  // "International Affairs",
  // "International Trade/Development",
  // "Internet",
  // "Investment Banking/Venture",
  // "Investment Management/Hedge Fund/Private Equity",
  // "Judiciary",
  // "Law Enforcement",
  // "Law Practice/Law Firms",
  // "Legal Services",
  // "Legislative Office",
  // "Leisure/Travel",
  // "Library",
  // "Logistics/Procurement",
  // "Luxury Goods/Jewelry",
  // "Machinery",
  // "Management Consulting",
  // "Maritime",
  // "Market Research",
  // "Marketing/Advertising/Sales",
  // "Mechanical or Industrial Engineering",
  // "Media Production",
  // "Medical Equipment",
  // "Medical Practice",
  // "Mental Health Care",
  // "Military Industry",
  // "Mining/Metals",
  // "Motion Pictures/Film",
  // "Museums/Institutions",
  // "Music",
  // "Nanotechnology",
  // "Newspapers/Journalism",
  // "Non-Profit/Volunteering",
  // "Oil/Energy/Solar/Greentech",
  // "Online Publishing",
  // "Other Industry",
  // "Outsourcing/Offshoring",
  // "Package/Freight Delivery",
  // "Packaging/Containers",
  // "Paper/Forest Products",
  // "Performing Arts",
  // "Pharmaceuticals",
  // "Philanthropy",
  // "Photography",
  // "Plastics",
  // "Political Organization",
  // "Primary/Secondary Education",
  // "Printing",
  // "Professional Training",
  // "Program Development",
  // "Public Relations/PR",
  // "Public Safety",
  // "Publishing Industry",
  // "Railroad Manufacture",
  // "Ranching",
  // "Real Estate/Mortgage",
  // "Recreational Facilities/Services",
  // "Religious Institutions",
  // "Renewables/Environment",
  // "Research Industry",
  // "Restaurants",
  // "Retail Industry",
  // "Security/Investigations",
  // "Semiconductors",
  // "Shipbuilding",
  // "Sporting Goods",
  // "Sports",
  // "Staffing/Recruiting",
  // "Supermarkets",
  // "Telecommunications",
  // "Textiles",
  // "Think Tanks",
  // "Tobacco",
  // "Translation/Localization",
  // "Transportation",
  // "Utilities",
  // "Venture Capital/VC",
  // "Veterinary",
  // "Warehousing",
  // "Wholesale",
  // "Wine/Spirits",
  // "Wireless",
  // "Writing/Editing",
  // "Online Media",
  "Agriculture",
  "Crops",
  "Farming Animals & Livestock",
  "Fishery & Aquaculture",
  "Ranching",
  "Accounting & Accounting Services",
  "Auctions",
  "Business Services - General",
  "Call Centers & Business Centers",
  "Career Planning",
  "Career",
  "Commercial Printing",
  "Debt Collection",
  "Design",
  "Event Services",
  "Facilities Management & Services",
  "Food Service",
  "Fraud Detection",
  "Geography & Positioning",
  "Human Resources & Staffing",
  "Information Services",
  "Management Consulting",
  "Marketing & Advertising",
  "Multimedia & Graphic Design",
  "Outsourcing",
  "Sales",
  "Security & Investigations Products & Services",
  "Staffing & Recruiting",
  "Translation & Localization",
  "Writing & Editing",
  "Cities Towns & Municipalities - General",
  "Communities",
  "Local",
  "Parking",
  "Public Safety",
  "Architecture Engineering & Design",
  "Construction - General",
  "Mechanical Engineering",
  "Mechanical or Industrial Engineering",
  "Car & Truck Rental",
  "Child Care",
  "Consumer Services - General",
  "Funeral Homes & Funeral Related Services",
  "Hair Salons",
  "Laundry & Dry Cleaning Services",
  "Photography",
  "Privacy",
  "Travel Agencies & Services",
  "Veterinary Care",
  "Wedding",
  "Cultural - General",
  "Fine Arts",
  "Libraries",
  "Museums & Art Galleries",
  "Performing Arts",
  "Colleges & Universities",
  "E-learning",
  "Education - General",
  "Higher Education",
  "K-12 Schools",
  "Professional Training & Coaching",
  "Training",
  "Electricity & Energy",
  "Environmental Services",
  "Forestry",
  "Natural Resources",
  "Nuclear",
  "Oil & Gas Exploration & Services",
  "Renewables & Environment",
  "Utilities",
  "Water Energy & Waste Treatment",
  "Energy, Utilities & Waste Treatment - General",
  "Banking",
  "Credit Cards & Transaction Processing",
  "Finance - General",
  "Financial Services",
  "Investment Banking",
  "Venture Capital & Private Equity",
  "Corrections Facilities",
  "Government - General",
  "International Affairs",
  "Law Enforcement",
  "Law Firms & Legal Services",
  "Military",
  "Public Policy",
  "Biotechnology",
  "Clinical Trials",
  "Drug Manufacturing & Research",
  "Drug Stores & Pharmacies",
  "Emergency Medical Transportation & Services",
  "Hospitals & Healthcare",
  "Medical Practice",
  "Medicine",
  "Mental Health Care",
  "Pharmaceuticals",
  "Psychology",
  "Healthcare - General",
  "Amusement Parks Arcades & Attractions",
  "Cruise Lines",
  "Elder & Disabled Care",
  "Entertainment",
  "Fitness & Dance Facilities",
  "Gambling & Casinos",
  "Gaming",
  "Hospitality - General",
  "Hotels and Resorts",
  "Lodging & Resorts",
  "Movie Theaters",
  "Recreation",
  "Restaurants",
  "Sports",
  "Zoos & National Parks",
  "Aerospace & Defense",
  "Apparel & Fashion",
  "Appliances",
  "Automotive",
  "Boats & Submarines",
  "Building Materials",
  "Business Supplies & Equipment",
  "Chemicals",
  "Cleaning Products",
  "Computer Hardware",
  "Consumer Electronics",
  "Consumer Goods",
  "Cosmetics Beauty Supply & Personal Care Products",
  "Dairy",
  "Electronics & Electronics Manufacturing",
  "Flash Storage",
  "Food & Beverages",
  "Furniture",
  "Glass & Concrete",
  "Hand Power and Lawn-care Tools",
  "Health & Nutrition Products",
  "Health Wellness & Fitness",
  "Household Goods",
  "Industrial Machinery Equipment & Automation",
  "Lumber & Wood Production",
  "Luxury Goods & Jewelry",
  "Manufacturing - General",
  "Maritime",
  "Medical Devices & Equipment",
  "Miscellaneous Building Materials",
  "Network Security Hardware & Software",
  "Office Products",
  "Paper & Forest Products",
  "Personal Computers & Peripherals",
  "Pet Products",
  "Petrochemicals",
  "Plastics & Packaging & Containers",
  "Plumbing & HVAC Equipment",
  "Power Conversion & Protection Equipment",
  "Semiconductor & Semiconductor Equipment",
  "Shipbuilding",
  "Telecommunication Equipment",
  "Test & Measurement Equipment",
  "Textiles & Apparel",
  "Tires & Rubber",
  "Tobacco",
  "Toys & Games",
  "Wine & Spirits",
  "Wire & Cable",
  "Adult",
  "Animation",
  "Broadcasting & Media",
  "Classifieds",
  "Ebook & Audiobooks",
  "Ecommerce & Internet Retail",
  "Film Video & Media Production & Services",
  "Internet & Digital Media",
  "Internet-related Services",
  "Music & Music Related Services",
  "Newspapers & News Services",
  "Podcast",
  "Public Relations & Communication",
  "Publishing",
  "Radio Stations",
  "Search Engines & Internet Portals",
  "Social Media",
  "Software Engineering",
  "Streaming",
  "Television Stations",
  "Video Chat & Online Messaging",
  "Media & Internet - General",
  "Metals & Mining - General",
  "Mining",
  "Non-Profit & Charitable Organizations & Foundations",
  "Organizations - General",
  "Parenting & Youth",
  "Philanthropy",
  "Religious Organizations",
  "Commercial Real Estate",
  "Real Estate - General",
  "AR/VR",
  "Artificial Intelligence & Machine Learning",
  "Cryptocurrency",
  "Data & Big Data",
  "Drones",
  "Genetics",
  "Mobile",
  "Nanotechnology",
  "Neuroscience",
  "Quantum Computing",
  "Research - General",
  "Robotics",
  "Sharing Economy",
  "Technology",
  "Think Tanks",
  "Department Stores Shopping Centers & Superstores",
  "Flowers Gifts & Specialty",
  "Footwear",
  "Gas Stations Convenience & Liquor Stores",
  "Gift & Gift Products",
  "Grocery & Supermarkets",
  "Home Improvement & Hardware",
  "Optometry & Eyewear",
  "Rental - Other - Furniture A/V Construction & Industrial Equipment",
  "Rental - Video & DVD",
  "Retail - General",
  "Sporting & Recreational Equipment",
  "Vitamins Supplements & Health Stores",
  "Record, Video & Book Stores",
  "Jewelry & Watch Retail",
  "Consumer Electronics & Computers Retail",
  "Apparel & Accessories Retail",
  "Business Intelligence Software",
  "Cloud Software",
  "Computer Games",
  "Content & Collaboration Software",
  "Customer Relationship Management Software(CRM)",
  "Database & File Management Software",
  "Enterprise Resource Planning Software(ERP)",
  "Software & Technical Consulting",
  "Software - General",
  "Storage & System Management Software",
  "Supply Chain Management Software",
  "Cable & Satellite",
  "Telecommunications - General",
  "Wireless",
  "Import & Export & Trade",
  "Logistics & Supply Chain",
  "Warehousing",
  "Wholesale",
  "Airlines & Aviation",
  "Delivery",
  "Freight & Logistics Services",
  "Rail Bus & Taxi",
  "Shipping",
  "Transportation - General",
  "Trucking Moving & Storage",
];

export const industryTypes = [
  //1
  { title: "Agriculture & Fishing" },
  { title: "Agriculture", grpId: "Agriculture & Fishing" },
  { title: "Crops", grpId: "Agriculture & Fishing" },
  { title: "Farming Animals & Livestock", grpId: "Agriculture & Fishing" },
  { title: "Fishery & Aquaculture", grpId: "Agriculture & Fishing" },
  { title: "Ranching", grpId: "Agriculture & Fishing" },
  //2
  { title: "Business Services" },
  { title: "Accounting & Accounting Services", grpId: "Business Services" },
  { title: "Auctions", grpId: "Business Services" },
  { title: "Business Services - General", grpId: "Business Services" },
  { title: "Call Centers & Business Centers", grpId: "Business Services" },
  { title: "Career Planning", grpId: "Business Services" },
  { title: "Career", grpId: "Business Services" },
  { title: "Commercial Printing", grpId: "Business Services" },
  { title: "Debt Collection", grpId: "Business Services" },
  { title: "Design", grpId: "Business Services" },
  { title: "Event Services", grpId: "Business Services" },
  { title: "Facilities Management & Services", grpId: "Business Services" },
  { title: "Food Service", grpId: "Business Services" },
  { title: "Fraud Detection", grpId: "Business Services" },
  { title: "Geography & Positioning", grpId: "Business Services" },
  { title: "Human Resources & Staffing", grpId: "Business Services" },
  { title: "Information Services", grpId: "Business Services" },
  { title: "Management Consulting", grpId: "Business Services" },
  { title: "Marketing & Advertising", grpId: "Business Services" },
  { title: "Multimedia & Graphic Design", grpId: "Business Services" },
  { title: "Outsourcing", grpId: "Business Services" },
  { title: "Sales", grpId: "Business Services" },
  {
    title: "Security & Investigations Products & Services",
    grpId: "Business Services",
  },
  { title: "Staffing & Recruiting", grpId: "Business Services" },
  { title: "Translation & Localization", grpId: "Business Services" },
  { title: "Writing & Editing", grpId: "Business Services" },
  //3
  { title: "Chambers of Commerce" },
  //4
  { title: "Cities, Towns & Municipalities" },
  {
    title: "Cities Towns & Municipalities - General",
    grpId: "Cities, Towns & Municipalities",
  },
  {
    title: "Communities",
    grpId: "Cities, Towns & Municipalities",
  },
  {
    title: "Local",
    grpId: "Cities, Towns & Municipalities",
  },
  {
    title: "Parking",
    grpId: "Cities, Towns & Municipalities",
  },
  {
    title: "Public Safety",
    grpId: "Cities, Towns & Municipalities",
  },
  //5
  { title: "Construction" },
  { title: "Architecture Engineering & Design", grpId: "Construction" },
  { title: "Construction - General", grpId: "Construction" },
  { title: "Mechanical Engineering", grpId: "Construction" },
  { title: "Mechanical or Industrial Engineering", grpId: "Construction" },
  //6 consumer services
  { title: "Consumer Services" },
  {
    title: "Car & Truck Rental",
    grpId: "Consumer Services",
  },
  {
    title: "Child Care",
    grpId: "Consumer Services",
  },
  {
    title: "Consumer Services - General",
    grpId: "Consumer Services",
  },
  {
    title: "Funeral Homes & Funeral Related Services",
    grpId: "Consumer Services",
  },
  {
    title: "Hair Salons",
    grpId: "Consumer Services",
  },
  {
    title: "Laundry & Dry Cleaning Services",
    grpId: "Consumer Services",
  },
  {
    title: "Photography",
    grpId: "Consumer Services",
  },
  {
    title: "Privacy",
    grpId: "Consumer Services",
  },
  {
    title: "Travel Agencies & Services",
    grpId: "Consumer Services",
  },
  {
    title: "Veterinary Care",
    grpId: "Consumer Services",
  },
  {
    title: "Wedding",
    grpId: "Consumer Services",
  },

  //7 cultural
  { title: "Cultural" },
  {
    title: "Cultural - General",
    grpId: "Cultural",
  },
  {
    title: "Fine Arts",
    grpId: "Cultural",
  },
  {
    title: "Libraries",
    grpId: "Cultural",
  },
  {
    title: "Museums & Art Galleries",
    grpId: "Cultural",
  },
  {
    title: "Performing Arts",
    grpId: "Cultural",
  },

  //8 education
  { title: "Education" },
  {
    title: "Colleges & Universities",
    grpId: "Education",
  },
  {
    title: "E-learning",
    grpId: "Education",
  },
  {
    title: "Education - General",
    grpId: "Education",
  },
  {
    title: "Higher Education",
    grpId: "Education",
  },
  {
    title: "K-12 Schools",
    grpId: "Education",
  },
  {
    title: "Professional Training & Coaching",
    grpId: "Education",
  },
  {
    title: "Training",
    grpId: "Education",
  },

  //9 "Energy, Utilities & Waste Treatment"
  { title: "Energy, Utilities & Waste Treatment" },
  {
    title: "Electricity & Energy",
    grpId: "Energy, Utilities & Waste Treatment",
  },
  {
    title: "Environmental Services",
    grpId: "Energy, Utilities & Waste Treatment",
  },
  {
    title: "Forestry",
    grpId: "Energy, Utilities & Waste Treatment",
  },
  {
    title: "Natural Resources",
    grpId: "Energy, Utilities & Waste Treatment",
  },
  {
    title: "Nuclear",
    grpId: "Energy, Utilities & Waste Treatment",
  },
  {
    title: "Oil & Gas Exploration & Services",
    grpId: "Energy, Utilities & Waste Treatment",
  },
  {
    title: "Renewables & Environment",
    grpId: "Energy, Utilities & Waste Treatment",
  },
  {
    title: "Utilities",
    grpId: "Energy, Utilities & Waste Treatment",
  },
  {
    title: "Water Energy & Waste Treatment",
    grpId: "Energy, Utilities & Waste Treatment",
  },
  {
    title: "Energy, Utilities & Waste Treatment - General",
    grpId: "Energy, Utilities & Waste Treatment",
  },

  //10 finance
  { title: "Finance" },
  {
    title: "Banking",
    grpId: "Finance",
  },
  {
    title: "Credit Cards & Transaction Processing",
    grpId: "Finance",
  },
  {
    title: "Finance - General",
    grpId: "Finance",
  },
  {
    title: "Financial Services",
    grpId: "Finance",
  },
  {
    title: "Investment Banking",
    grpId: "Finance",
  },
  {
    title: "Venture Capital & Private Equity",
    grpId: "Finance",
  },

  //11 government
  { title: "Government" },
  {
    title: "Corrections Facilities",
    grpId: "Government",
  },
  {
    title: "Government - General",
    grpId: "Government",
  },
  {
    title: "International Affairs",
    grpId: "Government",
  },
  {
    title: "Law Enforcement",
    grpId: "Government",
  },
  {
    title: "Military",
    grpId: "Government",
  },
  {
    title: "Public Policy",
    grpId: "Government",
  },

  // 12 healthcare
  { title: "Healthcare" },

  {
    title: "Biotechnology",
    grpId: "Healthcare",
  },
  {
    title: "Clinical Trials",
    grpId: "Healthcare",
  },
  {
    title: "Drug Manufacturing & Research",
    grpId: "Healthcare",
  },
  {
    title: "Drug Stores & Pharmacies",
    grpId: "Healthcare",
  },
  {
    title: "Emergency Medical Transportation & Services",
    grpId: "Healthcare",
  },
  {
    title: "Hospitals & Healthcare",
    grpId: "Healthcare",
  },
  {
    title: "Medical Practice",
    grpId: "Healthcare",
  },
  {
    title: "Medicine",
    grpId: "Healthcare",
  },
  {
    title: "Mental Health Care",
    grpId: "Healthcare",
  },
  {
    title: "Pharmaceuticals",
    grpId: "Healthcare",
  },
  {
    title: "Psychology",
    grpId: "Healthcare",
  },
  {
    title: "Healthcare - General",
    grpId: "Healthcare",
  },

  //13 hospitality
  { title: "Hospitality" },

  {
    title: "Amusement Parks Arcades & Attractions",
    grpId: "Hospitality",
  },
  {
    title: "Cruise Lines",
    grpId: "Hospitality",
  },
  {
    title: "Elder & Disabled Care",
    grpId: "Hospitality",
  },
  {
    title: "Entertainment",
    grpId: "Hospitality",
  },
  {
    title: "Fitness & Dance Facilities",
    grpId: "Hospitality",
  },
  {
    title: "Gambling & Casinos",
    grpId: "Hospitality",
  },
  {
    title: "Gaming",
    grpId: "Hospitality",
  },
  {
    title: "Hospitality - General",
    grpId: "Hospitality",
  },
  {
    title: "Hotels and Resorts",
    grpId: "Hospitality",
  },
  {
    title: "Lodging & Resorts",
    grpId: "Hospitality",
  },
  {
    title: "Movie Theaters",
    grpId: "Hospitality",
  },
  {
    title: "Recreation",
    grpId: "Hospitality",
  },
  {
    title: "Restaurants",
    grpId: "Hospitality",
  },
  {
    title: "Sports",
    grpId: "Hospitality",
  },
  {
    title: "Zoos & National Parks",
    grpId: "Hospitality",
  },

  // 14 insurance
  { title: "Insurance" },
  // 15 Law Firms & Legal Services
  { title: "Law Firms & Legal Services" },
  //16 manufacturing
  { title: "Manufacturing" },

  {
    title: "Aerospace & Defense",
    grpId: "Manufacturing",
  },
  {
    title: "Apparel & Fashion",
    grpId: "Manufacturing",
  },
  {
    title: "Appliances",
    grpId: "Manufacturing",
  },
  {
    title: "Automotive",
    grpId: "Manufacturing",
  },
  {
    title: "Boats & Submarines",
    grpId: "Manufacturing",
  },
  {
    title: "Building Materials",
    grpId: "Manufacturing",
  },
  {
    title: "Business Supplies & Equipment",
    grpId: "Manufacturing",
  },
  {
    title: "Chemicals",
    grpId: "Manufacturing",
  },
  {
    title: "Cleaning Products",
    grpId: "Manufacturing",
  },
  {
    title: "Computer Hardware",
    grpId: "Manufacturing",
  },
  {
    title: "Consumer Electronics",
    grpId: "Manufacturing",
  },
  {
    title: "Consumer Goods",
    grpId: "Manufacturing",
  },
  {
    title: "Cosmetics Beauty Supply & Personal Care Products",
    grpId: "Manufacturing",
  },
  {
    title: "Dairy",
    grpId: "Manufacturing",
  },
  {
    title: "Electronics & Electronics Manufacturing",
    grpId: "Manufacturing",
  },
  {
    title: "Flash Storage",
    grpId: "Manufacturing",
  },
  {
    title: "Food & Beverages",
    grpId: "Manufacturing",
  },
  {
    title: "Furniture",
    grpId: "Manufacturing",
  },
  {
    title: "Glass & Concrete",
    grpId: "Manufacturing",
  },
  {
    title: "Hand Power and Lawn-care Tools",
    grpId: "Manufacturing",
  },
  {
    title: "Health & Nutrition Products",
    grpId: "Manufacturing",
  },
  {
    title: "Health Wellness & Fitness",
    grpId: "Manufacturing",
  },
  {
    title: "Household Goods",
    grpId: "Manufacturing",
  },
  {
    title: "Industrial Machinery Equipment & Automation",
    grpId: "Manufacturing",
  },
  {
    title: "Lumber & Wood Production",
    grpId: "Manufacturing",
  },
  {
    title: "Luxury Goods & Jewelry",
    grpId: "Manufacturing",
  },
  {
    title: "Manufacturing - General",
    grpId: "Manufacturing",
  },
  {
    title: "Maritime",
    grpId: "Manufacturing",
  },
  {
    title: "Medical Devices & Equipment",
    grpId: "Manufacturing",
  },
  {
    title: "Miscellaneous Building Materials",
    grpId: "Manufacturing",
  },
  {
    title: "Network Security Hardware & Software",
    grpId: "Manufacturing",
  },
  {
    title: "Office Products",
    grpId: "Manufacturing",
  },
  {
    title: "Paper & Forest Products",
    grpId: "Manufacturing",
  },
  {
    title: "Personal Computers & Peripherals",
    grpId: "Manufacturing",
  },
  {
    title: "Pet Products",
    grpId: "Manufacturing",
  },
  {
    title: "Petrochemicals",
    grpId: "Manufacturing",
  },
  {
    title: "Plastics & Packaging & Containers",
    grpId: "Manufacturing",
  },
  {
    title: "Plumbing & HVAC Equipment",
    grpId: "Manufacturing",
  },
  {
    title: "Power Conversion & Protection Equipment",
    grpId: "Manufacturing",
  },
  {
    title: "Semiconductor & Semiconductor Equipment",
    grpId: "Manufacturing",
  },
  {
    title: "Shipbuilding",
    grpId: "Manufacturing",
  },
  {
    title: "Telecommunication Equipment",
    grpId: "Manufacturing",
  },
  {
    title: "Test & Measurement Equipment",
    grpId: "Manufacturing",
  },
  {
    title: "Textiles & Apparel",
    grpId: "Manufacturing",
  },
  {
    title: "Tires & Rubber",
    grpId: "Manufacturing",
  },
  {
    title: "Tobacco",
    grpId: "Manufacturing",
  },
  {
    title: "Toys & Games",
    grpId: "Manufacturing",
  },
  {
    title: "Wine & Spirits",
    grpId: "Manufacturing",
  },
  {
    title: "Wire & Cable",
    grpId: "Manufacturing",
  },

  // 17 media & internet
  { title: "Media & Internet" },

  {
    title: "Adult",
    grpId: "Media & Internet",
  },
  {
    title: "Animation",
    grpId: "Media & Internet",
  },
  {
    title: "Broadcasting & Media",
    grpId: "Media & Internet",
  },
  {
    title: "Classifieds",
    grpId: "Media & Internet",
  },
  {
    title: "Ebook & Audiobooks",
    grpId: "Media & Internet",
  },
  {
    title: "Ecommerce & Internet Retail",
    grpId: "Media & Internet",
  },
  {
    title: "Film Video & Media Production & Services",
    grpId: "Media & Internet",
  },
  {
    title: "Internet & Digital Media",
    grpId: "Media & Internet",
  },
  {
    title: "Internet-related Services",
    grpId: "Media & Internet",
  },
  {
    title: "Music & Music Related Services",
    grpId: "Media & Internet",
  },
  {
    title: "Newspapers & News Services",
    grpId: "Media & Internet",
  },
  {
    title: "Podcast",
    grpId: "Media & Internet",
  },
  {
    title: "Public Relations & Communication",
    grpId: "Media & Internet",
  },
  {
    title: "Publishing",
    grpId: "Media & Internet",
  },
  {
    title: "Radio Stations",
    grpId: "Media & Internet",
  },
  {
    title: "Search Engines & Internet Portals",
    grpId: "Media & Internet",
  },
  {
    title: "Social Media",
    grpId: "Media & Internet",
  },
  {
    title: "Software Engineering",
    grpId: "Media & Internet",
  },
  {
    title: "Streaming",
    grpId: "Media & Internet",
  },
  {
    title: "Television Stations",
    grpId: "Media & Internet",
  },
  {
    title: "Video Chat & Online Messaging",
    grpId: "Media & Internet",
  },
  {
    title: "Media & Internet - General",
    grpId: "Media & Internet",
  },
  //18 metals & mining
  { title: "Metals & Mining" },

  {
    title: "Metals & Mining - General",
    grpId: "Metals & Mining",
  },
  {
    title: "Mining",
    grpId: "Metals & Mining",
  },
  //19 organizations
  { title: "Organizations" },

  {
    title: "Non-Profit & Charitable Organizations & Foundations",
    grpId: "Organizations",
  },
  {
    title: "Organizations - General",
    grpId: "Organizations",
  },
  {
    title: "Parenting & Youth",
    grpId: "Organizations",
  },
  {
    title: "Philanthropy",
    grpId: "Organizations",
  },
  {
    title: "Religious Organizations",
    grpId: "Organizations",
  },
  //20 real estate
  { title: "Real Estate" },

  {
    title: "Commercial Real Estate",
    grpId: "Real Estate",
  },
  {
    title: "Real Estate - General",
    grpId: "Real Estate",
  },
  //21 research & technology
  { title: "Research & Technology" },

  {
    title: "AR/VR",
    grpId: "Research & Technology",
  },
  {
    title: "Artificial Intelligence & Machine Learning",
    grpId: "Research & Technology",
  },
  {
    title: "Cryptocurrency",
    grpId: "Research & Technology",
  },
  {
    title: "Data & Big Data",
    grpId: "Research & Technology",
  },
  {
    title: "Drones",
    grpId: "Research & Technology",
  },
  {
    title: "Genetics",
    grpId: "Research & Technology",
  },
  {
    title: "Mobile",
    grpId: "Research & Technology",
  },
  {
    title: "Nanotechnology",
    grpId: "Research & Technology",
  },
  {
    title: "Neuroscience",
    grpId: "Research & Technology",
  },
  {
    title: "Quantum Computing",
    grpId: "Research & Technology",
  },
  {
    title: "Research - General",
    grpId: "Research & Technology",
  },
  {
    title: "Robotics",
    grpId: "Research & Technology",
  },
  {
    title: "Sharing Economy",
    grpId: "Research & Technology",
  },
  {
    title: "Technology",
    grpId: "Research & Technology",
  },
  {
    title: "Think Tanks",
    grpId: "Research & Technology",
  },
  //22 Retail
  { title: "Retail" },

  {
    title: "Department Stores Shopping Centers & Superstores",
    grpId: "Retail",
  },
  {
    title: "Flowers Gifts & Specialty",
    grpId: "Retail",
  },
  {
    title: "Footwear",
    grpId: "Retail",
  },
  {
    title: "Gas Stations Convenience & Liquor Stores",
    grpId: "Retail",
  },
  {
    title: "Gift & Gift Products",
    grpId: "Retail",
  },
  {
    title: "Grocery & Supermarkets",
    grpId: "Retail",
  },
  {
    title: "Home Improvement & Hardware",
    grpId: "Retail",
  },
  {
    title: "Optometry & Eyewear",
    grpId: "Retail",
  },
  {
    title: "Rental - Other - Furniture A/V Construction & Industrial Equipment",
    grpId: "Retail",
  },
  {
    title: "Rental - Video & DVD",
    grpId: "Retail",
  },
  {
    title: "Retail - General",
    grpId: "Retail",
  },
  {
    title: "Sporting & Recreational Equipment",
    grpId: "Retail",
  },
  {
    title: "Vitamins Supplements & Health Stores",
    grpId: "Retail",
  },
  {
    title: "Record, Video & Book Stores",
    grpId: "Retail",
  },
  {
    title: "Jewelry & Watch Retail",
    grpId: "Retail",
  },
  {
    title: "Consumer Electronics & Computers Retail",
    grpId: "Retail",
  },
  {
    title: "Apparel & Accessories Retail",
    grpId: "Retail",
  },
  //23 software
  { title: "Software" },

  {
    title: "Business Intelligence Software",
    grpId: "Software",
  },
  {
    title: "Cloud Software",
    grpId: "Software",
  },
  {
    title: "Computer Games",
    grpId: "Software",
  },
  {
    title: "Content & Collaboration Software",
    grpId: "Software",
  },
  {
    title: "Customer Relationship Management Software(CRM)",
    grpId: "Software",
  },
  {
    title: "Database & File Management Software",
    grpId: "Software",
  },
  {
    title: "Enterprise Resource Planning Software(ERP)",
    grpId: "Software",
  },
  {
    title: "Software & Technical Consulting",
    grpId: "Software",
  },
  {
    title: "Software - General",
    grpId: "Software",
  },
  {
    title: "Storage & System Management Software",
    grpId: "Software",
  },
  {
    title: "Supply Chain Management Software",
    grpId: "Software",
  },
  //24 telecommunications
  { title: "Telecommunications" },

  {
    title: "Cable & Satellite",
    grpId: "Telecommunications",
  },
  {
    title: "Telecommunications - General",
    grpId: "Telecommunications",
  },
  {
    title: "Wireless",
    grpId: "Telecommunications",
  },
  //25 trade, supply
  { title: "Trade, Supply Chain & Commerce" },
  {
    title: "Import & Export & Trade",
    grpId: "Trade, Supply Chain & Commerce",
  },
  {
    title: "Logistics & Supply Chain",
    grpId: "Trade, Supply Chain & Commerce",
  },
  {
    title: "Warehousing",
    grpId: "Trade, Supply Chain & Commerce",
  },
  {
    title: "Wholesale",
    grpId: "Trade, Supply Chain & Commerce",
  },
  //26 transportation
  { title: "Transportation" },

  {
    title: "Airlines & Aviation",
    grpId: "Transportation",
  },
  {
    title: "Delivery",
    grpId: "Transportation",
  },
  {
    title: "Freight & Logistics Services",
    grpId: "Transportation",
  },
  {
    title: "Rail Bus & Taxi",
    grpId: "Transportation",
  },
  {
    title: "Shipping",
    grpId: "Transportation",
  },
  {
    title: "Transportation - General",
    grpId: "Transportation",
  },
  {
    title: "Trucking Moving & Storage",
    grpId: "Transportation",
  },
];

export const FILTERS = [
  { name: "Location", type: "multi-select", id: "location" },
  {
    name: "Number of Employees",
    type: "multi-select",
    id: "compSize",

    options: [
      [1, 10],
      [11, 50],
      [51, 100],
      [101, 500],
      [501, 1000],
      [1001, 5000],
      [5001, 10000],
      [10001, 25000],
      [25001, 100000],
      [100001, 2000000],
    ],
    // options: [
    //   [1, 10],
    //   [11, 20],
    //   [21, 50],
    //   [51, 100],
    //   [101, 500],
    //   [501, 1000],
    //   [1001, 2000],
    //   [2001, 5000],
    //   [5001, 10000],
    //   [10001, 1000000000],
    // ],
  },
  {
    name: "Revenue",
    type: "range-slider",
    id: "revenue",
    options: {
      range: { min: 100000, max: 50000000 },
      values: [
        [100000, 10000000],
        [10000000, 50000000], // 10000000-50000000
        [50000000, 100000000], // 50000000-100000000
        [100000000, 500000000], // 100000000-500000000
        [500000000, 1000000000], // 500000000-1000000000
        [1000000000, 5000000000], // 1000000000-5000000000
        [5000000000, 10000000000], // 5000000000<
      ],
    },
  },
  {
    name: "Funding",
    type: "range-slider",
    id: "funding",
    options: {
      range: { min: 100000, max: 50000000 },
    },
  },
  { name: "Keywords", type: "input", id: "keywords" },
  // { name: "Target Technologies", type: "input", id: "techStack" },
  { name: "Exclude Domains", type: "input", id: "blockedDomains" },
  { name: "Exclude Keywords", type: "input", id: "blockedKeywords" },
  { name: "Exclude Locations", type: "input", id: "blockedLocations" },
  // {name: "Skip", type: "input", id: "skip"},
];

// ! initialize filters here
export const initAddFilters = {
  location: [],
  revenue: [],
  funding: [],
  compSize: [],
  keywords: [],
  blockedDomains: [],
  blockedLocations: [],
  blockedIndustries: [],
  blockedKeywords: [],
  // techStack: [],
};
export const initAdditionalFilters = {
  name: "",
  managementLevels: [],
  changedJob: "",
  departments: [],
  skills: [],
  schools: [],
  majors: [],
  degree: [],
  experience: "",
  companies: [],
  includePast: false,
  // companyType: "",
  excludeContacts: false,
  //  contactInfo: "",
  socialLink: "",
  radius: [],
};

const _1H = 100;
const _1M = 1000000;
const _1T = 1000000000000;

export const moveRange = (value, oRange, nRange) => {
  const [oldMin, oldMax] = oRange;
  const [newMin, newMax] = nRange;
  const modRange = value.map((oldValue) => {
    const oldRange = oldMax - oldMin;
    const newRange = newMax - newMin;
    const value = ((oldValue - oldMin) * newRange) / oldRange + newMin;
    return Math.round(value);
  });
  // console.log(value);
  // console.log(modRange);
  return modRange;
};

export const DESIGNATION = [
  "CEO",
  "CFO",
  "CTO",
  "CMO",
  "Sales Manager",
  "Marketing Lead",
];

export const TITLES = [
  "CMO",
  "Head Marketing",
  "Head 0f sales",
  "Sales Manager",
  "Director of sales",
  "BDR",
  "SDR",
  "Director of Business Development",
];
export const YearsOfExperience = ["0-3", "3-10", "10<"];
export const companyList = [
  "world",
  "company",
  "API Companies",
  "My Companies",
];
export const educationMajor = [
  "Computer Science",
  "Business Administration",
  "Management",
  "Marketing",
  "Accounting",
];
export const degree = [
  "Bachelors",
  "Masters",
  "Associates",
  "Doctorates",
  "High School",
];
export const DEPARTMENT = [
  {
    title: "Product & Engineering",
  },

  {
    title: "Graphic Design",
    grpId: "Product & Engineering",
  },
  {
    title: "Product Design",
    grpId: "Product & Engineering",
  },
  {
    title: "Web Design",
    grpId: "Product & Engineering",
  },
  {
    title: "Data",
    grpId: "Product & Engineering",
  },
  {
    title: "Devops",
    grpId: "Product & Engineering",
  },
  {
    title: "Electrical",
    grpId: "Product & Engineering",
  },
  {
    title: "Mechanical",
    grpId: "Product & Engineering",
  },
  {
    title: "Network",
    grpId: "Product & Engineering",
  },
  {
    title: "Information Technology",
    grpId: "Product & Engineering",
  },
  {
    title: "Project Engineering",
    grpId: "Product & Engineering",
  },
  {
    title: "Quality Assurance",
    grpId: "Product & Engineering",
  },
  {
    title: "Security",
    grpId: "Product & Engineering",
  },
  {
    title: "Software",
    grpId: "Product & Engineering",
  },
  {
    title: "Systems",
    grpId: "Product & Engineering",
  },
  {
    title: "Web",
    grpId: "Product & Engineering",
  },
  { title: "Finance" },

  {
    title: "Accounting",
    grpId: "Finance",
  },
  {
    title: "Investment",
    grpId: "Finance",
  },
  {
    title: "Tax",
    grpId: "Finance",
  },
  { title: "HR" },
  {
    title: "Compensation",
    grpId: "HR",
  },
  {
    title: "Employee Development",
    grpId: "HR",
  },
  {
    title: "Recruiting",
    grpId: "HR",
  },
  { title: "Legal" },

  {
    title: "Judicial",
    grpId: "Legal",
  },
  {
    title: "Lawyer",
    grpId: "Legal",
  },
  {
    title: "Paralegal",
    grpId: "Legal",
  },
  { title: "Marketing" },

  {
    title: "Brand Marketing",
    grpId: "Marketing",
  },
  {
    title: "Content Marketing",
    grpId: "Marketing",
  },
  {
    title: "Product Marketing",
    grpId: "Marketing",
  },
  {
    title: "Events",
    grpId: "Marketing",
  },
  {
    title: "Media Relations",
    grpId: "Marketing",
  },
  {
    title: "Broadcasting",
    grpId: "Marketing",
  },
  {
    title: "Editorial",
    grpId: "Marketing",
  },
  {
    title: "Journalism",
    grpId: "Marketing",
  },
  {
    title: "Video",
    grpId: "Marketing",
  },
  {
    title: "Writing",
    grpId: "Marketing",
  },
  { title: "Health" },

  {
    title: "Dental",
    grpId: "Health",
  },
  {
    title: "Doctor",
    grpId: "Health",
  },
  {
    title: "Fitness",
    grpId: "Health",
  },
  {
    title: "Nursing",
    grpId: "Health",
  },
  {
    title: "Therapy",
    grpId: "Health",
  },
  {
    title: "Wellness",
    grpId: "Health",
  },
  { title: "Operations" },

  {
    title: "Logistics",
    grpId: "Operations",
  },
  {
    title: "Office Management",
    grpId: "Operations",
  },
  {
    title: "Product",
    grpId: "Operations",
  },
  {
    title: "Project Management",
    grpId: "Operations",
  },
  {
    title: "Customer Success",
    grpId: "Operations",
  },
  {
    title: "Support",
    grpId: "Operations",
  },
  { title: "Sales" },

  {
    title: "Accounts",
    grpId: "Sales",
  },
  {
    title: "Business Development",
    grpId: "Sales",
  },
  {
    title: "Pipeline",
    grpId: "Sales",
  },
];
export const MANAGEMENT_LEVELS = [
  "Founder/Owner",
  "C-Level",
  "VP",
  "Director",
  "Manager",
  "Individual Contributor",
  "Entry",
  "Intern",
  "Unpaid",
];
// export const CHANGED_JOBS_WITHIN = [
//   "Last 3 Months",
//   "Last 6 Months",
//   "Last Year",
//   "Last 2 Years",
//   "Last 3 Years",
// ];
export const CHANGED_JOBS_WITHIN = [
  "0-90",
  "0-180",
  "0-360",
  "0-720",
  "0-1080",
];

export const SIC_CODES = [
  100, 200, 700, 800, 900, 1000, 1040, 1090, 1220, 1221, 1311, 1381, 1382, 1389,
  1400, 1520, 1531, 1540, 1600, 1623, 1700, 1731, 2000, 2011, 2013, 2015, 2020,
  2024, 2030, 2033, 2040, 2050, 2052, 2060, 2070, 2080, 2082, 2086, 2090, 2092,
  2100, 2111, 2200, 2211, 2221, 2250, 2253, 2273, 2300, 2320, 2330, 2340, 2390,
  2400, 2421, 2430, 2451, 2452, 2510, 2511, 2520, 2522, 2531, 2540, 2590, 2600,
  2611, 2621, 2631, 2650, 2670, 2673, 2711, 2721, 2731, 2732, 2741, 2750, 2761,
  2771, 2780, 2790, 2800, 2810, 2820, 2821, 2833, 2834, 2835, 2836, 2840, 2842,
  2844, 2851, 2860, 2870, 2890, 2891, 2911, 2950, 2990, 3011, 3021, 3050, 3060,
  3080, 3081, 3086, 3089, 3100, 3140, 3211, 3220, 3221, 3231, 3241, 3250, 3260,
  3270, 3272, 3281, 3290, 3310, 3312, 3317, 3320, 3330, 3334, 3341, 3350, 3357,
  3360, 3390, 3411, 3412, 3420, 3430, 3433, 3440, 3442, 3443, 3444, 3448, 3451,
  3452, 3460, 3470, 3480, 3490, 3510, 3523, 3524, 3530, 3531, 3532, 3533, 3537,
  3540, 3541, 3550, 3555, 3559, 3560, 3561, 3562, 3564, 3567, 3569, 3570, 3571,
  3572, 3575, 3576, 3577, 3578, 3579, 3580, 3585, 3590, 3600, 3612, 3613, 3620,
  3621, 3630, 3634, 3640, 3651, 3652, 3661, 3663, 3669, 3670, 3672, 3674, 3677,
  3678, 3679, 3690, 3695, 3711, 3713, 3714, 3715, 3716, 3720, 3721, 3724, 3728,
  3730, 3743, 3751, 3760, 3790, 3812, 3821, 3822, 3823, 3824, 3825, 3826, 3827,
  3829, 3841, 3842, 3843, 3844, 3845, 3851, 3861, 3873, 3910, 3911, 3931, 3942,
  3944, 3949, 3950, 3960, 3990, 4011, 4013, 4100, 4210, 4213, 4220, 4231, 4400,
  4412, 4512, 4513, 4522, 4581, 4610, 4700, 4731, 4812, 4813, 4822, 4832, 4833,
  4841, 4899, 4900, 4911, 4922, 4923, 4924, 4931, 4932, 4941, 4950, 4953, 4955,
  4961, 4991, 5000, 5010, 5013, 5020, 5030, 5031, 5040, 5045, 5047, 5050, 5051,
  5063, 5064, 5065, 5070, 5072, 5080, 5082, 5084, 5090, 5094, 5099, 5110, 5122,
  5130, 5140, 5141, 5150, 5160, 5171, 5172, 5180, 5190, 5200, 5211, 5271, 5311,
  5331, 5399, 5400, 5411, 5412, 5500, 5531, 5600, 5621, 5651, 5661, 5700, 5712,
  5731, 5734, 5735, 5810, 5812, 5900, 5912, 5940, 5944, 5945, 5960, 5961, 5990,
  6021, 6022, 6029, 6035, 6036, 6099, 6111, 6141, 6153, 6159, 6162, 6163, 6172,
  6189, 6199, 6200, 6211, 6221, 6282, 6311, 6321, 6324, 6331, 6351, 6361, 6399,
  6411, 6500, 6510, 6512, 6513, 6519, 6531, 6532, 6552, 6770, 6792, 6794, 6795,
  6798, 6799, 7000, 7011, 7200, 7310, 7311, 7320, 7330, 7331, 7340, 7350, 7359,
  7361, 7363, 7370, 7371, 7372, 7373, 7374, 7377, 7380, 7381, 7384, 7385, 7389,
  7500, 7510, 7600, 7812, 7819, 7822, 7829, 7830, 7841, 7900, 7948, 7990, 7997,
  8000, 8011, 8050, 8051, 8060, 8062, 8071, 8082, 8090, 8093, 8111, 8200, 8300,
  8351, 8600, 8700, 8711, 8731, 8734, 8741, 8742, 8744, 8880, 8888, 8900, 9721,
  9995,
];

export const NAICS_CODE = [
  11,
  111,
  1111,
  11111,
  111110,
  11112,
  111120,
  11113,
  111130,
  11114,
  111140,
  11115,
  111150,
  11116,
  111160,
  11119,
  111191,
  111199,
  1112,
  11121,
  111211,
  111219,
  1113,
  11131,
  111310,
  11132,
  111320,
  11133,
  111331,
  111332,
  111333,
  111334,
  111335,
  111336,
  111339,
  1114,
  11141,
  111411,
  111419,
  11142,
  111421,
  111422,
  1119,
  11191,
  111910,
  11192,
  111920,
  11193,
  111930,
  11194,
  111940,
  11199,
  111991,
  111992,
  111998,
  112,
  1121,
  11211,
  112111,
  112112,
  11212,
  112120,
  11213,
  112130,
  1122,
  11221,
  112210,
  1123,
  11231,
  112310,
  11232,
  112320,
  11233,
  112330,
  11234,
  112340,
  11239,
  112390,
  1124,
  11241,
  112410,
  11242,
  112420,
  1125,
  11251,
  112511,
  112512,
  112519,
  1129,
  11291,
  112910,
  11292,
  112920,
  11293,
  112930,
  11299,
  112990,
  113,
  1131,
  11311,
  113110,
  1132,
  11321,
  113210,
  1133,
  11331,
  113310,
  114,
  1141,
  11411,
  114111,
  114112,
  114119,
  1142,
  11421,
  114210,
  115,
  1151,
  11511,
  115111,
  115112,
  115113,
  115114,
  115115,
  115116,
  1152,
  11521,
  115210,
  1153,
  11531,
  115310,
  21,
  211,
  2111,
  21112,
  211120,
  21113,
  211130,
  212,
  2121,
  21211,
  212114,
  212115,
  2122,
  21221,
  212210,
  21222,
  212220,
  21223,
  212230,
  21229,
  212290,
  2123,
  21231,
  212311,
  212312,
  212313,
  212319,
  21232,
  212321,
  212322,
  212323,
  21239,
  212390,
  213,
  2131,
  21311,
  213111,
  213112,
  213113,
  213114,
  213115,
  22,
  221,
  2211,
  22111,
  221111,
  221112,
  221113,
  221114,
  221115,
  221116,
  221117,
  221118,
  22112,
  221121,
  221122,
  2212,
  22121,
  221210,
  2213,
  22131,
  221310,
  22132,
  221320,
  22133,
  221330,
  23,
  236,
  2361,
  23611,
  236115,
  236116,
  236117,
  236118,
  2362,
  23621,
  236210,
  23622,
  236220,
  237,
  2371,
  23711,
  237110,
  23712,
  237120,
  23713,
  237130,
  2372,
  23721,
  237210,
  2373,
  23731,
  237310,
  2379,
  23799,
  237990,
  238,
  2381,
  23811,
  238110,
  23812,
  238120,
  23813,
  238130,
  23814,
  238140,
  23815,
  238150,
  23816,
  238160,
  23817,
  238170,
  23819,
  238190,
  2382,
  23821,
  238210,
  23822,
  238220,
  23829,
  238290,
  2383,
  23831,
  238310,
  23832,
  238320,
  23833,
  238330,
  23834,
  238340,
  23835,
  238350,
  23839,
  238390,
  2389,
  23891,
  238910,
  23899,
  238990,
  31 - 33,
  311,
  3111,
  31111,
  311111,
  311119,
  3112,
  31121,
  311211,
  311212,
  311213,
  31122,
  311221,
  311224,
  311225,
  31123,
  311230,
  3113,
  31131,
  311313,
  311314,
  31134,
  311340,
  31135,
  311351,
  311352,
  3114,
  31141,
  311411,
  311412,
  31142,
  311421,
  311422,
  311423,
  3115,
  31151,
  311511,
  311512,
  311513,
  311514,
  31152,
  311520,
  3116,
  31161,
  311611,
  311612,
  311613,
  311615,
  3117,
  31171,
  311710,
  3118,
  31181,
  311811,
  311812,
  311813,
  31182,
  311821,
  311824,
  31183,
  311830,
  3119,
  31191,
  311911,
  311919,
  31192,
  311920,
  31193,
  311930,
  31194,
  311941,
  311942,
  31199,
  311991,
  311999,
  312,
  3121,
  31211,
  312111,
  312112,
  312113,
  31212,
  312120,
  31213,
  312130,
  31214,
  312140,
  3122,
  31223,
  312230,
  313,
  3131,
  31311,
  313110,
  3132,
  31321,
  313210,
  31322,
  313220,
  31323,
  313230,
  31324,
  313240,
  3133,
  31331,
  313310,
  31332,
  313320,
  314,
  3141,
  31411,
  314110,
  31412,
  314120,
  3149,
  31491,
  314910,
  31499,
  314994,
  314999,
  315,
  3151,
  31512,
  315120,
  3152,
  31521,
  315210,
  31525,
  315250,
  3159,
  31599,
  315990,
  316,
  3161,
  31611,
  316110,
  3162,
  31621,
  316210,
  3169,
  31699,
  316990,
  321,
  3211,
  32111,
  321113,
  321114,
  3212,
  32121,
  321211,
  321212,
  321215,
  321219,
  3219,
  32191,
  321911,
  321912,
  321918,
  32192,
  321920,
  32199,
  321991,
  321992,
  321999,
  322,
  3221,
  32211,
  322110,
  32212,
  322120,
  32213,
  322130,
  3222,
  32221,
  322211,
  322212,
  322219,
  32222,
  322220,
  32223,
  322230,
  32229,
  322291,
  322299,
  323,
  3231,
  32311,
  323111,
  323113,
  323117,
  32312,
  323120,
  324,
  3241,
  32411,
  324110,
  32412,
  324121,
  324122,
  32419,
  324191,
  324199,
  325,
  3251,
  32511,
  325110,
  32512,
  325120,
  32513,
  325130,
  32518,
  325180,
  32519,
  325193,
  325194,
  325199,
  3252,
  32521,
  325211,
  325212,
  32522,
  325220,
  3253,
  32531,
  325311,
  325312,
  325314,
  325315,
  32532,
  325320,
  3254,
  32541,
  325411,
  325412,
  325413,
  325414,
  3255,
  32551,
  325510,
  32552,
  325520,
  3256,
  32561,
  325611,
  325612,
  325613,
  32562,
  325620,
  3259,
  32591,
  325910,
  32592,
  325920,
  32599,
  325991,
  325992,
  325998,
  326,
  3261,
  32611,
  326111,
  326112,
  326113,
  32612,
  326121,
  326122,
  32613,
  326130,
  32614,
  326140,
  32615,
  326150,
  32616,
  326160,
  32619,
  326191,
  326199,
  3262,
  32621,
  326211,
  326212,
  32622,
  326220,
  32629,
  326291,
  326299,
  327,
  3271,
  32711,
  327110,
  32712,
  327120,
  3272,
  32721,
  327211,
  327212,
  327213,
  327215,
  3273,
  32731,
  327310,
  32732,
  327320,
  32733,
  327331,
  327332,
  32739,
  327390,
  3274,
  32741,
  327410,
  32742,
  327420,
  3279,
  32791,
  327910,
  32799,
  327991,
  327992,
  327993,
  327999,
  331,
  3311,
  33111,
  331110,
  3312,
  33121,
  331210,
  33122,
  331221,
  331222,
  3313,
  33131,
  331313,
  331314,
  331315,
  331318,
  3314,
  33141,
  331410,
  33142,
  331420,
  33149,
  331491,
  331492,
  3315,
  33151,
  331511,
  331512,
  331513,
  33152,
  331523,
  331524,
  331529,
  332,
  3321,
  33211,
  332111,
  332112,
  332114,
  332117,
  332119,
  3322,
  33221,
  332215,
  332216,
  3323,
  33231,
  332311,
  332312,
  332313,
  33232,
  332321,
  332322,
  332323,
  3324,
  33241,
  332410,
  33242,
  332420,
  33243,
  332431,
  332439,
  3325,
  33251,
  332510,
  3326,
  33261,
  332613,
  332618,
  3327,
  33271,
  332710,
  33272,
  332721,
  332722,
  3328,
  33281,
  332811,
  332812,
  332813,
  3329,
  33291,
  332911,
  332912,
  332913,
  332919,
  33299,
  332991,
  332992,
  332993,
  332994,
  332996,
  332999,
  333,
  3331,
  33311,
  333111,
  333112,
  33312,
  333120,
  33313,
  333131,
  333132,
  3332,
  33324,
  333241,
  333242,
  333243,
  333248,
  3333,
  33331,
  333310,
  3334,
  33341,
  333413,
  333414,
  333415,
  3335,
  33351,
  333511,
  333514,
  333515,
  333517,
  333519,
  3336,
  33361,
  333611,
  333612,
  333613,
  333618,
  3339,
  33391,
  333912,
  333914,
  33392,
  333921,
  333922,
  333923,
  333924,
  33399,
  333991,
  333992,
  333993,
  333994,
  333995,
  333996,
  333998,
  334,
  3341,
  33411,
  334111,
  334112,
  334118,
  3342,
  33421,
  334210,
  33422,
  334220,
  33429,
  334290,
  3343,
  33431,
  334310,
  3344,
  33441,
  334412,
  334413,
  334416,
  334417,
  334418,
  334419,
  3345,
  33451,
  334510,
  334511,
  334512,
  334513,
  334514,
  334515,
  334516,
  334517,
  334519,
  3346,
  33461,
  334610,
  335,
  3351,
  33513,
  335131,
  335132,
  335139,
  3352,
  33521,
  335210,
  33522,
  335220,
  3353,
  33531,
  335311,
  335312,
  335313,
  335314,
  3359,
  33591,
  335910,
  33592,
  335921,
  335929,
  33593,
  335931,
  335932,
  33599,
  335991,
  335999,
  336,
  3361,
  33611,
  336110,
  33612,
  336120,
  3362,
  33621,
  336211,
  336212,
  336213,
  336214,
  3363,
  33631,
  336310,
  33632,
  336320,
  33633,
  336330,
  33634,
  336340,
  33635,
  336350,
  33636,
  336360,
  33637,
  336370,
  33639,
  336390,
  3364,
  33641,
  336411,
  336412,
  336413,
  336414,
  336415,
  336419,
  3365,
  33651,
  336510,
  3366,
  33661,
  336611,
  336612,
  3369,
  33699,
  336991,
  336992,
  336999,
  337,
  3371,
  33711,
  337110,
  33712,
  337121,
  337122,
  337126,
  337127,
  3372,
  33721,
  337211,
  337212,
  337214,
  337215,
  3379,
  33791,
  337910,
  33792,
  337920,
  339,
  3391,
  33911,
  339112,
  339113,
  339114,
  339115,
  339116,
  3399,
  33991,
  339910,
  33992,
  339920,
  33993,
  339930,
  33994,
  339940,
  33995,
  339950,
  33999,
  339991,
  339992,
  339993,
  339994,
  339995,
  339999,
  42,
  423,
  4231,
  42311,
  423110,
  42312,
  423120,
  42313,
  423130,
  42314,
  423140,
  4232,
  42321,
  423210,
  42322,
  423220,
  4233,
  42331,
  423310,
  42332,
  423320,
  42333,
  423330,
  42339,
  423390,
  4234,
  42341,
  423410,
  42342,
  423420,
  42343,
  423430,
  42344,
  423440,
  42345,
  423450,
  42346,
  423460,
  42349,
  423490,
  4235,
  42351,
  423510,
  42352,
  423520,
  4236,
  42361,
  423610,
  42362,
  423620,
  42369,
  423690,
  4237,
  42371,
  423710,
  42372,
  423720,
  42373,
  423730,
  42374,
  423740,
  4238,
  42381,
  423810,
  42382,
  423820,
  42383,
  423830,
  42384,
  423840,
  42385,
  423850,
  42386,
  423860,
  4239,
  42391,
  423910,
  42392,
  423920,
  42393,
  423930,
  42394,
  423940,
  42399,
  423990,
  424,
  4241,
  42411,
  424110,
  42412,
  424120,
  42413,
  424130,
  4242,
  42421,
  424210,
  4243,
  42431,
  424310,
  42434,
  424340,
  42435,
  424350,
  4244,
  42441,
  424410,
  42442,
  424420,
  42443,
  424430,
  42444,
  424440,
  42445,
  424450,
  42446,
  424460,
  42447,
  424470,
  42448,
  424480,
  42449,
  424490,
  4245,
  42451,
  424510,
  42452,
  424520,
  42459,
  424590,
  4246,
  42461,
  424610,
  42469,
  424690,
  4247,
  42471,
  424710,
  42472,
  424720,
  4248,
  42481,
  424810,
  42482,
  424820,
  4249,
  42491,
  424910,
  42492,
  424920,
  42493,
  424930,
  42494,
  424940,
  42495,
  424950,
  42499,
  424990,
  425,
  4251,
  42512,
  425120,
  44 - 45,
  441,
  4411,
  44111,
  441110,
  44112,
  441120,
  4412,
  44121,
  441210,
  44122,
  441222,
  441227,
  4413,
  44133,
  441330,
  44134,
  441340,
  444,
  4441,
  44411,
  444110,
  44412,
  444120,
  44414,
  444140,
  44418,
  444180,
  4442,
  44423,
  444230,
  44424,
  444240,
  445,
  4451,
  44511,
  445110,
  44513,
  445131,
  445132,
  4452,
  44523,
  445230,
  44524,
  445240,
  44525,
  445250,
  44529,
  445291,
  445292,
  445298,
  4453,
  44532,
  445320,
  449,
  4491,
  44911,
  449110,
  44912,
  449121,
  449122,
  449129,
  4492,
  44921,
  449210,
  455,
  4551,
  45511,
  455110,
  4552,
  45521,
  455211,
  455219,
  456,
  4561,
  45611,
  456110,
  45612,
  456120,
  45613,
  456130,
  45619,
  456191,
  456199,
  457,
  4571,
  45711,
  457110,
  45712,
  457120,
  4572,
  45721,
  457210,
  458,
  4581,
  45811,
  458110,
  4582,
  45821,
  458210,
  4583,
  45831,
  458310,
  45832,
  458320,
  459,
  4591,
  45911,
  459110,
  45912,
  459120,
  45913,
  459130,
  45914,
  459140,
  4592,
  45921,
  459210,
  4593,
  45931,
  459310,
  4594,
  45941,
  459410,
  45942,
  459420,
  4595,
  45951,
  459510,
  4599,
  45991,
  459910,
  45992,
  459920,
  45993,
  459930,
  45999,
  459991,
  459999,
  48 - 49,
  481,
  4811,
  48111,
  481111,
  481112,
  4812,
  48121,
  481211,
  481212,
  481219,
  482,
  4821,
  48211,
  482111,
  482112,
  483,
  4831,
  48311,
  483111,
  483112,
  483113,
  483114,
  4832,
  48321,
  483211,
  483212,
  484,
  4841,
  48411,
  484110,
  48412,
  484121,
  484122,
  4842,
  48421,
  484210,
  48422,
  484220,
  48423,
  484230,
  485,
  4851,
  48511,
  485111,
  485112,
  485113,
  485119,
  4852,
  48521,
  485210,
  4853,
  48531,
  485310,
  48532,
  485320,
  4854,
  48541,
  485410,
  4855,
  48551,
  485510,
  4859,
  48599,
  485991,
  485999,
  486,
  4861,
  48611,
  486110,
  4862,
  48621,
  486210,
  4869,
  48691,
  486910,
  48699,
  486990,
  487,
  4871,
  48711,
  487110,
  4872,
  48721,
  487210,
  4879,
  48799,
  487990,
  488,
  4881,
  48811,
  488111,
  488119,
  48819,
  488190,
  4882,
  48821,
  488210,
  4883,
  48831,
  488310,
  48832,
  488320,
  48833,
  488330,
  48839,
  488390,
  4884,
  48841,
  488410,
  48849,
  488490,
  4885,
  48851,
  488510,
  4889,
  48899,
  488991,
  488999,
  491,
  4911,
  49111,
  491110,
  492,
  4921,
  49211,
  492110,
  4922,
  49221,
  492210,
  493,
  4931,
  49311,
  493110,
  49312,
  493120,
  49313,
  493130,
  49319,
  493190,
  51,
  512,
  5121,
  51211,
  512110,
  51212,
  512120,
  51213,
  512131,
  512132,
  51219,
  512191,
  512199,
  5122,
  51223,
  512230,
  51224,
  512240,
  51225,
  512250,
  51229,
  512290,
  513,
  5131,
  51311,
  513110,
  51312,
  513120,
  51313,
  513130,
  51314,
  513140,
  51319,
  513191,
  513199,
  5132,
  51321,
  513210,
  516,
  5161,
  51611,
  516110,
  51612,
  516120,
  5162,
  51621,
  516210,
  517,
  5171,
  51711,
  517111,
  517112,
  51712,
  517121,
  517122,
  5174,
  51741,
  517410,
  5178,
  51781,
  517810,
  518,
  5182,
  51821,
  518210,
  519,
  5192,
  51921,
  519210,
  51929,
  519290,
  52,
  521,
  5211,
  52111,
  521110,
  522,
  5221,
  52211,
  522110,
  52213,
  522130,
  52218,
  522180,
  5222,
  52221,
  522210,
  52222,
  522220,
  52229,
  522291,
  522292,
  522299,
  5223,
  52231,
  522310,
  52232,
  522320,
  52239,
  522390,
  523,
  5231,
  52315,
  523150,
  52316,
  523160,
  5232,
  52321,
  523210,
  5239,
  52391,
  523910,
  52394,
  523940,
  52399,
  523991,
  523999,
  524,
  5241,
  52411,
  524113,
  524114,
  52412,
  524126,
  524127,
  524128,
  52413,
  524130,
  5242,
  52421,
  524210,
  52429,
  524291,
  524292,
  524298,
  525,
  5251,
  52511,
  525110,
  52512,
  525120,
  52519,
  525190,
  5259,
  52591,
  525910,
  52592,
  525920,
  52599,
  525990,
  53,
  531,
  5311,
  53111,
  531110,
  53112,
  531120,
  53113,
  531130,
  53119,
  531190,
  5312,
  53121,
  531210,
  5313,
  53131,
  531311,
  531312,
  53132,
  531320,
  53139,
  531390,
  532,
  5321,
  53211,
  532111,
  532112,
  53212,
  532120,
  5322,
  53221,
  532210,
  53228,
  532281,
  532282,
  532283,
  532284,
  532289,
  5323,
  53231,
  532310,
  5324,
  53241,
  532411,
  532412,
  53242,
  532420,
  53249,
  532490,
  533,
  5331,
  53311,
  533110,
  54,
  541,
  5411,
  54111,
  541110,
  54112,
  541120,
  54119,
  541191,
  541199,
  5412,
  54121,
  541211,
  541213,
  541214,
  541219,
  5413,
  54131,
  541310,
  54132,
  541320,
  54133,
  541330,
  54134,
  541340,
  54135,
  541350,
  54136,
  541360,
  54137,
  541370,
  54138,
  541380,
  5414,
  54141,
  541410,
  54142,
  541420,
  54143,
  541430,
  54149,
  541490,
  5415,
  54151,
  541511,
  541512,
  541513,
  541519,
  5416,
  54161,
  541611,
  541612,
  541613,
  541614,
  541618,
  54162,
  541620,
  54169,
  541690,
  5417,
  54171,
  541713,
  541714,
  541715,
  54172,
  541720,
  5418,
  54181,
  541810,
  54182,
  541820,
  54183,
  541830,
  54184,
  541840,
  54185,
  541850,
  54186,
  541860,
  54187,
  541870,
  54189,
  541890,
  5419,
  54191,
  541910,
  54192,
  541921,
  541922,
  54193,
  541930,
  54194,
  541940,
  54199,
  541990,
  55,
  551,
  5511,
  55111,
  551111,
  551112,
  551114,
  56,
  561,
  5611,
  56111,
  561110,
  5612,
  56121,
  561210,
  5613,
  56131,
  561311,
  561312,
  56132,
  561320,
  56133,
  561330,
  5614,
  56141,
  561410,
  56142,
  561421,
  561422,
  56143,
  561431,
  561439,
  56144,
  561440,
  56145,
  561450,
  56149,
  561491,
  561492,
  561499,
  5615,
  56151,
  561510,
  56152,
  561520,
  56159,
  561591,
  561599,
  5616,
  56161,
  561611,
  561612,
  561613,
  56162,
  561621,
  561622,
  5617,
  56171,
  561710,
  56172,
  561720,
  56173,
  561730,
  56174,
  561740,
  56179,
  561790,
  5619,
  56191,
  561910,
  56192,
  561920,
  56199,
  561990,
  562,
  5621,
  56211,
  562111,
  562112,
  562119,
  5622,
  56221,
  562211,
  562212,
  562213,
  562219,
  5629,
  56291,
  562910,
  56292,
  562920,
  56299,
  562991,
  562998,
  61,
  611,
  6111,
  61111,
  611110,
  6112,
  61121,
  611210,
  6113,
  61131,
  611310,
  6114,
  61141,
  611410,
  61142,
  611420,
  61143,
  611430,
  6115,
  61151,
  611511,
  611512,
  611513,
  611519,
  6116,
  61161,
  611610,
  61162,
  611620,
  61163,
  611630,
  61169,
  611691,
  611692,
  611699,
  6117,
  61171,
  611710,
  62,
  621,
  6211,
  62111,
  621111,
  621112,
  6212,
  62121,
  621210,
  6213,
  62131,
  621310,
  62132,
  621320,
  62133,
  621330,
  62134,
  621340,
  62139,
  621391,
  621399,
  6214,
  62141,
  621410,
  62142,
  621420,
  62149,
  621491,
  621492,
  621493,
  621498,
  6215,
  62151,
  621511,
  621512,
  6216,
  62161,
  621610,
  6219,
  62191,
  621910,
  62199,
  621991,
  621999,
  622,
  6221,
  62211,
  622110,
  6222,
  62221,
  622210,
  6223,
  62231,
  622310,
  623,
  6231,
  62311,
  623110,
  6232,
  62321,
  623210,
  62322,
  623220,
  6233,
  62331,
  623311,
  623312,
  6239,
  62399,
  623990,
  624,
  6241,
  62411,
  624110,
  62412,
  624120,
  62419,
  624190,
  6242,
  62421,
  624210,
  62422,
  624221,
  624229,
  62423,
  624230,
  6243,
  62431,
  624310,
  6244,
  62441,
  624410,
  71,
  711,
  7111,
  71111,
  711110,
  71112,
  711120,
  71113,
  711130,
  71119,
  711190,
  7112,
  71121,
  711211,
  711212,
  711219,
  7113,
  71131,
  711310,
  71132,
  711320,
  7114,
  71141,
  711410,
  7115,
  71151,
  711510,
  712,
  7121,
  71211,
  712110,
  71212,
  712120,
  71213,
  712130,
  71219,
  712190,
  713,
  7131,
  71311,
  713110,
  71312,
  713120,
  7132,
  71321,
  713210,
  71329,
  713290,
  7139,
  71391,
  713910,
  71392,
  713920,
  71393,
  713930,
  71394,
  713940,
  71395,
  713950,
  71399,
  713990,
  72,
  721,
  7211,
  72111,
  721110,
  72112,
  721120,
  72119,
  721191,
  721199,
  7212,
  72121,
  721211,
  721214,
  7213,
  72131,
  721310,
  722,
  7223,
  72231,
  722310,
  72232,
  722320,
  72233,
  722330,
  7224,
  72241,
  722410,
  7225,
  72251,
  722511,
  722513,
  722514,
  722515,
  81,
  811,
  8111,
  81111,
  811111,
  811114,
  81112,
  811121,
  811122,
  81119,
  811191,
  811192,
  811198,
  8112,
  81121,
  811210,
  8113,
  81131,
  811310,
  8114,
  81141,
  811411,
  811412,
  81142,
  811420,
  81143,
  811430,
  81149,
  811490,
  812,
  8121,
  81211,
  812111,
  812112,
  812113,
  81219,
  812191,
  812199,
  8122,
  81221,
  812210,
  81222,
  812220,
  8123,
  81231,
  812310,
  81232,
  812320,
  81233,
  812331,
  812332,
  8129,
  81291,
  812910,
  81292,
  812921,
  812922,
  81293,
  812930,
  81299,
  812990,
  813,
  8131,
  81311,
  813110,
  8132,
  81321,
  813211,
  813212,
  813219,
  8133,
  81331,
  813311,
  813312,
  813319,
  8134,
  81341,
  813410,
  8139,
  81391,
  813910,
  81392,
  813920,
  81393,
  813930,
  81394,
  813940,
  81399,
  813990,
  814,
  8141,
  81411,
  814110,
  92,
  921,
  9211,
  92111,
  921110,
  92112,
  921120,
  92113,
  921130,
  92114,
  921140,
  92115,
  921150,
  92119,
  921190,
  922,
  9221,
  92211,
  922110,
  92212,
  922120,
  92213,
  922130,
  92214,
  922140,
  92215,
  922150,
  92216,
  922160,
  92219,
  922190,
  923,
  9231,
  92311,
  923110,
  92312,
  923120,
  92313,
  923130,
  92314,
  923140,
  924,
  9241,
  92411,
  924110,
  92412,
  924120,
  925,
  9251,
  92511,
  925110,
  92512,
  925120,
  926,
  9261,
  92611,
  926110,
  92612,
  926120,
  92613,
  926130,
  92614,
  926140,
  92615,
  926150,
  927,
  9271,
  92711,
  927110,
  928,
  9281,
  92811,
  928110,
  92812,
  928120,
];

// const getFilters = {
//   revenue: (value) => {
//     const _val = _1M + (value / 100) * (_1T - _1M);
//     return [_val, `${parseInt(_val / _1M)} M`];
//   },
//   compSize: (value) => {
//     const _val = _1H + (value / 100) * (_1M - _1H);
//     return [_val, `${parseInt(_val)}`];
//   },
// };

// module.exports = {
//   INDUSTRY_OPTIONS,
//   FILTERS,
//   initAddFilters,
//   moveRange,
//   // getFilters,
//   // nums: { _1H, _1M, _1T },
// };
